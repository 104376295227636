const generalSettingsStrings = {
  title: "Generelle innstillinger",
  ratesSettingsListTitle: "@:commonStrings.rateConfiguration",
  enableOrgConfigurationForPayment: "Ta i bruk Idrettsoppgjør",
  save: "@:commonStrings.save",
  successfulSaved: "Generelle innstillinger ble oppdatert",
  canChildrenActivate: "Underliggende grupper kan benytte Idrettsoppgjør",
  canChildrenActivateDisabledWarning: "Kan ikke endres fordi underliggende grupper har tatt i bruk Idrettsoppgjør",
  parentMustActivateWarning: "Overliggende klubb må gi tilgang før Idrettsoppgjør kan tas i bruk",
  cannotChangeCriticalSettings: "Du har ikke rettigheter til å deaktivere Idrettsoppgjør og tilhørende integrasjoner for ditt organisasjonsledd.",
  integrationSettingsTableTitle: "Integrasjon med andre applikasjoner",
  tableHeaders: {
    integrationConfiguration: "Applikasjon",
    salaryFraction: "Del på honorarbrøk",
    importSalaryClaimsOnly: "Kun honorarer kan importeres",
    immutableSalaryClaimsCollection: "Ikke mulig å legge til honorar",
    editableSalaryClaimPayingOrgs: "Honorar mottakere kan endres"
  },
  labels: {
    immutableSalaryClaimsCollection: "Ikke mulig å legge til en ny honorar-rad etter import"
  },
  placeholders: {
    integrationConfiguration: "Velg applikasjon"
  },
  addIntegration: "@:commonStrings.add applikasjon",
  allIntegrationsUsed: "Alle applikasjoner er allerede satt opp",
  inconsistentIntegrationConfigurationsError: "Kun ett oppsett pr applikasjon",
  paymentMethodsTitle: "Utbetalingsmåter",
  buypassSettings: {
    title: "Buypass",
    editNotAvailableMessage: "Innstillinger for Buypass kan kun endres av Buypass-administrator",
    labels: {
      isActive: "Tillat utbetaling fra Buypass",
      settlementAccountId: "Utbetalingskonto"
    },
    placeholders: {
      settlementAccountId: "Velg konto"
    }
  },
  remittanceSettings: {
    title: "Remitteringsfil",
    labels: {
      isActive: "Tillat utbetaling fra remitteringsfil",
      dataSenderId: "Kundeenhet-ID",
      agreementId: "Avtale-ID",
      accountNumber: "Utbetalingskonto",
      useParentAccount: "Bruk overliggende klubbs konto",
      useOwnAccount: "Bruk egen konto"
    },
    isActiveNotAvailableMessage: "Kundeenhet-ID må være oppgitt for overliggende klubb før remittering kan aktiveres",
    notAvailableForOrgTypeMessage: "Innstillinger for remitteringsfil kan kun endres av overliggende klubb"
  },
  buypassSettingsInfoModal: {
    linkText: "Om utbetaling fra Buypass",
    title: "Om utbetaling fra Buypass",
    infoText1: "For å utbetale refusjoner fra Buypass må tjenesten aktiveres og utbetalingskonto velges fra Innstillinger.",
    infoText2: "Dette kan gjøres av personer som er registrert som Administrator i Buypass Payment Manager.",
    infoText3: "For å kunne utbetale må også minst to personer tildeles funksjonen 'Fullmakt utbetaling Idrettsoppgjør' slik at krav kan signeres.",
    helpLinkText: "Gå til brukerveiledning for mer informasjon",
    closeButton: "@:commonStrings.close"
  },
  childOrgSelectionPanel: {
    cancelButton: "@:commonStrings.cancel",
    proceedButton: "@:commonStrings.proceed",
    childOrgSelect: "@:commonStrings.selectOrg",
    confirmChangeOrgDialogTitle: "Bytte organisasjonsledd?",
    confirmChangeOrgDialogText: "Du har endringer som ikke er lagret. Disse vil gå tapt om du bytter organisasjonsledd."
  }
};

export default generalSettingsStrings;