<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on" rounded :class="linkCss">{{ linkText }}</v-btn>
    </template>
    <v-skeleton-loader :loading="loading" type="card">
      <v-card>
        <v-card-title
          ><h2 class="text-h3">
            {{ $t("remittancesStrings.detailsModal.title") }}
          </h2></v-card-title
        >
        <v-card-text class="pt-4" v-if="notFound">{{$t("remittancesStrings.detailsModal.notFound")}}</v-card-text>
        <v-card-text v-else class="pa-4">
          <v-row>
            <v-col class="py-0 font-weight-bold col-sm-4 col-12">
              {{ $t("remittancesStrings.detailsModal.transmissionId") }}</v-col
            >
            <v-col class="py-0 col-sm-8 col-12">{{
              remittance.transmissionId
            }}</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 font-weight-bold col-sm-4 col-12">{{
              $t("remittancesStrings.detailsModal.assignmentId")
            }}</v-col>
            <v-col class="py-0 col-sm-8 col-12">{{
              remittance.assignmentId
            }}</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 font-weight-bold col-sm-4 col-12">{{
              $t("remittancesStrings.detailsModal.amount")
            }}</v-col>
            <v-col class="py-0 col-sm-8 col-12">{{
              remittance.amount | toMoney
            }}</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 font-weight-bold col-sm-4 col-12">{{
              $t("remittancesStrings.detailsModal.createdDate")
            }}</v-col>
            <v-col class="py-0 col-sm-8 col-12">{{
              remittance.createdDate
                | moment($t("commonStrings.dateDisplayFormat"))
            }}</v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 font-weight-bold col-sm-4 col-12">{{
              $t("remittancesStrings.detailsModal.receiptReceivedDate")
            }}</v-col>
            <v-col
              class="py-0 col-sm-8 col-12"
              v-if="remittance.receiptReceivedDate"
              >{{
                remittance.receiptReceivedDate
                  | moment($t("commonStrings.dateDisplayFormat"))
              }}</v-col
            >
            <v-col class="py-0 col-sm-8 col-12" v-else></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn rounded class="primary-button" @click="close">{{
            $t("previewApplicationPageStrings.labels.closeButton")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-skeleton-loader>
  </v-dialog>
</template>

<script>
import { api } from "@/utils/api";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "RemittanceDetailsModal",
  props: {
    applicationId: {
      required: true
    },
    orgId: {
      required: true
    },
    linkText: {
      required: true
    },
    linkCss: {
      requried: true
    }
  },
  data() {
    return {
      remittance: {},
      dialog: false,
      loading: true,
      notFound: false
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    async getRemittance() {
      try {
        const response = await api.v1.applicationOrgs.getRemittanceExportLogRow(
          this.applicationId,
          this.orgId
        );

        if (response.status === 200) {
          this.loading = false;
          this.remittance = response.data;
        }
      } catch (error) {
        //Handling 404 here with a message in the modal, since we probably don't want to redirect from a modal, which a 404 will.
        if (error.response.status === 404) {
          this.notFound = true;
          this.loading = false;
        } else {
          await handleError(error);
          this.dialog = false;
        }
      }
    }
  },
  watch: {
    dialog: {
      async handler(val) {
        if (val) {
          await this.getRemittance();
        } else {
          this.remittance = {};
          this.loading = true;
        }
      }
    }
  }
};
</script>