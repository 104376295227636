const drivingPanelStrings = {
  title: "Kjøregodtgjørelse",
  tableHeaders: {
    date: "@:commonStrings.date",
    payingOrgs: "@:commonStrings.recipients",
    from: "@:commonStrings.from",
    to: "@:commonStrings.to",
    vehicleType: "Kjøretøy",
    tripLength: "Antall km (tur/retur)",
    passengers: "@:commonStrings.passengers (for- og etternavn)",
    amount: "@:commonStrings.amount"
  },
  hints: {
    place: "Oppgi navn på sted, adresse",
    from: "@:drivingPanelStrings.hints.place",
    to: "@:drivingPanelStrings.hints.place",
    passengers: "Oppgi for- og etternavn. Trykk Enter for å legge til flere",
    tripLength: "Fyll inn total kjørelengde tur/retur. Har du kun kjørt en vei, legger du inn antall km kun for denne strekningen."
  },
  validationLabels: {
    tripLength: "Antall km",
    passengers: "@:commonStrings.passengers"
  },
  customErrors: {
    passengers: {
      name_list: "Hvert navn må inneholde for- og etternavn, husk mellomrom i navnet"
    }
  },
  labels: {
    addButton: "@:commonStrings.add @.lower:drivingPanelStrings.title",
    removeButton: "@:commonStrings.remove",
    editButton: "@:commonStrings.edit",
    closeButton: "@:commonStrings.close",
    cancelButton: "@:commonStrings.cancel",
    insertButton: "@:commonStrings.add",
    updateButton: "@:commonStrings.update",
    insertEditDialogTitle: "Ny @.lower:drivingPanelStrings.title",
    updateEditDialogTitle: "Endre @.lower:drivingPanelStrings.title"
  }
};

export default drivingPanelStrings;