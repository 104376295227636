<template>
  <v-card>
    <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('editApplicationStrings.applicationInformationTitle')}}</h2>
              <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onHelpClick"
          >help_outline</v-icon>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <validation-provider
            rules="required|min:5|max:60"
            :name="$t('editApplicationStrings.applicationNameTextValidationName')"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="application.applicationName"
              :error-messages="errors"
              :label="$t('editApplicationStrings.applicationNameTextField')"
              counter="60"
              outlined
              dense
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <org-search :claims="application.claims"></org-search>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider
            rules="required|length:11|account_number"
            :name="$t('editApplicationStrings.accountNumberTextFieldValidationName')"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="application.accountNumber"
              :error-messages="errors"
              :label="$t('editApplicationStrings.accountNumberTextField')"
              counter="11"
              outlined
              dense
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import OrgSearch from "./OrgSearch";
import claimPanel from "@/mixins/claimPanel";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import HelpBarApplicationSectionTypes from "@/enums/HelpBarApplicationSectionTypes";

export default {
  name: "GeneralDetailsPanel",
  components: {
    ValidationProvider,
    OrgSearch
  },
  mixins: [claimPanel],
  props: {
    application: {
      type: Object,
      validator: prop => typeof prop === "object" || prop === null,
      required: true
    }
  },
  methods: {
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.Application, [HelpBarApplicationSectionTypes.General]);
    }
  }
};
</script>