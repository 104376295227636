import Vue from '@/main';
import { getOrgDisplayName } from "@/utils/org";

const payingOrgsStore = {
    namespaced: true,
    state: {
        payingOrgs: [],
        nameLookup: {}
    },
    mutations: {
        setPayingOrgs(state, payingOrgs) {
            state.payingOrgs = payingOrgs;

            for (const org of payingOrgs) {
                Vue.$set(state.nameLookup, org.id, getOrgDisplayName(org));
            }
        },
        addPayingOrg(state, payingOrg) {
            state.payingOrgs.push(payingOrg);

            Vue.$set(state.nameLookup, payingOrg.id, getOrgDisplayName(payingOrg));
        },
        removePayingOrg(state, payingOrg) {
            const index = state.payingOrgs.findIndex(x => x.orgId === payingOrg.orgId);

            if (index >= 0) {
                Vue.delete(state.nameLookup, state.payingOrg[index].id);
                state.payingOrgs.splice(index, 1);
            }
        }
    },
    getters: {
        payingOrgs: state => state.payingOrgs,
        nameLookup: state => state.nameLookup
    }
};

export default payingOrgsStore;