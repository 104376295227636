<template>
  <v-row>
    <v-col cols="12">
          <v-switch
            color="primary"
            inset
            :label="
              $t('generalSettingsStrings.enableOrgConfigurationForPayment')
            "
            :disabled="!relationActivationRights.isActivationPermitted || disableIsActive"
            v-model="orgConfiguration.isActive"
          ></v-switch>
          <em v-if="!relationActivationRights.isActivationPermitted">{{
            $t("generalSettingsStrings.parentMustActivateWarning")
          }}</em>
          <em v-if="disableIsActive">{{
            $t("generalSettingsStrings.cannotChangeCriticalSettings")
          }}</em>

          <v-switch
            v-if="
              relationActivationRights.hasChildrenRequiringActivationPermission
            "
            color="primary"
            inset
            :label="$t('generalSettingsStrings.canChildrenActivate')"
            :disabled="
              !relationActivationRights.isChildPermissionDeactivationPermitted
            "
            v-model="orgConfiguration.canChildrenActivate"
          ></v-switch>
          <em
            v-if="
              !relationActivationRights.isChildPermissionDeactivationPermitted
            "
            >{{
              $t("generalSettingsStrings.canChildrenActivateDisabledWarning")
            }}</em
          >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CommonSettings",
  props: {
    orgConfiguration: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    relationActivationRights: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    disableCriticalSettings: Boolean
  },
  computed: {
    disableIsActive() {
      return this.disableCriticalSettings && this.orgConfiguration.isActive;
    }
  }
};
</script>