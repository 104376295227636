const financialDimensionStrings = {
  title: "Kostnadsbærere",
  categoryTitle: "Kostnadsbærerkategorier",
  fixedCategoryTitle: "Faste kategorier",
  customCategoryTitle: "Valgfrie kategorier",
  dimensionsTable: {
    noData: "Ingen kostnadsbærere funnet",
    noResults: "Ingen resultater å vise",
    headers: {
      category: "Kategori",
      name: "Navn",
      code: "Nummer",
      active: "Aktiv"
    },
    editDialog: {
      createTitle: "Opprett kostnadsbærer",
      editTitle: "Rediger kostnadsbærer",
      saved: "Kostnadsbærer ble lagret"
    }
  }
};

export default financialDimensionStrings;