<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col>
          <h2 class="text-h2 float-left">{{ $t("drivingPanelStrings.title") }} {{ tripsCount }}</h2>
          <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onHelpClick"
            >help_outline</v-icon
          >
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <trip-edit-dialog
        :item="editedItem"
        :current-index="editedIndex"
        :updateTripAmount="updateTripAmount"
        :canUseSalaryFraction="canUseSalaryFraction"
        @new-item-added="addNewItem"
        @item-changed="updateItem"
        @edit-cancelled="editCancelled"
        :enableAddNew="enableAddNew"
      >
      </trip-edit-dialog>
      <v-row v-if="claims.length">
        <v-col>
          <trips-table
            :claims="claims"
            :remove="remove"
            :canUseSalaryFraction="canUseSalaryFraction"
            @edit-clicked="editItem"
          ></trips-table>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import claimPanel from "@/mixins/claimPanel";
import TripsTable from "./TripsTable";
import TripEditDialog from "./TripEditDialog";
import { calculateTotalTripAmountForOrgs } from "@/utils/claims/trips";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import HelpBarApplicationSectionTypes from "@/enums/HelpBarApplicationSectionTypes";

export default {
  name: "TripsPanel",
  components: {
    TripsTable,
    TripEditDialog
  },
  mixins: [claimPanel],
  data() {
    return {
      showPanel: false,
      defaultItem: {
        amount: 0,
        claimTypeId: 0,
        date: "",
        from: "",
        kilometers: 0,
        passengers: [],
        payingOrgs: [],
        to: ""
      },
      editedItem: {
        amount: 0,
        claimTypeId: 0,
        date: "",
        from: "",
        kilometers: 0,
        passengers: [],
        payingOrgs: [],
        to: ""
      },
      editedIndex: -1
    };
  },
  computed: {
    tripsCount() {
      if (!this.claims.length) {
        return "";
      }

      return `(${this.claims.length})`;
    },
    getExpandedPanelsIndex() {
      if (this.claims?.length) {
        return 0;
      }

      return null;
    }
  },
  props: {
    claims: {
      default: () => [],
      type: Array,
      required: true
    },
    canUseSalaryFraction: {
      required: true
    },
    enableAddNew:{
      required: true,
      type: Boolean
    }
  },
  methods: {
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.Application, [HelpBarApplicationSectionTypes.Trips]);
    },
    initForNewItem() {
      this.editedItem = { ...this.defaultItem };

      //any index < 0 is new item, should change on every init to trigger component updates
      if (this.editedIndex < 0) {
        this.editedIndex = this.editedIndex - 1;
      } else {
        this.editedIndex = -1;
      }
    },
    addNewItem(item) {
      this.claims.push(item);
      this.initForNewItem();
    },
    editItem(item) {
      this.editedItem = { ...item.claim};
      this.editedIndex = item.index;
    },
    updateItem(item) {
      Object.assign(this.claims[item.index], item.claim);
      this.initForNewItem();
    },
    editCancelled() {
      this.initForNewItem();
    },
    remove(claim) {
      const index = this.claims.indexOf(claim);
      this.claims.splice(index, 1);
    },
    updateTripAmount(claim) {
      if (
        !claim.date ||
        !claim.payingOrgs?.length ||
        !claim.claimTypeId ||
        !claim.kilometers
      ) {
        this.$delete(claim, "amount");
        return;
      }

      const calculatedAmount = calculateTotalTripAmountForOrgs(claim);

      this.$set(claim, "amount", calculatedAmount);
    }
  },
  beforeMount() {
    for (const claim of this.claims) {
      this.updateTripAmount(claim);
    }
  }
};
</script>