<template>
  <v-dialog v-model="dialog" max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn
        text
        v-on="on"
        class="body-1 pa-0 tertiary-remove-underline-button text-none font-weight-light"
      >{{ linkText }}</v-btn>
    </template>
    <v-card>
      <v-card-title><h2 class="text-h3">{{ title }}</h2></v-card-title>
      <v-card-text class="pa-4">
        <p v-if="startText" class="py-2">{{ startText }}</p>
        <ul>
          <li v-for="functionTypeName in functionTypeNames" :key="functionTypeName">
            {{ functionTypeName }}
          </li>
        </ul>
        <p v-if="endText" class="py-2">{{ endText }}</p>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn
          rounded
          class="primary-button"
          @click="close"
        >{{$t('previewApplicationPageStrings.labels.closeButton')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PermissionsModal",
  props: {
    linkText: String,
    title: String,
    startText: String,
    functionTypeNames: Array,
    endText: String
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    close() {
      this.dialog = false;
    }
  }
};
</script>