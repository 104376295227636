const infoMessagesStrings = {
  pageTitle: "Infomeldinger",
  noTableData: "Ingen meldinger funnet",
  noTableResults: "Ingen meldinger matcher filter",
  editButton: "@:commonStrings.edit",
  addButton: "@:commonStrings.add",
  removeButton: "@:commonStrings.remove",
  saved: "Meldingen ble lagret",
  deleted: "Melding ble slettet",
  confirmDeleteTitle: "Er du sikker?",
  confirmDeleteText: "Dette vil slette meldingen, den kan ikke gjenopprettes",
  headers: {
    id: "Id",
    title: "Tittel",
    message: "Melding",
    type: "Meldingstype",
    url: "URL",
    fromDate: "Fra dato",
    toDate: "Til dato",
    displayOnPage: "Visningsside",
    recipient: "Mottagertype"
  },
  editModal: {
    createTitle: "Opprett melding",
    editTitle: "Rediger melding"
  }
};

export default infoMessagesStrings;