<template>
  <v-col cols="12">
    <v-card outlined dense>
      <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t("journalStrings.meta.title")}}</h2></v-card-title>
      <v-card-text>
        <v-container class="pa-0 ma-0">
        <v-row class="ma-0">
          <v-col class="pt-0 pl-0 pb-0">
            <v-simple-table dense align="left">
              <tbody>
                <tr>
                  <td>{{$t("journalStrings.meta.name")}}</td>
                  <td>{{ application.applicationName }}</td>
                </tr>
                <tr>
                  <td>{{$t("journalStrings.meta.approvedDate")}}</td>
                  <td>{{ orgApprovalDate | moment($t("commonStrings.dateDisplayFormat")) }}</td>
                </tr>
                <tr>
                  <td>{{$t("journalStrings.meta.approvedBy")}}</td>
                  <td>{{approvedBy}}</td>
                </tr>
                <tr>
                  <td>{{$t("journalStrings.meta.paidDate")}}</td>
                  <td>{{orgPaidDate | moment($t("commonStrings.dateDisplayFormat")) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col class="pt-0 pr-0 pb-0">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>{{$t("journalStrings.meta.uniqueId")}}</td>
                  <td style="text-align: left">{{ application.id }}</td>
                </tr>
                <tr>
                  <td>{{$t("journalStrings.meta.createdDate")}}</td>
                  <td>{{ application.createdDate | moment($t("commonStrings.dateDisplayFormat")) }}</td>
                </tr>
                <tr>
                  <td>{{$t("journalStrings.meta.accountNumber")}}</td>
                  <td style="text-align: left">{{ application.accountNumber }}</td>
                </tr>
                <tr>
                  <td>{{$t("journalStrings.meta.paidWith")}}</td>
                  <td>{{orgPaidWith}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-col>
</template>
<script>
import OrgApplicationStatusTypes from "@/enums/OrgApplicationStatusTypes";
import { getPersonName } from "@/utils/currentApplication";
export default {
  name: "JournalMetaData",
  computed: {
    orgApprovalDate() {
      if (this.application.orgs) {
        const org = this.application.orgs[this.orgId];

        if (
          org?.approval &&
          (org.orgApplicationStatus === OrgApplicationStatusTypes.Approved ||
            org.orgApplicationStatus === OrgApplicationStatusTypes.Paid)
        ) {
          return org.approval.dateCreated;
        }
      }

      return null;
    },
    orgPaidDate() {
      if (this.application.orgs) {
        const org = this.application.orgs[this.orgId];

        if (org?.paymentSent) {
          return org.paymentSent.dateCreated;
        }
      }

      return null;
    },
    orgPaidWith() {
      if (this.application.orgs) {
        const org = this.application.orgs[this.orgId];

        if (org?.paymentSent && org.paymentMethod) {
          return this.$t(`enums.paymentMethods.${org.paymentMethod}`);
        }
      }

      return null;
    },
    approvedBy() {
      if (this.application.orgs) {
        const org = this.application.orgs[this.orgId];

        if (
          org?.approval &&
          (org.orgApplicationStatus === OrgApplicationStatusTypes.Approved ||
            org.orgApplicationStatus === OrgApplicationStatusTypes.Paid)
        ) {
          return getPersonName(org.approval.personId);
        }
      }
      return null;
    }
  },
  props: {
    application: {
      required: true,
      type: Object,
      default: () => ({})
    },
    orgId: {
      type: Number,
      required: true
    }
  }
};
</script>