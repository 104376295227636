<template>
  <v-footer :absolute="absolute" :app="app" class="primary inverted--text">
    <v-container class="wide-container mx-auto my-6">
      <v-row>
        <v-col cols="12" md="4" class="text-center">
          <div class="text-left inline-block">
            <img src="https://www.idrettsforbundet.no/Static/gfx/logo-hvit.svg" alt="Norges Idrettsforbund" width="160" />
          </div>
        </v-col>
        <v-col cols="12" md="4" class="middle-column text-center">
          <div class="text-left inline-block">
            <p class="body-2">
              {{ $t('footer.nifName') }}<br/>
              {{ $t('footer.nifName2') }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <div class="text-left inline-block">
            <p class="body-2 red-underline-animation">
              <a href="https://www.idrettsforbundet.no/digital/support/kontakt/"
                class="inverted--text opaque-underline"
                target="_blank"
                rel="noopener noreferrer"
              >{{ $t('footer.support') }}&rarr;</a>
            </p>
            <p class="body-2 red-underline-animation">
              <a href="https://www.idrettsforbundet.no/om-nif/personvern-i-idretten/"
                class="inverted--text opaque-underline"
                target="_blank"
                rel="noopener noreferrer"
              > {{ $t('footer.gdpr') }}&rarr;</a>
            </p>
            <slot></slot>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped lang="scss">
.inline-block {
  display: inline-block;
  width: 300px;
}

@media (max-width: 960px) {
  .row {
    flex-direction: column-reverse;
  }
}

@media (min-width: 600px) {
  .middle-column {
    border-left: 1px solid #232651;
    border-right: 1px solid #232651;
  }
}
</style>

<script>
export default {
  name: 'FooterBlock',
  props: {
    lang: String,
    absolute: Boolean,
    app: Boolean
  }
};
</script>