export const validationConstants = Object.freeze({
  remittanceSettings: {
    maxDataSenderIdLength: 8,
    maxAgreementIdLength: 9,
    maxAccountNumberLength: 11
  },
  attachmentUpload: {
    maxFileSizeInMB: 10
  },
  remittanceImportPanel: {
    maxFileSizeInMB: 10
  },
  receiptEditDialog: {
    maxCommentLength: 60
  }
});