<template>
  <v-skeleton-loader :loading="loading" type="table">
    <v-card>
      <v-card-title><h2 class="text-h3">{{$t('statisticsStrings.approvalsByMonth.panelHeader')}}</h2></v-card-title>
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>{{$t('statisticsStrings.approvalsByMonth.tableHeaders.month')}}</th>
              <template v-for="config in integrationConfigs">
                <th :key="`${config.id}_month`">{{ $t("statisticsStrings.approvalsByMonth.tableHeaders.count", {0: config.name}) }}</th>
                <th :key="`${config.id}_total`">{{ $t("statisticsStrings.approvalsByMonth.tableHeaders.yearAccumulatedTotal", {0: config.name}) }}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in descendingTableContents" :key="item.month">
              <td>{{ createDateFromMonthAndYear(item.month, item.year) | moment($t("commonStrings.monthYearDisplayFormat")) }}</td>
              <template v-for="config in integrationConfigs">
                <td :key="`${config.id}_month`">
                  {{ getCountByApplicationId(item, config.applicationId) | replaceZeroAndNullValues("-") }}
                </td>
                <td :key="`${config.id}_total`">
                  {{ getAccumulatedTotalInYear(item, index, config.applicationId) | replaceZeroAndNullValues("-") }}
                </td>
              </template>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { api } from "@/utils/api";
import { copy } from "@/utils/object";
import { replaceZeroAndNullValues } from "@/utils/numeric";
import { createDateFromMonthAndYear, sortByYearThenMonthAscending, sortByYearThenMonthDescending } from "@/utils/date";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "ApprovalsByMonthPanel",
  data() {
    return {
      loading: true,
      tableContents: [],
      integrationConfigs: []
    };
  },
  computed: {
    ascendingTableContents() {
      return copy(this.tableContents).sort(sortByYearThenMonthAscending);
    },
    descendingTableContents() {
      return copy(this.tableContents).sort(sortByYearThenMonthDescending);
    }
  },
  methods: {
    async fetch() {
      try {
        const response = await api.v1.statistics.approvalCountsByFunctionalityIdAndMonth();
        if (response.status === 200) {
          this.tableContents = response.data;
        }

        const integrationsResponse = await api.v1.integrationConfigurations.get();
        if (integrationsResponse.status === 200) {
          this.integrationConfigs = integrationsResponse.data;
        }
      } catch (error) {
        await handleError(error);
      }

      this.loading = false;
    },
    getCountByApplicationId(item, applicationId) {
      const functionalityIdItem = item.functionalityIdAggregates.find(x => x.functionalityId === applicationId);

      if (functionalityIdItem) {
        return functionalityIdItem.count;
      }

      return 0;
    },
    getAccumulatedTotalInYear(item, index, applicationId) {
      const subArray = this.getAccumulatedTotalItems(index);
      const filteredSubArray = subArray.filter(x => x.year === item.year);

      if (filteredSubArray?.length) {
        return filteredSubArray.reduce((acc, cur) => acc + this.getCountByApplicationId(cur, applicationId), 0);
      }

      return 0;
    },
    getAccumulatedTotalItems(index) {
      if (this.ascendingTableContents) {
        return this.ascendingTableContents.slice(0, this.ascendingTableContents.length - index);
      }

      return [];
    },
    createDateFromMonthAndYear
  },
  filters: {
    replaceZeroAndNullValues
  },
  async mounted() {
    await this.fetch();
  }
};
</script>


