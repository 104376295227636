import store from '@/store';
const functionTypeNames = function () {
  return store.getters['functionTypes/functionTypes'];
};

const permissionConfig = JSON.parse(process.env.VUE_APP_PERMISSION_CONFIG);

const allowedBackofficeFunctionTypes = function allowedBackofficeFunctionTypes() {
  return permissionConfig.backofficeFunctionTypes;
};

const allowedApprovalFunctionTypes = function allowedApprovalFunctionTypes() {
  return permissionConfig.approvalFunctionTypes;
};

const allowedSignPaymentFunctionTypes = function allowedSignPaymentFunctionTypes() {
  return permissionConfig.signPaymentFunctionTypes;
};

const allowedSendPaymentFunctionTypes = function allowedSendPaymentFunctionTypes() {
  return permissionConfig.sendPaymentFunctionTypes;
};

const allowedOrgApplicationFunctionTypes = function allowedOrgApplicationFunctionTypes() {
  return [permissionConfig.approvalFunctionTypes, permissionConfig.signPaymentFunctionTypes, permissionConfig.sendPaymentFunctionTypes].flat();
};

const allowedOrgTypes = function allowedOrgTypes() {
  return permissionConfig.orgTypes;
};

const superuserFunctionTypes = function superuserFunctionType() {
  return permissionConfig.superuserFunctionTypes;
};

const defaultEmailRecipientFunctionTypes = function defaultEmailRecipientFunctionTypes() {
  return permissionConfig.defaultEmailRecipientFunctionTypes;
};

const isInListOrListEmpty = function isInListOrListEmpty(list, checkVal) {
  if (!list?.length) {
    return true;
  }

  return list.some(x => x === checkVal);
};

const isAllowedOrgType = function isAllowedOrgType(orgTypeId, isSingleSport) {
  if (isSingleSport) {
    return isInListOrListEmpty(allowedOrgTypes(), orgTypeId);
  }

  return isInListOrListEmpty(allowedOrgTypes(), orgTypeId);
};

export const isBackofficeEnabled = function isBackofficeEnabled(functionTypeId, orgTypeId, isSingleSport) {
  return isAllowedOrgType(orgTypeId, isSingleSport) && isInListOrListEmpty(allowedBackofficeFunctionTypes(), functionTypeId);
};

export const isPayoutEnabled = function isPayoutEnabled(functionTypeId, orgTypeId, isSingleSport) {
  return isAllowedOrgType(orgTypeId, isSingleSport) && isInListOrListEmpty(allowedSendPaymentFunctionTypes(), functionTypeId);
};

export const isPaymentMethodSelectionEnabled = function isPaymentMethodSelectionEnabled(functionTypeId, orgTypeId, isSingleSport) {
  return isAllowedOrgType(orgTypeId, isSingleSport) && isInListOrListEmpty(allowedOrgApplicationFunctionTypes(), functionTypeId);
};

export const isPaymentSignatureEnabled = function isPaymentSignatureEnabled(functionTypeId, orgTypeId, isSingleSport) {
  return isAllowedOrgType(orgTypeId, isSingleSport) && isInListOrListEmpty(allowedSignPaymentFunctionTypes(), functionTypeId);
};

export const isApprovalEnabled = function isApprovalEnabled(functionTypeId, orgTypeId, isSingleSport) {
  return isAllowedOrgType(orgTypeId, isSingleSport) && isInListOrListEmpty(allowedApprovalFunctionTypes(), functionTypeId);
};

export const isOrgApplicationEnabled = function isOrgApplicationEnabled(functionTypeId, orgTypeId, isSingleSport) {
  return isAllowedOrgType(orgTypeId, isSingleSport) && isInListOrListEmpty(allowedOrgApplicationFunctionTypes(), functionTypeId);
};

export const isDefaultEmailRecipient = function isDefaultEmailRecipient(functionTypeId) {
  return isInListOrListEmpty(defaultEmailRecipientFunctionTypes(), functionTypeId);
};

export const isSuperuser = function isSuperuser(functionTypeId) {
  return isInListOrListEmpty(superuserFunctionTypes(), functionTypeId);
};

export const approvalPermittedFunctionTypes = function approvalPermittedFunctionTypes() {
  return functionTypeNames().filter(x => allowedApprovalFunctionTypes().some(y => y === x.id));
};

export const paymentMethodSelectionPermittedFunctionTypes = function paymentMethodSelectionPermittedFunctionTypes() {
  return functionTypeNames().filter(x => allowedOrgApplicationFunctionTypes().some(y => y === x.id));
};

export const paymentSignaturePermittedFunctionTypes = function paymentSignaturePermittedFunctionTypes() {
  return functionTypeNames().filter(x => allowedSignPaymentFunctionTypes().some(y => y === x.id));
};

export const payoutPermittedFunctionTypes = function payoutPermittedFunctionTypes() {
  return functionTypeNames().filter(x => allowedSendPaymentFunctionTypes().some(y => y === x.id));
};