<template>
  <div class="pb-4">
    <inline-router-link :to="{name: 'journalDetails', params: {id: applicationId, orgid: handledOrgId}}">
      {{$t("previewApplicationPageStrings.labels.toJournal", [handledOrgName])}}
    </inline-router-link>
  </div>
</template>

<script>
import InlineRouterLink from "@/views/common/components/InlineRouterLink";

export default {
  name: "JournalLinkPanel",
  components: {
    InlineRouterLink
  },
  props: {
    applicationId: String,
    handledOrgId: Number,
    handledOrgName: String
  }
};
</script>