<template>
  <v-skeleton-loader :loading="loading" type="card-heading, table-thead, table-row-divider@8">
    <v-card>
      <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('rateConfigurationStrings.vehicleTitle')}}</h2></v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              rules="integer|min_value:0"
              v-slot="{ errors }"
              :name="$t('rateConfigurationStrings.vehicleMinDrivingDistanceLabel')"
              vid="minDrivingDistance"
            >
              <integer-text-field
                v-model="vehicleRates.minDrivingDistance"
                name="minDrivingDistance"
                :error-messages="errors"
                :label="$t('rateConfigurationStrings.vehicleMinDrivingDistanceLabel')"
              ></integer-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              rules="finance_account"
              :name="$t('rateConfigurationStrings.vehicleAccountingIdLabel')"
              :skipIfEmpty="false"
              vid="vehicle.accountingId"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="vehicleRates.accountingId"
                :error-messages="errors"
                outlined
                dense
                :label="$t('rateConfigurationStrings.vehicleAccountingIdLabel')"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              :rules="{decimal: 2, min_value: 0}"
              v-slot="{ errors }"
              :name="$t('rateConfigurationStrings.vehiclePassengerRateLabel')"
              vid="passengerRate"
            >
              <decimal-text-field
                v-model="vehicleRates.passengerRate"
                :error-messages="errors"
                name="passengerRate"
                :messages="verifyTaxRatesWarningsForPassengerRate(vehicleRates.passengerRate)"
                :label="$t('rateConfigurationStrings.vehiclePassengerRateLabel')"
                :pad-decimals="2"
              ></decimal-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              rules="finance_account"
              :name="$t('rateConfigurationStrings.vehiclePassengerAccountingIdLabel')"
              :skipIfEmpty="false"
              vid="vehicle.passengerAccountingId"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="vehicleRates.passengerAccountingId"
                :error-messages="errors"
                outlined
                dense
                :label="$t('rateConfigurationStrings.vehiclePassengerAccountingIdLabel')"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              :rules="{decimal: 2, min_value: 0}"
              v-slot="{ errors }"
              :name="$t('rateConfigurationStrings.vehicleTrailerRateLabel')"
              vid="trailerRate"
            >
              <decimal-text-field
                v-model="vehicleRates.trailerRate"
                :error-messages="errors"
                name="trailerRate"
                :messages="verifyTaxRatesWarningsForTrailerRate(vehicleRates.trailerRate)"
                :label="$t('rateConfigurationStrings.vehicleTrailerRateLabel')"
                :pad-decimals="2"
              ></decimal-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" lg="4" xl="3">
            <validation-provider
              rules="finance_account"
              :name="$t('rateConfigurationStrings.vehicleTrailerAccountingIdLabel')"
              :skipIfEmpty="false"
              vid="vehicle.trailerAccountingId"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="vehicleRates.trailerAccountingId"
                :error-messages="errors"
                outlined
                dense
                :label="$t('rateConfigurationStrings.vehicleTrailerAccountingIdLabel')"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="8" xl="6">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      id="vehicleEnabledHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.vehicleTypeTableEnabledHeader')}}</th>
                    <th
                      id="vehicleRateTypeHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.vehicleTypeTableTypeHeader')}}</th>
                    <th
                      id="vehicleRateHeader"
                      class="text-left"
                    >{{$t('rateConfigurationStrings.vehicleTypeTableRateHeader')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in vehicleRates.vehicleRateTypes" :key="item.rateType">
                    <td>
                      <validation-provider rules :vid="getVehicleEnabledValidationId(item.rateType)">
                        <v-checkbox
                          color="primary"
                          v-model="item.enabled"
                          :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.vehicleTypeTableEnabledHeader'), $t(`enums.vehicleTypes.${item.rateType}`)])"
                        ></v-checkbox>
                      </validation-provider>
                    </td>
                    <td>{{ $t(`enums.vehicleTypes.${item.rateType}`) }}</td>
                    <td>
                      <validation-provider
                        :rules="{required_if:getVehicleEnabledValidationId(item.rateType), decimal: 2, min_value: item.enabled ? 0.01 : 0}"
                        v-slot="{ errors }"
                        :name="$t('rateConfigurationStrings.vehicleTypeTableRateHeader')"
                        :vid="getVehicleAmountValidationId(item.rateType)"
                      >
                        <decimal-text-field
                          v-model="item.rate"
                          :error-messages="errors"
                          name="rate"
                          :messages="verifyTaxRatesWarnings(item)"
                          :aria-label="$t('commonStrings.tableAriaLabel',[$t('rateConfigurationStrings.vehicleTypeTableRateHeader'), $t(`enums.vehicleTypes.${item.rateType}`)])"
                          :disabled="!item.enabled"
                          :pad-decimals="2"
                        ></decimal-text-field>
                      </validation-provider>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { toMoney } from "@/utils/numeric";
import DecimalTextField from "@/views/common/components/DecimalTextField";
import IntegerTextField from "@/views/common/components/IntegerTextField";

export default {
  name: "VehicleSettings",
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    vehicleRates: {
      type: Object,
      required: true
    },
    vehicleTaxRates: {
      type: Object,
      required: true
    }
  },
  components: {
    ValidationProvider,
    DecimalTextField,
    IntegerTextField
  },
  computed: {
    passengerMaxTaxRate: function() {
      if (this.vehicleTaxRates) {
        return this.vehicleTaxRates.passengerMaxTaxRate;
      } else {
        return null;
      }
    },
    hasPassengerMaxTaxRate: function() {
      return this.passengerMaxTaxRate && this.passengerMaxTaxRate > 0;
    },
    trailerMaxTaxRate: function() {
      if (this.vehicleTaxRates) {
        return this.vehicleTaxRates.trailerMaxTaxRate;
      } else {
        return null;
      }
    },
    hasTrailerMaxTaxRate: function() {
      return this.trailerMaxTaxRate && this.trailerMaxTaxRate > 0;
    },
    isAnyVehicleEnabled() {
      return this.vehicleRates.vehicleRateTypes.some(x => x.enabled);
    }
  },
  methods: {
    verifyTaxRatesWarnings(item) {
      if (!item) {
        return [];
      }

      const maxTaxRate = this.getMaxTaxRate(item.rateType);

      if (this.hasPositiveMaxTaxRate(item.rateType) && item.rate > maxTaxRate) {
        return this.$t("warnings.exceedTaxRate", [toMoney(maxTaxRate)]);
      }
      // no warnings
      return [];
    },
    verifyTaxRatesWarningsForTrailerRate(trailerRate) {
      if (!trailerRate) {
        return [];
      }

      if (this.hasTrailerMaxTaxRate && trailerRate > this.trailerMaxTaxRate) {
        const result = this.$t("warnings.exceedTaxRate", [
          toMoney(this.trailerMaxTaxRate)
        ]);
        return result;
      }

      return [];
    },
    verifyTaxRatesWarningsForPassengerRate(passangerRate) {
      if (!passangerRate) {
        return [];
      }
      if (
        this.hasPassengerMaxTaxRate &&
        passangerRate > this.passengerMaxTaxRate
      ) {
        const result = this.$t("warnings.exceedTaxRate", [
          toMoney(this.passengerMaxTaxRate)
        ]);
        return result;
      }

      return [];
    },
    getVehicleEnabledValidationId(rateType) {
      return `enabled_vehicleType_${rateType}`;
    },
    getVehicleAmountValidationId(rateType) {
      return `amount_vehicleType_${rateType}`;
    },
    getMaxTaxRate(rateType) {
      if (this.vehicleTaxRates?.vehicleTypeMaxTaxRates) {
        const res = this.vehicleTaxRates.vehicleTypeMaxTaxRates.find(
          x => x.claimType === rateType
        );

        if (res) {
          return res.maxTaxRate;
        } else {
          return null;
        }
      }
      return null;
    },
    hasPositiveMaxTaxRate(rateType) {
      const maxRate = this.getMaxTaxRate(rateType);

      return maxRate && maxRate > 0;
    }
  }
};
</script>