import Vue from "@/main";

const errorStore = {
    namespaced: true,
    state: {
        error: null
    },
    mutations: {
        set(state, text) {
            Vue.$set(state, "error", text);
        },
        reset(state) {
            Vue.$set(state, "error", null);
        }
    },
    getters: {
        error: state => state.error
    }
};

export default errorStore;