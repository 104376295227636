<template>
  <v-skeleton-loader :loading="loading" type="table">
    <v-card>
      <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{$t('rateConfigurationSummariesStrings.title')}}</h2>
          <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onHelpClick"
          >help_outline</v-icon>
      </v-card-title>
      <v-card-text v-if="canDisplayRateSummaries">
        <v-simple-table>
          <thead>
            <tr>
              <th>{{$t('rateConfigurationSummariesStrings.tableHeaders.fromDate')}}</th>
              <th>{{$t('rateConfigurationSummariesStrings.tableHeaders.toDate')}}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rate, index) in orderedSummaries" :key="rate.id">
              <td>{{ rate.fromDate | moment($t("commonStrings.dateDisplayFormat"))}}</td>
              <td
                v-if="orderedSummaries[index + 1]"
              >{{ $moment(orderedSummaries[index + 1].fromDate).subtract(1, "days") | moment($t("commonStrings.dateDisplayFormat")) }}</td>
              <td v-else>-</td>
              <td>
                <router-link
                  :to="{name: 'rateConfigurationDetails', params: {id: rate.id}}"
                >{{$t('rateConfigurationSummariesStrings.labels.details')}}</router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="info">
          {{$t('rateConfigurationSummariesStrings.cannotEditForChildOrgInfo')}}
        </v-alert>
      </v-card-text>
      <v-card-actions v-if="canDisplayRateSummaries">
        <v-btn
          :to="'/backoffice/period/create'"
          small
          rounded
          class="primary-inverted-button">
          <v-icon tag="span" left>mdi-plus</v-icon>
          {{$t('rateConfigurationSummariesStrings.labels.new')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import { api } from "@/utils/api";
import { mapGetters, mapMutations } from "vuex";
import { copy } from "@/utils/object";
import claimPanel from "@/mixins/claimPanel";
import { handleError } from "@/utils/errorHandler";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";

export default {
  name: "RateSummaries",
  data() {
    return {
      loading: true,
      summaries: []
    };
  },
  mixins: [claimPanel],
  props: {
    selectedOrgId: Number
  },
  computed: {
    ...mapGetters({
      orgId: "contextStore/orgId"
    }),
    orderedSummaries() {
      // as dates are in ISO 8601
      // and both of them are in the same
      // timezone, we can compare them
      // lexicographically
      return copy(this.summaries).sort((a, b) =>
        a.fromDate.localeCompare(b.fromDate)
      );
    },
    canDisplayRateSummaries() {
      return this.selectedOrgId && this.selectedOrgId === this.orgId;
    }
  },
  methods: {
    ...mapMutations({
      setRateSummaries: "settings/rateSummariesStore/setRateSummaries"
    }),
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.OrgSettings, [1]);
    },
    async fetch() {
      try {
        const rateResponse = await api.v1.orgs.rateConfigurations(this.orgId);
        if (rateResponse.status === 200) {
          this.summaries = rateResponse.data;
        }
      } catch (error) {
        await handleError(error);
      }

      this.loading = false;
      this.$emit("rate-summaries-loaded");
    },
    clear() {
      this.summaries = [];
      this.loading = false;
      this.$emit("rate-summaries-loaded");
    }
  },
  watch: {
    summaries(summaries) {
      this.setRateSummaries(summaries);
    },
    selectedOrgId: {
      handler: async function selectedOrgId(val) {
        this.loading = true;
        if(val) {
          await this.fetch();
        } else {
          this.clear();
        }
      },
      immediate: true
    }
  }
};
</script>