<template>
  <div>
    <breadcrumb-block>
      <breadcrumb-router-link to="/applications">
        {{ $t("commonStrings.toOverview") }}
      </breadcrumb-router-link>
    </breadcrumb-block>
    <validation-observer ref="generalObserver">
      <v-row>
        <v-col cols="12" lg="6" order-lg="2">
          <person-information
            :initializing="initializing"
            :initPersonId.sync="application.personId"
            :title="$t('personInformationStrings.title')"
          ></person-information>
        </v-col>
        <v-col cols="12" lg="6" order-lg="1">
          <general-details-panel
            :application="application"
          ></general-details-panel>
        </v-col>
      </v-row>
    </validation-observer>
    <v-row>
      <v-col>
        <v-expansion-panels multiple :value="[0, 1, 2, 3]">
          <salary-panel
            :payingOrgs="application.orgs"
            :claims="application.claims.salaries"
            :immutableSalaryClaimsCollection="immutableSalaryClaimsCollection"
            :editableSalaryClaimPayingOrgs="editableSalaryClaimPayingOrgs"
            :enableAddNew="generalInfoIsValid"
          ></salary-panel>
          <trips-panel
            :payingOrgs="application.orgs"
            :claims="application.claims.trips"
            :canUseSalaryFraction="canUseSalaryFraction"
            :enableAddNew="generalInfoIsValid"
          ></trips-panel>
          <validation-observer slim ref="dietObserver">
            <diet-panel
              :diet="application.claims.diet"
              @add-default-diet="onDietAdd"
              @remove-current-diet="onDietRemove"
              :canUseSalaryFraction="canUseSalaryFraction"
              :enableAddNew="generalInfoIsValid"
            ></diet-panel>
          </validation-observer>
          <receipts-panel
            :payingOrgs="application.orgs"
            :claims="application.claims.receipts"
            :canUseSalaryFraction="canUseSalaryFraction"
            :enableAddNew="generalInfoIsValid"
          ></receipts-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <summary-panel :claims="application.claims"></summary-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-btn
            rounded
            class="primary-inverted-button"
            :loading="isSaving"
            :disabled="!hasChanges || isSaving || isTypingResponse"
            :block="$vuetify.breakpoint.xsOnly"
            @click="saveClicked"
            >{{ $t("editApplicationStrings.saveBtn") }}</v-btn
          >
          <v-btn
            rounded
            :loading="isSaving"
            :disabled="!canSend || isSaving || isTypingResponse"
            :block="$vuetify.breakpoint.xsOnly"
            class="primary-button ml-0 ml-sm-2 mt-2 mt-sm-0"
            @click="sendClicked"
            >{{ $t("editApplicationStrings.sendBtn") }}</v-btn
          >
      </v-col>
    </v-row>
    <v-row
      v-if="
        (application.logEvents && application.logEvents.length > 0) ||
        (application.comments && application.comments.length > 0)
      "
    >
      <v-col>
        <communication-panel
          :log="application.logEvents"
          :comments="application.comments"
          :orgs="payingOrgs"
          :application-person-id="application.personId"
          :current-user="user"
          :current-org-id="currentOrgId"
          @reply="appendReply"
          :application-id="application.id"
          :can-write-as-org="false"
          :is-draft="application.isDraft"
          :is-typing-response="isTypingResponse"
          :initializing="initializing"
          :source="application.databaseSource"
          @comment-changed="setIsTypingResponse($event)"
        ></communication-panel>
      </v-col>
    </v-row>

    <div class="mt-4"></div>
  </div>
</template>

<script>
import _ from "lodash";
import { api, urls } from "@/utils/api";

import { ValidationObserver } from "vee-validate";
import ObjectId from "bson-objectid";
import { compare } from "fast-json-patch";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { handleError } from "@/utils/errorHandler";

import OrgApplicationStatusTypes from "@/enums/OrgApplicationStatusTypes";
import PersonInformation from "@/views/applications/components/PersonInformation";
import SalaryPanel from "./salaries/SalaryPanel";
import ReceiptsPanel from "./receipts/ReceiptsPanel";
import TripsPanel from "./trips/TripsPanel";
import DietPanel from "./diet/DietPanel";
import SummaryPanel from "@/views/applications/components/summary/SummaryPanel";
import GeneralDetailsPanel from "./components/GeneralDetailsPanel";
import CommunicationPanel from "@/views/applications/components/communication/CommunicationPanel";

import validationErrors from "@/mixins/validationErrors";
import application from "@/mixins/application";
import BreadcrumbBlock from "@/views/common/components/BreadcrumbBlock";
import BreadcrumbRouterLink from "@/views/common/components/BreadcrumbRouterLink";

export default {
  name: "EditApplication",
  components: {
    ValidationObserver,
    PersonInformation,
    ReceiptsPanel,
    SalaryPanel,
    SummaryPanel,
    TripsPanel,
    DietPanel,
    GeneralDetailsPanel,
    CommunicationPanel,
    BreadcrumbBlock,
    BreadcrumbRouterLink
  },
  data: function () {
    return {
      generalInfoIsValid: false
    };
  },
  mixins: [application, validationErrors],
  methods: {
    ...mapActions({
      fetchRateConfigurationsForOrg:
        "application/rateConfigurationsStore/fetchByOrgId",
      fetchTaxRates: "currentTaxRatePeriodStore/fetch"
    }),
    ...mapMutations({
      setPayingOrgs: "application/payingOrgsStore/setPayingOrgs"
    }),
    ...mapGetters({
      getRateConfiguration:
        "application/rateConfigurationsStore/rateConfiguration"
    }),
    onDietAdd() {
      this.$set(this.application.claims, "diet", { stays: [] });
    },
    onDietRemove() {
      this.$delete(this.application.claims, "diet");
    },
    // we need to clean rejections if case of
    // application has been rejected and returned
    // to user to do corrections
    async saveClicked() {
      if (!this.application.isDraft) {
        this.application.isDraft = true;
      }
      this.application.rateConfigurations = null;
      this.resetOrgActions();

      await this.save();
    },
    getUsedRateConfigurations() {
      const getRateConfigurationFunc = this.getRateConfiguration();

      const rateConfigurationIds = this.getRateConfigurationsUsedInNonDietClaims(getRateConfigurationFunc);
      const dietRateConfigurationIds = this.getRateConfigurationsUsedInDietClaims(getRateConfigurationFunc);

      return rateConfigurationIds.concat(dietRateConfigurationIds.filter(x => rateConfigurationIds.indexOf(x) < 0));
    },
    getRateConfigurationsUsedInNonDietClaims(getRateConfigurationFunc) {
      const rateConfigurationIds = [];

      Object.values(this.application.claims)
        .flat()
        .filter(x => x?.date && x?.payingOrgs && !x?.isDeleted)
        .forEach(function (claim) {
          for (const orgId of claim.payingOrgs) {
            const rateConfiguration = getRateConfigurationFunc(
              orgId,
              claim.date
            );

            if (
              rateConfiguration &&
              !rateConfigurationIds.includes(rateConfiguration.id)
            ) {
              rateConfigurationIds.push(rateConfiguration.id);
            }
          }
        }, this);

        return rateConfigurationIds;
    },
    getRateConfigurationsUsedInDietClaims(getRateConfigurationFunc) {
      if (!this.application.claims.diet?.stays ||
        !this.application.claims.diet.payingOrgs) {
          return [];
      }

      const rateConfigurationIds = [];

        for (const stay of this.application.claims.diet.stays) {
          for (const orgId of this.application.claims.diet.payingOrgs) {
            const rateConfiguration = getRateConfigurationFunc(
              orgId,
              stay.date
            );

            if (
              rateConfiguration &&
              !rateConfigurationIds.includes(rateConfiguration.id)
            ) {
              rateConfigurationIds.push(rateConfiguration.id);
            }
          }
        }

      return rateConfigurationIds;
    },
    async sendClicked() {
      this.resetOrgActions();
      this.application.rateConfigurations = this.getUsedRateConfigurations();

      await this.save();
    },
    resetOrgActions() {
      for (const orgId in this.application.orgs) {
        if (this.application.orgs[orgId].rejection) {
          this.$set(this.application.orgs[orgId], "rejection", null);
          this.$set(
            this.application.orgs[orgId],
            "orgApplicationStatus",
            OrgApplicationStatusTypes.Pending
          );
        }
      }
    },
    async save() {
      const observers = [this.$refs.generalObserver, this.$refs.dietObserver];

      const validationResults = await Promise.all(
        observers.map(async x => await x.validate())
      );
      const isDataValid = validationResults.every(x => x);

      if (!isDataValid) {
        this.showValidationErrorSnackbar(observers);
        return;
      }

      if (
        this.application.claims?.diet?.stays?.length &&
        !this.application.claims?.diet?.amount
      ) {
        this.$snacks.add(this.$t("editApplicationStrings.dietWithoutAmount"),
          "error"
        );

        return;
      }

      if (!this.claimCount) {
        this.$snacks.add(this.$t("editApplicationStrings.noClaimsError"),
          "error"
        );
        return;
      }

      if (this.application.rateConfigurations && this.claimTotalAmount <= 0) {
        this.$snacks.add(this.$t("editApplicationStrings.noClaimAmountError"),
          "error"
        );
        return;
      }

      this.application.orgs = _.pick(this.application.orgs, this.usedOrgs);

      const filteredPayingOrgs = this.payingOrgs.filter(
        x => this.application.orgs[x.id]
      );

      this.setPayingOrgs(filteredPayingOrgs);

      if (ObjectId.isValid(this.application.id)) {
        this.updateApplication("/applications");
      } else {
        this.insertApplication();
      }
    },
    async insertApplication() {
      try {
        const applicationToInsert = this.getApplicationWithDraftState(
          this.application
        );
        const response = await api.v1.applications.post(applicationToInsert);

        if (response.status === 201) {
          this.$snacks.add(this.$t("editApplicationStrings.saveAlerts.saved"),
            "success",
            true
          );
          this.$router.push("/applications");
        }
      } catch (error) {
        await handleError(error);
      }
    },
    async validateGeneral() {
      const observer = this.$refs.generalObserver;

      const valid = await observer.validate();
      this.generalInfoIsValid = valid;
    }
  },
  computed: {
    hasChanges() {
      return compare(this.originalState, this.application).length;
    },
    canSend() {
      // if it's just created can be send directly
      // if it's existing (valid object id) and in draft can be send
      return (
        this.hasChanges ||
        (ObjectId.isValid(this.application.id) && this.application.isDraft)
      );
    },
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs",
      user: "oidcStore/oidcUser",
      currentOrgId: "contextStore/orgId"
    }),
    isPosting() {
      return this.urlState(urls.v1.applications.post());
    },
    isPatching() {
      if (this.application?.id) {
        return this.urlState(urls.v1.applications.patch(this.application.id));
      }

      return false;
    },
    isSaving() {
      return this.isPosting || this.isPatching;
    },
    usedOrgs() {
      return Object.values(this.application.claims)
        .flat()
        .filter(x => x?.payingOrgs && !x.isDeleted)
        .map(x => x.payingOrgs)
        .flat()
        .map(x => x.toString());
    },
    claimCount() {
      return Object.values(this.application.claims)
        .flat()
        .filter(x => x && !x.isDeleted).length;
    },
    claimTotalAmount() {
      return _.sum(
        Object.values(this.application.claims)
          .flat()
          .filter(x => x && !x.isDeleted)
          .map(x => x.amount));
    }

  },
  async mounted() {
    if (location.href.indexOf("applications/create") < 0) {
      await this.fetch();
    } else {
      this.application.functionalityId = Number(this.user.application_id);
      this.application.personId = Number(this.user.personid);
    }

    this.initializing = false;
  },
  watch: {
    "application.applicationName": {
      async handler() {
        await this.validateGeneral();
      }
    },
    "application.accountNumber": {
      async handler() {
        await this.validateGeneral();
      }
    },
    async payingOrgs(payingOrgs) {
      // keys have to be strings for valid json
      const payingOrgIds = payingOrgs.map(x => x.id.toString());

      for (const payingOrgId of payingOrgIds) {
        if (!this.application.orgs[payingOrgId]) {
          this.$set(this.application.orgs, payingOrgId, {
            orgApplicationStatus: OrgApplicationStatusTypes.Pending
          });
        }
      }

      for (const orgId in this.application.orgs) {
        if (!payingOrgIds.includes(orgId)) {
          this.$delete(this.application.orgs, orgId);
        }
      }

      if (payingOrgs?.length) {
        const orgIds = payingOrgs.map(x => x.id);

        for (const orgId of orgIds) {
          try {
            await this.fetchRateConfigurationsForOrg(orgId);
          } catch (error) {
            await handleError(error);
          }
        }
      }

      await this.validateGeneral();
    }
  },
  async beforeMount() {
    try {
      await this.fetchTaxRates();
    } catch (error) {
      await handleError(error);
    }
  }
};
</script>
