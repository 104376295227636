import Vue from 'vue';
import Router from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import store from "@/store";
import VueInstance from "@/main";
import i18n from '@/plugins/i18n';

import PublicPage from '@/views/public/PublicPage.vue';
import ApplicationDetailsPage from '@/views/applications/ApplicationDetailsPage.vue';
import JournalDetailsPage from '@/views/applications/journal/JournalDetailsPage.vue';
import OrgApplicationsPage from '@/views/orgapplications/OrgApplicationsPage.vue';
import PersonalSettingsPage from '@/views/personalsettings/PersonalSettingsPage.vue';
import SettingsPage from '@/views/generalsettings/SettingsPage.vue';
import RateConfigurationPage from '@/views/rateconfigurations/RateConfigurationPage.vue';
import OidcCallback from '@/views/oidc/OidcCallbackPage.vue';
import OidcCallbackError from '@/views/oidc/OidcCallbackErrorPage.vue';
import ErrorPage from "@/views/error/ErrorPage.vue";
import StatisticsPage from '@/views/statistics/StatisticsPage.vue';
import OrgFeesInvoicePage from '@/views/orgFeesInvoice/OrgFeesInvoicePage.vue';
import StartPage from '@/views/start/StartPage.vue';
import RemittancePage from '@/views/remittance/RemittancePage.vue';
import SenderInformationPage from '@/views/senderInformation/SenderInformationPage.vue';
import InfoMessagesPage from '@/views/messages/MessageAdminPage.vue';

Vue.use(Router);

const applicationGuard = async function (to, _from, next) {
  try {
    await store.dispatch("application/currentApplicationStore/fetch", to.params.id);
    next();
  } catch (error) {
    await store.commit("errorStore/set", error || {});
    next('error');
  }
};


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'public',
      component: PublicPage,
      meta: {
        isPublic: true,
        layout: 'public'
      },
      beforeEnter: (_to, _from, next) => {
        const isAuthenticated = store.getters['oidcStore/oidcIsAuthenticated'];
        if (isAuthenticated) {
          next({ path: '/start' });
        } else {
          next();
        }
      }
    },
    {
      path: '/messages',
      name: 'messages',
      component: InfoMessagesPage,
      meta: {
        title: i18n.t("infoMessagesStrings.pageTitle")
      }
    },
    {
      path: '/backoffice',
      name: 'backoffice',
      component: SettingsPage,
      meta: {
        title: i18n.t("backofficeStrings.pageTitle")
      }
    },
    {
      path: '/backoffice/period/create',
      name: 'createRateConfiguration',
      component: RateConfigurationPage,
      meta: {
        title: i18n.t("rateConfigurationStrings.pageTitle")
      }
    },
    {
      path: '/backoffice/period/:id',
      name: 'rateConfigurationDetails',
      component: RateConfigurationPage,
      meta: {
        title: i18n.t("rateConfigurationStrings.pageTitle")
      }
    },
    {
      path: '/orgapplications/:id/orgs/:orgid/journal',
      name: 'journalDetails',
      component: JournalDetailsPage,
      beforeEnter: applicationGuard,
      meta: {
        title: i18n.t("journalStrings.pageTitle"),
        addTitleId: true,
        addTitleOrgName: true
      }
    },
    {
      path: '/orgapplications',
      name: 'orgApplications',
      component: OrgApplicationsPage,
      meta: {
        title: i18n.t("orgApplicationsStrings.pageTitle")
      }
    },
    {
      path: '/remittances',
      name: 'remittances',
      component: RemittancePage,
      meta: {
        title: i18n.t("remittancesStrings.pageTitle")
      }
    },
    {
      path: '/senderInformation',
      name: 'senderInformation',
      component: SenderInformationPage,
      meta: {
        title: i18n.t("senderInformationStrings.pageTitle")
      }
    },
    {
      path: '/orgapplications/:id',
      name: 'orgApplicationDetails',
      component: ApplicationDetailsPage,
      beforeEnter: applicationGuard,
      meta: {
        title: i18n.t("editApplicationStrings.detailsPageTitle"),
        addTitleId: true
      }
    },
    {
      path: '/personalsettings',
      name: 'personalSettings',
      component: PersonalSettingsPage,
      meta: {
        title: i18n.t("personalSettingsStrings.pageTitle")
      }
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: StatisticsPage,
      meta: {
        title: i18n.t("statisticsStrings.pageTitle")
      }
    },
    {
      path: '/orgFeesInvoice',
      name: 'orgFeesInvoice',
      component: OrgFeesInvoicePage,
      meta: {
        title: i18n.t("orgFeesInvoiceStrings.pageTitle")
      }
    },
    {
      path: '/start',
      name: 'start',
      component: StartPage,
      meta: {
        title: i18n.t("startPageStrings.pageTitle"),
        styleOverride: 'image-title'
      }
    },
    // Needs to match redirectUri in you oidcSettings
    {
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: OidcCallback
    },
    // Needs to match redirect_uri in you oidcSettings
    {
      path: '/oidc-callback-error',
      name: 'oidcCallbackError',
      component: OidcCallbackError,
      meta: {
        isPublic: true
      }
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
      beforeEnter: function (_to, _from, next) {
        if (store.getters['errorStore/error']) {
          next();
        } else {
          next('applications');
        }
      }
    },
    {
      path: '*',
      name: 'default',
      component: ErrorPage,
      beforeEnter: async function (_to, _from, next) {
        const notFound = i18n.t(`httpErrorCodes.404`);
        await store.commit("errorStore/set", notFound);
        next();
      }
    }
  ]
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

router.afterEach(() => {
  VueInstance.$snacks.clearAll();
});

export default router;
