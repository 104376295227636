<template>
  <v-app-bar class="primary" clipped-left app height="90" flat>
    <slot name="outside-left"></slot>
    <slot name="title">
      <img src="https://www.idrettsforbundet.no/Static/gfx/logo-hvit.svg" alt="Norges Idrettsforbund" height="70" />
    </slot>
    <slot name="inside-left"></slot>
    <v-spacer></v-spacer>
    <slot name="inside-right"></slot>
    <slot name="menu"></slot>
    <slot name="outside-right"></slot>
  </v-app-bar>
</template>

<script>
export default {
  name: "HeaderBlock"
};
</script>
