import HelpBarContentTypes from "@/enums/HelpBarContentTypes";

const helpBarStore = {
    namespaced: true,
    state: {
        visible: false,
        contentType: HelpBarContentTypes.None,
        expandedSections: []
    },
    mutations: {
        show(state, options) {
            state.contentType = options.contentType;
            state.expandedSections = options.expandedSections;
            state.visible = true;
        },
        hide(state) {
            state.visible = false;
            state.expandedSections = [];
        }
    },
    getters: {
        visible: state => state.visible,
        contentType: state => state.contentType,
        expandedSections: state => state.expandedSections
    }
};

export default helpBarStore;