import Vue from "vue";
import VueI18n from "vue-i18n";
import noVuetify from "vuetify/src/locale/no.ts";
import noCommonStrings from "./i18n/no/commonStrings";
import noErrorMessages from "./i18n/no/errorMessages";
import noMenuStrings from "./i18n/no/menuStrings";
import noAppLayoutStrings from "./i18n/no/appLayoutStrings";
import noContextSwitchStrings from "./i18n/no/contextSwitchStrings";
import noDatePickerStrings from "./i18n/no/datePickerStrings";
import noPersonalSettingsStrings from "./i18n/no/personalSettingsStrings";
import noSummaryPanelStrings from "./i18n/no/summaryPanelStrings";
import noRateConfigurationStrings from "./i18n/no/rateConfigurationStrings";
import noCommunicationPanelStrings from "./i18n/no/communicationPanelStrings";
import noClaimPickersStrings from "./i18n/no/claimPickersStrings";
import noPayingOrgsPickerStrings from "./i18n/no/payingOrgsPickerStrings";
import noTripRateTooltipStrings from "./i18n/no/tripRateTooltipStrings";
import noTripsSummaryPanelStrings from "./i18n/no/tripsSummaryPanelStrings";
import noDietSummaryPanelStrings from "./i18n/no/dietSummaryPanelStrings";
import noSalaryPanelStrings from "./i18n/no/salaryPanelStrings";
import noClaimTypeAvailabilityLabelStrings from "./i18n/no/claimTypeAvailabilityLabelStrings";
import noSalaryTableStrings from "./i18n/no/salaryTableStrings";
import noReceiptsPanelStrings from "./i18n/no/receiptsPanelStrings";
import noDrivingPanelStrings from "./i18n/no/drivingPanelStrings";
import noDietPanelStrings from "./i18n/no/dietPanelStrings";
import noBackofficeStrings from "./i18n/no/backofficeStrings";
import noGeneralSettingsStrings from "./i18n/no/generalSettingsStrings";
import noProductListSettingsStrings from "./i18n/no/productListSettingsStrings";
import noRateConfigurationSummariesStrings from "./i18n/no/rateConfigurationSummariesStrings";
import noEditApplicationStrings from "./i18n/no/editApplicationStrings";
import noPersonInformationStrings from "./i18n/no/personInformationStrings";
import noOrgSearchStrings from "./i18n/no/orgSearchStrings";
import noApplicationStatusPanelStrings from "./i18n/no/applicationStatusPanelStrings";
import noPreviewApplicationPageStrings from "./i18n/no/previewApplicationPageStrings";
import noApplicationsStrings from "./i18n/no/applicationsStrings";
import noPaymentMethodLabelStrings from "./i18n/no/paymentMethodLabelStrings";
import noSenderInformationStrings from "./i18n/no/senderInformationStrings";
import noRemittancesStrings from "./i18n/no/remittancesStrings";
import noOrgApplicationsStrings from "./i18n/no/orgApplicationsStrings";
import noStatusLabelStrings from "./i18n/no/statusLabelStrings";
import noAttachmentUploadStrings from "./i18n/no/attachmentUploadStrings";
import noAttachmentListStrings from "./i18n/no/attachmentListStrings";
import noJournalStrings from "./i18n/no/journalStrings";
import noOrgFeesInvoiceStrings from "./i18n/no/orgFeesInvoiceStrings";
import noStatisticsStrings from "./i18n/no/statisticsStrings";
import noErrorPageStrings from "./i18n/no/errorPageStrings";
import noStartPageStrings from "./i18n/no/startPageStrings";
import noPublicPageStrings from "./i18n/no/publicPageStrings";
import noHelpBarStrings from "./i18n/no/helpBarStrings";
import noInfoMessagesStrings from "./i18n/no/infoMessagesStrings";
import noFinancialDimensionStrings from "./i18n/no/financialDimensionStrings";
import noHelp from "./i18n/no/help";
import noValidations from "./i18n/no/validations";
import noWarnings from "./i18n/no/warnings";
import noFooter from "./i18n/no/footer";
import noMenuBlock from "./i18n/no/menuBlock";
import noHeaderBlock from "./i18n/no/headerBlock";
import noEnums from "./i18n/no/enums";
import noHttpErrorCodes from "./i18n/no/httpErrorCodes";
import noBuypassPaymentErrorStrings from "./i18n/no/buypassPaymentErrorStrings";
import noHttpErrorHandler from "@nifnpm/vue-error-utils/dist/locales/no";
import noSnackbarQueue from "@nifnpm/vue-snackbar-queue/dist/locales/no";

Vue.use(VueI18n);

const messages = {
  no: {
    commonStrings: noCommonStrings,
    errorMessages: noErrorMessages,
    menuStrings: noMenuStrings,
    appLayoutStrings: noAppLayoutStrings,
    contextSwitchStrings: noContextSwitchStrings,
    datePickerStrings: noDatePickerStrings,
    personalSettingsStrings: noPersonalSettingsStrings,
    rateConfigurationStrings: noRateConfigurationStrings,
    summaryPanelStrings: noSummaryPanelStrings,
    communicationPanelStrings: noCommunicationPanelStrings,
    claimPickersStrings: noClaimPickersStrings,
    payingOrgsPickerStrings: noPayingOrgsPickerStrings,
    tripRateTooltipStrings: noTripRateTooltipStrings,
    tripsSummaryPanelStrings: noTripsSummaryPanelStrings,
    dietSummaryPanelStrings: noDietSummaryPanelStrings,
    salaryPanelStrings: noSalaryPanelStrings,
    claimTypeAvailabilityLabelStrings: noClaimTypeAvailabilityLabelStrings,
    salaryTableStrings: noSalaryTableStrings,
    receiptsPanelStrings: noReceiptsPanelStrings,
    drivingPanelStrings: noDrivingPanelStrings,
    dietPanelStrings: noDietPanelStrings,
    backofficeStrings: noBackofficeStrings,
    generalSettingsStrings: noGeneralSettingsStrings,
    productListSettingsStrings: noProductListSettingsStrings,
    rateConfigurationSummariesStrings: noRateConfigurationSummariesStrings,
    editApplicationStrings: noEditApplicationStrings,
    personInformationStrings: noPersonInformationStrings,
    orgSearchStrings: noOrgSearchStrings,
    applicationStatusPanelStrings: noApplicationStatusPanelStrings,
    previewApplicationPageStrings: noPreviewApplicationPageStrings,
    applicationsStrings: noApplicationsStrings,
    paymentMethodLabelStrings: noPaymentMethodLabelStrings,
    senderInformationStrings: noSenderInformationStrings,
    remittancesStrings: noRemittancesStrings,
    orgApplicationsStrings: noOrgApplicationsStrings,
    statusLabelStrings: noStatusLabelStrings,
    attachmentUploadStrings: noAttachmentUploadStrings,
    attachmentListStrings: noAttachmentListStrings,
    journalStrings: noJournalStrings,
    orgFeesInvoiceStrings: noOrgFeesInvoiceStrings,
    statisticsStrings: noStatisticsStrings,
    errorPageStrings: noErrorPageStrings,
    startPageStrings: noStartPageStrings,
    publicPageStrings: noPublicPageStrings,
    helpBarStrings: noHelpBarStrings,
    infoMessagesStrings: noInfoMessagesStrings,
    financialDimensionStrings: noFinancialDimensionStrings,
    help: noHelp,
    validations: noValidations,
    warnings: noWarnings,
    footer: noFooter,
    menuBlock: noMenuBlock,
    headerBlock: noHeaderBlock,
    enums: noEnums,
    httpErrorCodes: noHttpErrorCodes,
    $vuetify: noVuetify,
    httpErrorHandler: noHttpErrorHandler,
    snackbarQueue: noSnackbarQueue
  }
};

//Matches path currently in use in vue.error.utils.
messages.no.httpErrorHandler.buypassPaymentErrorStrings = noBuypassPaymentErrorStrings;

// Create VueI18n instance with options
const i18n = new VueI18n({
  // set locale
  locale: "no",
  fallbackLocale: "no",
  // set locale messages
  messages
});

export default i18n;
