<template>
  <component :is="layout" :style-override="styleOverride">
    <router-view />
    <v-snackbar-queue></v-snackbar-queue>
  </component>
</template>

<script>
const default_layout = "app";
import { mapGetters, mapActions } from "vuex";
import i18n from "./plugins/i18n";
import { getOrgDisplayName } from "@/utils/org";
import { setDefaultSearchCriteriaDates } from "@/utils/searchCriteria";

export default {
  name: "App",
  methods: {
    //oidc seems to trigger userLoaded before token is set, using access_token provided by the user object instead of oidc store.
    userLoaded: async function (user) {
      await this.fetchFunctionTypes({ token: user.detail.access_token });
      await this.fetchFunctions({
        personId: user.detail.profile.personid,
        token: user.detail.access_token
      });
      await this.fetchCurrentOrgs({
        personId: user.detail.profile.personid,
        token: user.detail.access_token
      });
      await this.fetchInfoMessages({ token: user.detail.access_token });
      await this.initContext(this.orgsWithPermissions.map(x => x.org));
      setDefaultSearchCriteriaDates(user.detail.profile.personid);
    },
    ...mapActions({
      fetchFunctionTypes: "functionTypes/fetch",
      initContext: "contextStore/init",
      fetchFunctions: "currentOrgsStore/fetchFunctions",
      fetchCurrentOrgs: "currentOrgsStore/fetch",
      fetchInfoMessages: "infoMessagesStore/fetch"
    })
  },
  computed: {
    route() {
      return this.$route;
    },
    layout() {
      return `${this.$route.meta.layout || default_layout}-layout`;
    },
    styleOverride() {
      return this.$route.meta.styleOverride;
    },
    ...mapGetters({
      currentOrgId: "contextStore/orgId",
      orgsWithPermissions: "currentOrgsStore/orgs",
      messages: "infoMessagesStore/messages"
    }),
    currentOrgName() {
      const currentOrg = this.orgsWithPermissions.find(
        x => x.org.id === this.currentOrgId
      );
      if (currentOrg) {
        return getOrgDisplayName(currentOrg?.org);
      }

      return "";
    }
  },
  watch: {
    currentOrgId(_newValue, oldValue) {
      if (
        oldValue &&
        this.orgsWithPermissions &&
        this.orgsWithPermissions.some(x => x.org.id === oldValue)
      ) {
        window.location.href = "/orgapplications";
      }
    },
    route(to) {
      const values = [to.meta.title];

      if (to.meta.addTitleId && to.params.id) {
        values.push(`${to.params.id}`);
      }

      if (to.meta.addTitleOrgName) {
        values.push(this.currentOrgName);
      } else {
        values.push(i18n.t("commonStrings.appName"));
      }

      document.title = values.filter(Boolean).join(" - ");
    }
  },
  mounted() {
    window.addEventListener("vuexoidc:userLoaded", this.userLoaded);
  },
  destroyed() {
    window.removeEventListener("vuexoidc:userLoaded", this.userLoaded);
  }
};
</script>
