<template>
  <div class="senderInformation">
    <sender-information-dialog
      :item="editedPerson"
      :currentIndex="editedIndex"
      :enabledPaymentMethods="availablePaymentMethods"
      @edit-cancelled="onCancelEdit"
      @item-changed="onUpdated"
    ></sender-information-dialog>
    <v-card>
      <v-card-title class="py-0"
        ><h1 class="text-h2 mt-10 mb-7">
          {{ $t("senderInformationStrings.pageTitle") }}
        </h1></v-card-title
      >
      <v-card-title class="py-0"
        ><h2 class="text-h2 mt-10 mb-7">
          {{ $t("senderInformationStrings.resultsSubheader", [filteredCount]) }}
        </h2></v-card-title
      >
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredPersons"
          :no-data-text="this.$t('senderInformationStrings.noDataText')"
          :no-results-text="this.$t('senderInformationStrings.noResultText')"
          :loading="loading"
        >
          <template v-slot:item.preferredPaymentMethod="{item}">
            {{ getPaymentMethodLabel(item.preferredPaymentMethod) }}
          </template>
          <template v-slot:item.editAction="{ item }">
            <v-btn
              small
              rounded
              class="primary-inverted-button my-2"
              @click="onEdit(item)"
              >{{ $t("senderInformationStrings.labels.editButton") }}</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { api, urls } from "@/utils/api";
import SenderInformationDialog from "./SenderInformationDialog.vue";
import { compare } from "fast-json-patch";
import { copy } from "@/utils/object";
import PaymentMethods from "@/enums/PaymentMethods";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "SenderInformationPage",
  components: {
    SenderInformationDialog
  },
  data() {
    return {
      editedPerson: {},
      editedIndex: -1,
      originalPersons: [],
      persons: [],
      orgConfiguration: {},
      headers: [
        {
          text: this.$t("senderInformationStrings.headers.name"),
          value: "name"
        },
        {
          text: this.$t("senderInformationStrings.headers.paymentMethod"),
          value: "preferredPaymentMethod"

        },
        {
          text: this.$t("senderInformationStrings.headers.edit"),
          value: "editAction",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      stateInitiated: "contextStore/initiated",
      currentOrgId: "contextStore/orgId",
      urlState: "utilsRestStore/urlState"
    }),
    loading() {
      return this.urlState(
        urls.v1.orgs.orgPersonConfigurations(this.currentOrgId)
      );
    },
    filteredPersons() {
      return this.persons;
    },
    filteredCount() {
      return this.filteredPersons.length;
    },
    availablePaymentMethods() {
      const methods = [PaymentMethods.Manual];

      if (this.orgConfiguration?.buypassSettings?.isActive) {
        methods.push(PaymentMethods.Buypass);
      }

      if (this.orgConfiguration?.remittanceSettings?.isActive) {
        methods.push(PaymentMethods.Remittance);
      }

      return methods;
    }
  },
  methods: {
    ...mapActions({
      getOrgConfiguration: "settings/orgConfigurationStore/getOrgConfiguration"
    }),
    getPaymentMethodLabel(paymentMethod) {
      if (this.$te(`enums.paymentMethods.${paymentMethod}`)) {
        return this.$t(`enums.paymentMethods.${paymentMethod}`);
      }

      return this.$t(`senderInformationStrings.undefinedPaymentMethod`);
    },
    onEdit(person) {
      this.editedPerson = { ...person };
      this.editedIndex = person.id;
    },
    resetEdited() {
      this.editedPerson = { };
      this.editedIndex = -1;
    },
    onCancelEdit() {
      this.resetEdited();
    },
    async onUpdated(changes) {
      try {
        const original = this.originalPersons.find(x => x.id === changes.id);
        const updated = copy(original);
        updated.preferredPaymentMethod = changes.selectedPaymentMethod;

        const patchDoc = compare(original, updated);

        const response = await api.v1.orgs.orgPersonConfigurationPatch(
          original.id,
          patchDoc
        );
        if (response.status === 204) {
          const position = this.originalPersons
            .map(x => x.id)
            .indexOf(original.id);

          this.originalPersons[position] = updated;
          this.persons = this.mapPersons(this.originalPersons);

          this.$snacks.add(this.$t("senderInformationStrings.successfullySaved"),
            "success"
          );
          this.resetEdited();
        }
      } catch (error) {
        await handleError(error);
        this.resetEdited();
      }
    },
    async fetch() {
      try {
        const response = await api.v1.orgs.orgPersonConfigurations(
          this.currentOrgId
        );

        if (response.status === 200) {
          let persons;

          if (response.data) {
            persons = response.data;
          } else {
            persons = [];
          }

          this.persons = this.mapPersons(persons);
          this.originalPersons = persons;
        }
      } catch (error) {
        await handleError(error);
      }
    },
    mapPersons(persons) {
      if (!persons?.length) {
        return [];
      }
      return persons.map(x => ({
        id: x.id,
        orgId: x.orgId,
        personId: x.personId,
        preferredPaymentMethod: x.preferredPaymentMethod,
        externalAmount: x.externalAmount,
        externalAmountLastUpdatedDate: x.externalAmountLastUpdatedDate,
        name: `${x.firstName} ${x.lastName}`
      }));
    }
  },
  watch: {
    stateInitiated: {
      handler: async function (val) {
        if (val) {
          this.orgConfiguration = await this.getOrgConfiguration(
            this.currentOrgId
          );
          await this.fetch();
        }
      },
      immediate: true
    }
  }
};
</script>