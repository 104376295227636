<template>
  <v-row v-if="areRemittanceSettingsVisible">
    <v-col cols="12">
      <h3 class="text-h3 mt-8 mb-5">
        {{ $t("generalSettingsStrings.remittanceSettings.title") }}
      </h3>
      <v-row v-if="canEditDataSenderId">
        <v-col cols="12" sm="6" md="3">
          <validation-provider
            :rules="{numeric: true, max: validationConstants.remittanceSettings.maxDataSenderIdLength, required_if: isDataSenderIdRequired}"
            :name="$t('generalSettingsStrings.remittanceSettings.labels.dataSenderId')"
            :custom-messages="{required_if: dataSenderIdValidationMessage}"
            v-slot="{ errors }"
          >
            <v-text-field
              outlined
              dense
              v-model="orgConfiguration.remittanceSettings.dataSenderId"
              :error-messages="errors"
              :label="$t('generalSettingsStrings.remittanceSettings.labels.dataSenderId')"
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <validation-provider
            :rules="{required_if: isActiveRequired}"
            :name="$t('generalSettingsStrings.remittanceSettings.labels.isActive')"
            :custom-messages="{required_if: $t('validations.isActiveRequiredWhenChildUsesAccount')}"
            v-slot="{ errors }"
          >
          <v-switch
            color="primary"
            inset
            :label="$t('generalSettingsStrings.remittanceSettings.labels.isActive')"
            :disabled="!canActivate"
            :error-messages="errors"
            v-model="orgConfiguration.remittanceSettings.isActive"
          ></v-switch>
          </validation-provider>
        </v-col>
      </v-row>
      <template v-if="canActivate">
        <v-row v-if="relationActivationRights.hasParentWithActiveRemittanceAccount">
          <v-col cols="12" sm="6" md="3">
            <v-btn-toggle
              v-model="orgConfiguration.remittanceSettings.useParentAccount"
              class="filter-buttons"
              mandatory
              rounded>
              <v-btn :value="true" :class="orgConfiguration.remittanceSettings.useParentAccount ? 'filter-button-active mr-2' : 'filter-button-inactive mr-2'">
                {{$t('generalSettingsStrings.remittanceSettings.labels.useParentAccount')}}
              </v-btn>
              <v-btn :value="false" :class="!orgConfiguration.remittanceSettings.useParentAccount ? 'filter-button-active mr-2' : 'filter-button-inactive mr-2'">
                {{$t('generalSettingsStrings.remittanceSettings.labels.useOwnAccount')}}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <validation-provider
              :rules="{required_if: areAccountDetailsRequired, numeric: true, max: validationConstants.remittanceSettings.maxAgreementIdLength}"
              :name="$t('generalSettingsStrings.remittanceSettings.labels.agreementId')"
              v-slot="{ errors }"
            >
              <v-text-field
                outlined
                dense
                v-model="orgConfiguration.remittanceSettings.agreementId"
                :disabled="orgConfiguration.remittanceSettings.useParentAccount"
                :error-messages="errors"
                :label="$t('generalSettingsStrings.remittanceSettings.labels.agreementId')"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <validation-provider
              :rules="{required_if: areAccountDetailsRequired, max: validationConstants.remittanceSettings.maxAccountNumberLength, account_number: true}"
              :name="$t('generalSettingsStrings.remittanceSettings.labels.accountNumber')"
              v-slot="{ errors }"
            >
              <v-text-field
                outlined
                dense
                v-model="orgConfiguration.remittanceSettings.accountNumber"
                :disabled="orgConfiguration.remittanceSettings.useParentAccount"
                :error-messages="errors"
                :label="$t('generalSettingsStrings.remittanceSettings.labels.accountNumber')"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="!hasPermittedContextOrgTypeForEdit">
        <em>{{$t('generalSettingsStrings.remittanceSettings.notAvailableForOrgTypeMessage')}}</em>
      </template>
      <template v-else>
        <em>{{$t('generalSettingsStrings.remittanceSettings.isActiveNotAvailableMessage')}}</em>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationProvider } from "vee-validate";
import OrgTypes from "@/enums/OrgTypes";
import { validationConstants } from "@/utils/validationConstants";
import { remittanceOrgIds } from "@/utils/featureFlags";

export default {
  name: "RemittanceSettings",
  components: {
      ValidationProvider
  },
  data() {
    return {
      lastAgreementId: null,
      lastAccountNumber: null,
      validationConstants: validationConstants
    };
  },
  props: {
    orgConfiguration: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    relationActivationRights: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    orgTypeId: Number
  },
  computed: {
    ...mapGetters({
      contextOrgId: "contextStore/orgId",
      contextOrgTypeId: "contextStore/orgTypeId"
    }),
    areRemittanceSettingsVisible() {
      return (
        !remittanceOrgIds() ||
        !remittanceOrgIds().length ||
        remittanceOrgIds().includes(this.contextOrgId)
      );
    },


    hasPermittedContextOrgTypeForEdit() {
      return this.contextOrgTypeId !== OrgTypes.GR && this.contextOrgTypeId !== OrgTypes.AI;
    },
    canEditDataSenderId() {
      return this.orgTypeId !== OrgTypes.GR && this.orgTypeId !== OrgTypes.AI;
    },
    requiresParentDataSenderId() {
      return this.orgTypeId === OrgTypes.GR || this.orgTypeId === OrgTypes.AI;
    },
    dataSenderIdValidationMessage() {
      if (this.relationActivationRights.hasChildWithActiveRemittanceAccount) {
        return this.$t('validations.dataSenderIdRequiredForChild');
      }

        return this.$t('validations.dataSenderIdRequiredForSelf');
    },
    isDataSenderIdRequired() {
      return this.relationActivationRights.hasChildWithActiveRemittanceAccount || this.orgConfiguration.remittanceSettings.isActive;
    },
    isActiveRequired() {
      return this.relationActivationRights.hasChildUsingParentAccount;
    },
    areAccountDetailsRequired() {
      return this.orgConfiguration.remittanceSettings.isActive && !this.orgConfiguration.remittanceSettings.useParentAccount;
    },
    parentAccountSelection() {
      return this.orgConfiguration.remittanceSettings.useParentAccount;
    },
    canActivate() {
        return (!this.requiresParentDataSenderId || this.relationActivationRights.hasParentWithValidDataSenderId) && this.hasPermittedContextOrgTypeForEdit;
    }
  },
    watch: {
      orgConfiguration: {
        handler: function orgConfiguration(val) {
        if (val) {
          this.lastAgreementId = val.remittanceSettings.agreementId;
          this.lastAccountNumber = val.remittanceSettings.accountNumber;
        }
        },
        immediate: true
      },
      parentAccountSelection(val) {
        //only relevant when parent account is available
        if (this.relationActivationRights.hasParentWithActiveRemittanceAccount) {
          if (val) {
            this.lastAgreementId = this.orgConfiguration.remittanceSettings.agreementId;
            this.lastAccountNumber = this.orgConfiguration.remittanceSettings.accountNumber;
            this.orgConfiguration.remittanceSettings.agreementId = null;
            this.orgConfiguration.remittanceSettings.accountNumber = null;
          } else {
            this.orgConfiguration.remittanceSettings.agreementId = this.lastAgreementId;
            this.orgConfiguration.remittanceSettings.accountNumber = this.lastAccountNumber;
          }
        }
      }
    }
  };
</script>