<template>
  <div class="body-1 py-4 inverted--text">
    <v-icon v-if="icon" class="inverted--text">{{icon}}</v-icon>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MenuHeader",
  props: {
    icon: String
  }
};
</script>