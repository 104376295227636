const InactiveReceiptTypes = {
  Parking: 1,
  Toll: 2,
  Phone: 4,
  Internet: 5,
  DryCleaning: 6
};

Object.freeze(InactiveReceiptTypes);

export default InactiveReceiptTypes;