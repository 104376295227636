<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col>
          <h2 class="text-h2 float-left">{{ $t("salaryPanelStrings.title") }} {{ claimsCountLabel }}</h2>
          <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onHelpClick"
          >help_outline</v-icon>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <salary-edit-dialog
        :editableSalaryClaimPayingOrgs="editableSalaryClaimPayingOrgs"
        :immutableSalaryClaimsCollection="immutableSalaryClaimsCollection"
        :claims="claims"
        :current-item="editedItem"
        :current-index="editedIndex"
        @new-item-added="addNewItem"
        @item-changed="updateItem"
        @edit-cancelled="editCancelled"
        :enableAddNew="enableAddNew"
      ></salary-edit-dialog>
      <v-row v-if="claimsCount">
        <v-col>
          <salary-table
            :claims="claims"
            :editableSalaryClaimPayingOrgs="editableSalaryClaimPayingOrgs"
            @edit-clicked="editItem"
          ></salary-table>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import claimPanel from "@/mixins/claimPanel";
import SalaryTable from "./SalaryTable";
import SalaryEditDialog from "./SalaryEditDialog";
import store from "@/store";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import HelpBarApplicationSectionTypes from "@/enums/HelpBarApplicationSectionTypes";

export default {
  name: "SalaryPanel",
  components: {
    SalaryTable,
    SalaryEditDialog
  },
  mixins: [claimPanel],
  data() {
    return {
      showPanel: false,
      defaultItem: { isDeleted: false },
      editedItem: { isDeleted: false },
      editedIndex: -1
    };
  },
  computed: {
    filteredClaims() {
      return (this.claims || []).filter(x => !x.isDeleted);
    },
    claimsCount() {
      return this.filteredClaims.length;
    },
    claimsCountLabel() {
      if (this.claimsCount) {
        return `(${this.claimsCount})`;
      }

      return "";
    },
    salaryPayingOrgs() {
      return this.claims
        .filter(x => !x.isDeleted)
        .map(x => x.payingOrgs)
        .flat();
    }
  },
  watch: {
    salaryPayingOrgs: {
      handler: function (payingOrgIds) {
        store.commit(
          "application/salaryFractionsStore/setOrgIds",
          payingOrgIds
        );
      }
    }
  },
  props: {
    claims: {
      default: () => [],
      type: Array
    },
    immutableSalaryClaimsCollection: {
      required: true
    },
    editableSalaryClaimPayingOrgs: {
      required: true
    },
    enableAddNew:{
      required: true,
      type: Boolean
    }
  },
  methods: {
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.Application, [HelpBarApplicationSectionTypes.Salaries]);
    },
    initForNewItem(){
      this.editedItem = { ...this.defaultItem };

      //any index < 0 is new item, should change on every init to trigger component updates
      if (this.editedIndex < 0) {
        this.editedIndex = this.editedIndex - 1;
      } else {
        this.editedIndex = -1;
      }
    },
    addNewItem(item) {
      this.claims.push(item);
      this.initForNewItem();
    },
    editItem(item) {
      this.editedItem = { ...item.claim };
      this.editedIndex = item.index;
    },
    updateItem(item) {
      Object.assign(this.claims[item.index], item.claim);
      this.initForNewItem();
    },
    editCancelled() {
      this.initForNewItem();
    }
  }
};
</script>