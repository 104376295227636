import Vue from "@/main";
import { toMoney } from "@/utils/numeric";
import store from "@/store";

export const calculateSalaryOrgShare = function (claim) {
    if (claim?.salaryCount && claim?.payingOrgs?.length) {
        return claim.salaryCount / claim.payingOrgs.length;
    }

    return claim?.salaryCount;
};

export const calculateSalaryClaimTotal = function (claim) {
    return claim.salaryCost * claim.salaryCount;
};

export const calculateSalaryOrgAmountFromTotal = function (claim) {
    if (claim?.amount && claim?.payingOrgs?.length) {
        return claim.amount / claim.payingOrgs.length;
    }
    return claim?.amount;
};

export const getSalaryExplanationForOrg = function (claim){
    let calculation = `${toMoney(claim.amount)}`;

    if(claim.payingOrgs.length > 1){
        calculation = `${calculation} / ${claim.payingOrgs.length} ${Vue.$t(
            "summaryPanelStrings.payingOrgs"
        )}`;
    }

    return calculation;
};

export const getSalaryClaimsForOrg = function (orgId, claims, prefix) {
    const orgClaims = claims.salaries.filter(
        x => x?.payingOrgs?.includes(orgId) && !x?.isDeleted
    );

    return orgClaims.map(function (x, index) {
        return {
            id: [prefix, index].join("_"),
            name: Vue.$t(`enums.salaryTypes.${x.claimTypeId}`),
            details: getSalaryExplanationForOrg(x),
            amount: calculateSalaryOrgAmountFromTotal(x)
        };
    });
};


export const getTotalSalaryFractionsCount = function() {
    return store.getters['application/salaryFractionsStore/totalFractionsCount'];
};

export const getSalaryFractionsCountForOrg = function (orgId) {
    return store.getters['application/salaryFractionsStore/fractions'][orgId];
};

export const calculateSalaryFractionCoefficientForOrg = function (orgId) {
    const totalFractionParts = getTotalSalaryFractionsCount();

    if (store.getters['application/salaryFractionsStore/fractions'][orgId]) {
        return store.getters['application/salaryFractionsStore/fractions'][orgId] / totalFractionParts;
    }

    return 0;
};

