import { get, post, patch, put, del } from "@/utils/rest";

export const urls = {
    v1: {
        applications: {
            root: '/Applications',
            post() { return `${this.root}`; },
            export() { return `${this.root}/Export`; },
            exportOrgFeesInvoice() { return `${this.root}/ExportOrgFeesInvoice`; },
            getById(id) { return `${this.root}/${id}`; },
            patch(id) { return `${this.root}/${id}`; },
            orgs(id) { return `${this.root}/${id}/Orgs`; },
            rateConfigurations(id) { return `${this.root}/${id}/RateConfigurations`; },
            attachments(id, attachmentId) { return `${this.root}/${id}/Attachments/${attachmentId}`; },
            persons(id) { return `${this.root}/${id}/Persons`; },
            paymentReceived(id) { return `${this.root}/${id}/PaymentReceived`; },
            setToDraft(id) { return `${this.root}/${id}/SetToDraft`; },
            reply(id) { return `${this.root}/${id}/Reply`; },
            delete(id) { return `${this.root}/${id}`; }
        },
        persons: {
            root: '/Persons',
            applications(id) { return `${this.root}/${id}/Applications`; },
            orgs(id) { return `${this.root}/${id}/Orgs`; },
            getById(id) { return `${this.root}/${id}`; },
            orgsForApplications(id) { return `${this.root}/${id}/Applications/Orgs`; },
            attachments(id, attachmentId) { return `${this.root}/${id}/Attachments/${attachmentId}`; },
            postAttachment(id) { return `${this.root}/${id}/Attachments`; },
            functions(id) { return `${this.root}/${id}/Functions`; },
            personConfigurations(id) { return `${this.root}/${id}/PersonConfigurations`; },
            getMerchantsForBuypassAdministrator(id, buypassId) { return `${this.root}/${id}/BuypassAdministrativeOrgs/${buypassId}`; }
        },
        orgConfigurations: {
            root: '/OrgConfigurations',
            post() { return `${this.root}`; },
            patch(id) { return `${this.root}/${id}`; }
        },
        orgs: {
            root: 'Orgs',
            applicationsWithChildren(id) { return `${this.root}/${id}/WithChildren/Applications`; },
            orgConfigurations(id) { return `${this.root}/${id}/OrgConfigurations`; },
            rateConfigurations(id) { return `${this.root}/${id}/RateConfigurations`; },
            get() { return `${this.root}`; },
            getById(id) { return `${this.root}/${id}`; },
            children(id) { return `${this.root}/${id}/Children`; },
            readonlyChildren(id) { return `${this.root}/${id}/Children/Readonly`; },
            buypassSettlementAccounts(id) { return `${this.root}/${id}/BuypassSettlementAccounts`; },
            orgPersonConfiguration(id, personId) { return `${this.root}/${id}/Persons/${personId}/OrgPersonConfiguration`; },
            orgPersonConfigurations(id) { return `${this.root}/${id}/OrgPersonConfiguration`; },
            orgPersonConfigurationPatch(id) { return `${this.root}/OrgPersonConfiguration/${id}`; },
            exportRemittanceFile(id) { return `${this.root}/${id}/Remittance/Export`; },
            importRemittanceReceipt(id) { return `${this.root}/${id}/Remittance/ImportReceipt`; },
            getTransmissions(id) { return `${this.root}/${id}/Remittance/Transmissions`; },
            resetTransmission(id, transmissionId) { return `${this.root}/${id}/Remittance/Transmissions/${transmissionId}/Reset`; },
            financialDimensions(id) { return `${this.root}/${id}/FinancialDimensions`;}
        },
        rateConfigurations: {
            root: 'RateConfigurations',
            post() { return `${this.root}`; },
            patch(id) { return `${this.root}/${id}`; }
        },
        personConfigurations: {
            root: 'PersonConfigurations',
            post() { return `${this.root}`; },
            patch(id) { return `${this.root}/${id}`; }
        },
        taxRatePeriods: {
            root: 'TaxRatePeriods',
            current() { return `${this.root}/Current`; }
        },
        applicationOrgs: {
            root: 'Applications',
            approve(id, orgId) { return `${this.root}/${id}/Orgs/${orgId}/Approve`; },
            reject(id, orgId) { return `${this.root}/${id}/Orgs/${orgId}/Reject`; },
            pay(id, orgId) { return `${this.root}/${id}/Orgs/${orgId}/Pay`; },
            setPaymentMethod(id, orgId) { return `${this.root}/${id}/Orgs/${orgId}/SetPaymentMethod`; },
            resetPaymentMethod(id, orgId) { return `${this.root}/${id}/Orgs/${orgId}/ResetPaymentMethod`; },
            signPayment(id, orgId) { return `${this.root}/${id}/Orgs/${orgId}/SignPayment`; },
            getRemittanceExportLogRow(id, orgId) { return `${this.root}/${id}/Orgs/${orgId}/Remittance/ExportLogRow`; }
        },
        integrationConfigurations: {
            root: 'IntegrationConfigurations',
            get() { return `${this.root}`; }
        },
        statistics: {
            root: 'Statistics',
            orgCountsByFirstApplicationMonth() { return `${this.root}/OrgCountsByFirstApplicationMonth`; },
            approvalCountsByFunctionalityIdAndMonth() { return `${this.root}/ApprovalCountsByFunctionalityIdAndMonth`; }
        },
        functionTypes: {
            root: 'FunctionTypes',
            get() { return `${this.root}`; }
        },
        infoMessages: {
            root: 'InfoMessages',
            getToday() { return `${this.root}/today`; },
            get() { return `${this.root}`; },
            post() { return `${this.root}`; },
            patch(id) { return `${this.root}/${id}`; },
            delete(id) { return `${this.root}/${id}`; }
        },
        financialDimensions: {
            root: '/FinancialDimensions',
            post() { return `${this.root}`; },
            patch(id) { return `${this.root}/${id}`; },
            delete(id) { return `${this.root}/${id}`; }
        }
    }
};

export const api = {
    v1: {
        applications: {
            async post(body, config = {}) { return await post(urls.v1.applications.post(), body, config); },
            async getById(id, config = {}) { return await get(urls.v1.applications.getById(id), config); },
            async patch(id, body, config = {}) { return await patch(urls.v1.applications.patch(id), body, config); },
            async orgs(id, config = {}) { return await get(urls.v1.applications.orgs(id), config); },
            async rateConfigurations(id, config = {}) { return await get(urls.v1.applications.rateConfigurations(id), config); },
            async attachments(id, attachmentId, config = {}) { return await get(urls.v1.applications.attachments(id, attachmentId), config); },
            async persons(id, config = {}) { return await get(urls.v1.applications.persons(id), config); },
            async paymentReceived(id, body, config = {}) { return await put(urls.v1.applications.paymentReceived(id), body, config); },
            async setToDraft(id, config = {}) { return await put(urls.v1.applications.setToDraft(id), null, config); },
            async reply(id, body, config = {}) { return await post(urls.v1.applications.reply(id), body, config); },
            async export(body, config = {}) { return await post(urls.v1.applications.export(), body, config); },
            async exportOrgFeesInvoice(body, config = {}) { return await post(urls.v1.applications.exportOrgFeesInvoice(), body, config); },
            async delete(id, config = {}) { return await del(urls.v1.applications.delete(id), config); }
        },
        persons: {
            async applications(id, config = {}) { return await get(urls.v1.persons.applications(id), config); },
            async orgs(id, config = {}) { return await get(urls.v1.persons.orgs(id), config); },
            async getById(id, config = {}) { return await get(urls.v1.persons.getById(id), config); },
            async orgsForApplications(id, config = {}) { return await get(urls.v1.persons.orgsForApplications(id), config); },
            async attachments(id, attachmentId, config = {}) { return await get(urls.v1.persons.attachments(id, attachmentId), config); },
            async postAttachment(body, id, config = {}) { return await post(urls.v1.persons.postAttachment(id), body, config); },
            async functions(id, config = {}) { return await get(urls.v1.persons.functions(id), config); },
            async personConfigurations(id, config = {}) { return await get(urls.v1.persons.personConfigurations(id), config); },
            async getMerchantsForBuypassAdministrator(id, buypassId, config = {}) { return await get(urls.v1.persons.getMerchantsForBuypassAdministrator(id, buypassId), config); }
        },
        orgConfigurations: {
            async post(body, config = {}) { return await post(urls.v1.orgConfigurations.post(), body, config); },
            async patch(id, body, config = {}) { return await patch(urls.v1.orgConfigurations.patch(id), body, config); }
        },
        orgs: {
            async applicationsWithChildren(id, config = {}) { return await get(urls.v1.orgs.applicationsWithChildren(id), config); },
            async orgConfigurations(id, config = {}) { return await get(urls.v1.orgs.orgConfigurations(id), config); },
            async rateConfigurations(id, config = {}) { return await get(urls.v1.orgs.rateConfigurations(id), config); },
            async get(config = {}) { return await get(urls.v1.orgs.get(), config); },
            async getById(id, config = {}) { return await get(urls.v1.orgs.getById(id), config); },
            async children(id, config = {}) { return await get(urls.v1.orgs.children(id), config); },
            async readonlyChildren(id, config = {}) { return await get(urls.v1.orgs.readonlyChildren(id), config); },
            async buypassSettlementAccounts(id, config = {}) { return await get(urls.v1.orgs.buypassSettlementAccounts(id), config); },
            async orgPersonConfiguration(id, personId, config = {}) { return await get(urls.v1.orgs.orgPersonConfiguration(id, personId), config); },
            async orgPersonConfigurations(id, config = {}) { return await get(urls.v1.orgs.orgPersonConfigurations(id), config); },
            async orgPersonConfigurationPatch(id, body, config = {}) { return await patch(urls.v1.orgs.orgPersonConfigurationPatch(id), body, config); },
            async exportRemittanceFile(id, body, config = {}) { return await post(urls.v1.orgs.exportRemittanceFile(id), body, config); },
            async importRemittanceReceipt(id, body, config = {}) { return await post(urls.v1.orgs.importRemittanceReceipt(id), body, config); },
            async getTransmissions(id, config = {}) { return await get(urls.v1.orgs.getTransmissions(id), config); },
            async resetTransmission(id, transmissionId, config = {}) { return await post(urls.v1.orgs.resetTransmission(id, transmissionId), null, config); },
            async financialDimensions(id, config = {}) { return await get(urls.v1.orgs.financialDimensions(id), config); }
        },
        rateConfigurations: {
            async post(body, config = {}) { return await post(urls.v1.rateConfigurations.post(), body, config); },
            async patch(id, body, config = {}) { return await patch(urls.v1.rateConfigurations.patch(id), body, config); }
        },
        personConfigurations: {
            async post(body, config = {}) { return await post(urls.v1.personConfigurations.post(), body, config); },
            async patch(id, body, config = {}) { return await patch(urls.v1.personConfigurations.patch(id), body, config); }
        },
        taxRatePeriods: {
            async current(config = {}) { return await get(urls.v1.taxRatePeriods.current(), config); }
        },
        applicationOrgs: {
            async approve(id, orgId, config = {}) { return await post(urls.v1.applicationOrgs.approve(id, orgId), null, config); },
            async reject(id, orgId, config = {}) { return await post(urls.v1.applicationOrgs.reject(id, orgId), null, config); },
            async pay(id, orgId, config = {}) { return await post(urls.v1.applicationOrgs.pay(id, orgId), null, config); },
            async setPaymentMethod(id, orgId, body, config = {}) { return await post(urls.v1.applicationOrgs.setPaymentMethod(id, orgId), body, config); },
            async resetPaymentMethod(id, orgId, config = {}) { return await post(urls.v1.applicationOrgs.resetPaymentMethod(id, orgId), config); },
            async signPayment(id, orgId, config = {}) { return await post(urls.v1.applicationOrgs.signPayment(id, orgId), null, config); },
            async getRemittanceExportLogRow(id, orgId, config = {}) { return await get(urls.v1.applicationOrgs.getRemittanceExportLogRow(id, orgId), config); }
        },
        integrationConfigurations: {
            async get(config = {}) { return await get(urls.v1.integrationConfigurations.get(), config); }
        },
        statistics: {
            async orgCountsByFirstApplicationMonth(config = {}) { return await get(urls.v1.statistics.orgCountsByFirstApplicationMonth(), config); },
            async approvalCountsByFunctionalityIdAndMonth(config = {}) { return await get(urls.v1.statistics.approvalCountsByFunctionalityIdAndMonth(), config); }
        },
        functionTypes: {
            async get(config = {}) { return await get(urls.v1.functionTypes.get(), config); }
        },
        infoMessages: {
            async get(config = {}) { return await get(urls.v1.infoMessages.get(), config); },
            async getToday(config = {}) { return await get(urls.v1.infoMessages.getToday(), config); },
            async post(body, config = {}) { return await post(urls.v1.infoMessages.post(), body, config); },
            async patch(id, body, config = {}) { return await patch(urls.v1.infoMessages.patch(id), body, config); },
            async delete(id, config = {}) { return await del(urls.v1.infoMessages.delete(id), config); }
        },
        financialDimensions: {
            async post(body, config = {}) { return await post(urls.v1.financialDimensions.post(), body, config); },
            async patch(id, body, config = {}) { return await patch(urls.v1.financialDimensions.patch(id), body, config); },
            async delete(id, config = {}) { return await del(urls.v1.financialDimensions.delete(id), config); }
        }
    }
};