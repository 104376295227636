<template>
  <div>
    <template v-if="hasAvailableOrgs">
      <div class="d-print-none">
        <breadcrumb-block>
          <breadcrumb-router-link to="/orgapplications">
            {{ $t("commonStrings.toOverview") }}
          </breadcrumb-router-link>
        </breadcrumb-block>
      </div>
      <v-row>
        <v-col>
          <orgs-handling-panel
            @application-approved="approve"
            @application-rejected="reject"
            @application-payout-requested="payout"
            @payment-method-selected="setPaymentMethod"
            @payment-signed="signPayment"
            @payment-reset="resetPaymentMethod"
            :application="application"
            :availableOrgs="availableOrgs"
            :is-typing-response="isTypingResponse"
            :created-by-current-user="createdByCurrentUser"
          >
          </orgs-handling-panel>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col :cols="12" :lg="6" :order-lg="2">
        <person-information
          :initializing="initializing"
          :initApplicationId="application.id"
          :title="$t('previewApplicationPageStrings.personInformationHeader')"
        ></person-information>
      </v-col>
      <v-col :cols="12" :lg="6" :order-lg="1">
        <general-details-panel
          :initializing="initializing"
          :application="application"
        ></general-details-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :lg="6">
        <application-status-panel
          :orgs="application.orgs"
          class="fill-height"
        ></application-status-panel>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="application.id">
          <salaries-panel
            :claims="application.claims.salaries"
          ></salaries-panel>

          <trips-panel :claims="application.claims.trips"></trips-panel>

          <diet-panel :diet="application.claims.diet"></diet-panel>

          <receipts-panel
            :claims="application.claims.receipts"
            :application-id="application.id"
            :person-id="application.personId"
          ></receipts-panel>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <summary-panel
          :claims="application.claims"
          :orgs="payingOrgs"
        ></summary-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <common-handling-panel
          @draft="draft"
          :created-by-current-user="createdByCurrentUser"
          :to-draft-available="toDraftAvailable"
          :isSettingToDraft="isSettingToDraft"
          :is-typing-response="isTypingResponse"
        >
        </common-handling-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <communication-panel
          :log="application.logEvents"
          :comments="application.comments"
          :orgs="payingOrgs"
          :application-person-id="application.personId"
          :current-user="user"
          :current-org-id="currentOrgId"
          @reply="appendReply"
          :application-id="application.id"
          :can-write-as-org="hasAvailableOrgs"
          :is-draft="application.isDraft"
          :is-typing-response="isTypingResponse"
          :initializing="initializing"
          :source="application.databaseSource"
          @comment-changed="setIsTypingResponse($event)"
        ></communication-panel>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import SummaryPanel from "@/views/applications/components/summary/SummaryPanel";
import PersonInformation from "@/views/applications/components/PersonInformation";
import SalariesPanel from "./components/SalariesPanel";
import TripsPanel from "./components/TripsPanel";
import DietPanel from "./components/DietPanel";
import ReceiptsPanel from "./components/ReceiptsPanel";
import ApplicationStatusPanel from "./components/ApplicationStatusPanel";
import GeneralDetailsPanel from "./components/GeneralDetailsPanel";
import OrgApplicationStatusTypes from "@/enums/OrgApplicationStatusTypes";
import application from "@/mixins/application";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import CommunicationPanel from "@/views/applications/components/communication/CommunicationPanel";
import CommonHandlingPanel from "./components/CommonHandlingPanel";
import OrgsHandlingPanel from "./components/OrgsHandlingPanel";
import { api } from "@/utils/api";
import { getOrgDisplayName } from "@/utils/org";
import BreadcrumbBlock from "@/views/common/components/BreadcrumbBlock";
import BreadcrumbRouterLink from "@/views/common/components/BreadcrumbRouterLink";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "PreviewApplication",
  components: {
    SummaryPanel,
    PersonInformation,
    SalariesPanel,
    TripsPanel,
    DietPanel,
    ReceiptsPanel,
    ApplicationStatusPanel,
    GeneralDetailsPanel,
    CommunicationPanel,
    CommonHandlingPanel,
    OrgsHandlingPanel,
    BreadcrumbBlock,
    BreadcrumbRouterLink

  },
  mixins: [application],
  data() {
    return {
      availableOrgIds: []
    };
  },
  computed: {
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs",
      currentOrgId: "contextStore/orgId",
      contextInitiated: "contextStore/initiated",
      user: "oidcStore/oidcUser"
    }),
    toDraftAvailable() {
      if (
        !(this.application?.orgs) ||
        !Object.keys(this.application.orgs).length
      ) {
        return false;
      }

      return (
        !this.application.isDraft &&
        _.every(
          Object.values(this.application.orgs),
          x => x.orgApplicationStatus === OrgApplicationStatusTypes.Pending
        )
      );
    },
    createdByCurrentUser() {
      if (this.application?.personId && this.user?.personid) {
        const currentUser = Number(this.user.personid);
        const applicationUser = Number(this.application.personId);

        return currentUser === applicationUser;
      }

      return false;
    },
    currentApplicationOrg() {
      if (this.currentOrgId && this.application.orgs && this.application.orgs[this.currentOrgId]) {
        return this.application.orgs[this.currentOrgId];
      }

      return null;
    },
    availableOrgs() {
      return this.availableOrgIds
        .filter(x => x in this.application.orgs)
        .map(x => ({
          id: x,
          name: getOrgDisplayName(this.payingOrgs.find(y => y.id === x))
        }));
    },
    hasAvailableOrgs() {
      return this.availableOrgs && this.availableOrgs.length > 0;
    }
  },
  methods: {
    ...mapActions({
      fetchTaxRates: "currentTaxRatePeriodStore/fetch"
    }),
    async approve(id) {
      await this.applicationAction("approve", id);
    },
    async reject(id) {
      await this.applicationAction("reject", id);
    },
    async payout(params) {
      await this.applicationAction("pay", params.id, params.method);
    },
    async setPaymentMethod(params) {
      await this.setPaymentMethodAction(params.orgId, params.methodId, params.setAsPreferredMethod);
    },
    async resetPaymentMethod(orgId) {
      await this.resetPaymentMethodAction(orgId);
    },
    async signPayment(id) {
      await this.applicationAction("signPayment", id);
    },
    async draft() {
      await this.setToDraft();
    },
    async fetchChildOrgs() {
      if (!this.currentOrgId) {
         return;
      }

      try {
        let result = [this.currentOrgId];
        const response = await api.v1.orgs.children(this.currentOrgId);
        if (response.status === 200) {
          let childOrgs;

          if (response.data) {
            childOrgs = response.data;
          } else {
            childOrgs = [];
          }

          result = result.concat(childOrgs.map(x => x.id));
        }
        this.availableOrgIds = result;
      } catch (error) {
        await handleError(error);
      }
    },
    async onMountedOrContextInitiated() {
      await this.fetch();

      if (this.availableOrgs && !this.availableOrgs.length && this.currentOrgId) {
        await this.fetchChildOrgs();
      }
      this.initializing = false;
    }
  },
  async beforeMount() {
    try {
      await this.fetchTaxRates();
    } catch (error) {
      await handleError(error);
    }
  },
  //If we have context we can fetch directly, but skip if we don't and let the contextInitiated watch handle it
  async mounted() {
    if (this.contextInitiated) {
      await this.onMountedOrContextInitiated();
    }
  },
  watch: {
    //If no context present, mounted is not run and we init when we have the context
    contextInitiated: {
      handler: async function (val) {
        if (val) {
          await this.onMountedOrContextInitiated();
        }
      }
    }
  }
};
</script>
