<template>
    <v-data-table
      dense
      hide-default-footer
      :headers="headers"
      :items="filteredClaims"
      :items-per-page="-1"
      :mobile-breakpoint="960"
    >
      <template v-slot:item.date="{item}">
        {{ item.date | moment($t('commonStrings.dateDisplayFormat'))}}
      </template>
      <template v-slot:item.claimTypeId="{item}">
        {{ $t(`enums.salaryTypes.${item.claimTypeId}`)}}
      </template>
      <template v-slot:item.payingOrgs="{item}">
        <paying-orgs-label :paying-orgs="item.payingOrgs"></paying-orgs-label>
      </template>
      <template v-slot:item.salaryCost="{item}">
        <amount-label :value="item.salaryCost"></amount-label>
      </template>
      <template v-slot:item.amount="{item}">
        <amount-label :value="item.amount"></amount-label>
      </template>
      <template v-slot:item.editAction="{ item }">
        <v-btn
          rounded
          class="primary-inverted-button my-2"
          small
          @click="onEdit(item)"
        >{{ $t("salaryPanelStrings.labels.editButton") }}</v-btn>
      </template>
      <template v-slot:item.removeAction="{ item }">
        <v-btn
          rounded
          small
          class="accent-inverted-button my-2"
          @click="onRemove(item)"
        >{{ $t("salaryPanelStrings.labels.removeButton") }}</v-btn>
      </template>
    </v-data-table>
</template>
<script>
import AmountLabel from "@/views/common/components/AmountLabel";
import PayingOrgsLabel from "@/views/applications/components/PayingOrgsLabel";
import { mapGetters } from "vuex";

export default {
  name: "SalaryTable",
  components: {
    PayingOrgsLabel,
    AmountLabel
  },
  data: function () {
    return {
      headers: [
        {
          text: this.$t("salaryPanelStrings.tableHeaders.date"),
          value: "date",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("salaryPanelStrings.tableHeaders.salaryType"),
          value: "claimTypeId",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("salaryPanelStrings.tableHeaders.payingOrgs"),
          value: "payingOrgs",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("salaryPanelStrings.tableHeaders.count"),
          value: "salaryCount",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("salaryPanelStrings.tableHeaders.salaryCost"),
          value: "salaryCost",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("salaryPanelStrings.tableHeaders.amount"),
          value: "amount",
          sortable: false,
          filterable: false
        },
        {
          text: this.$t("salaryPanelStrings.tableHeaders.comment"),
          value: "comment",
          sortable: false,
          filterable: false
        },
        {
          value: "editAction",
          sortable: false,
          filterable: false
        },
        {
          value: "removeAction",
          sortable: false,
          filterable: false
        }
      ]
    };
  },
  props: {
    claims: {
      default: () => [],
      type: Array
    },
    editableSalaryClaimPayingOrgs: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentApplication: "application/currentApplicationStore/application"
    }),
    filteredClaims() {
      // all claims
      const availableClaims = this.claims.filter(x => !x.isDeleted);
      const readOnlyClaims = availableClaims.filter(x => x.readOnly);
      const editableClaims = availableClaims.filter(x => !x.readOnly);

      // only sort on readonly claims,
      // as otherwise there is a lot of
      // unintentional ui updates/line jumps
      return readOnlyClaims
        .sort((a, b) => this.$moment(a.date).diff(b.date))
        .concat(editableClaims);
    },
    claimsWithSalaryFraction() {
      return (
        this.currentApplication.claims.trips?.some(
          x => x.usesSalaryFraction
        ) ||
        this.currentApplication.claims.receipts?.some(
          x => x.usesSalaryFraction
        ) ||
        this.currentApplication.claims.diet?.usesSalaryFraction
      );
    }
  },
  methods: {
    onEdit(claim) {
      const index = this.claims.indexOf(claim);
      this.$emit('edit-clicked', {claim, index} );
    },
    onRemove(claim) {
      if (this.claimsWithSalaryFraction) {
        this.$snacks.add(this.$t("salaryTableStrings.removingSalaryDenial"),
          "error"
        );
        return;
      }

      this.$set(claim, "isDeleted", true);
    }
  }
};
</script>