<template>
  <span>
    <span v-for="(orgId, index) in payingOrgs" :key="index">
      {{ getSeparatorIfRequired(index) }}
      <span :class="{'font-weight-bold': isCurrentOrg(orgId)}">{{ getName(orgId) }}</span>
    </span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PayingOrgsLabel",
    props: {
    payingOrgs: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      payingOrgNames: "application/payingOrgsStore/nameLookup",
      currentOrgId: "contextStore/orgId"
    })
  },
  methods: {
    getName(orgId) {
      return this.payingOrgNames[orgId];
    },
    getSeparatorIfRequired(index) {
      if (index > 0) {
        return ", ";
      }

      return "";
    },
    isCurrentOrg(orgId) {
        return orgId === this.currentOrgId;
    }
  }
};
</script>