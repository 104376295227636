export const toMoney = function toMoney(amount) {
    return new Intl.NumberFormat("nb-NO", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
};

export const toNumeric = function toNumeric(amount) {
    return new Intl.NumberFormat("nb-NO", {
        style: "decimal",
        maximumFractionDigits: 2
    }).format(amount);
};


export const toPercent = function toPercent(amount) {
    return new Intl.NumberFormat("nb-NO", {
        style: "percent",
        maximumFractionDigits: 2
    }).format(amount);
};

export const replaceZeroAndNullValues = function replaceZeroAndNullValues(value, defaultValue) {
    if (value && value !== 0) {
        return value;
    }

    return defaultValue;
};

