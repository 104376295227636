<template>
  <div>
    <validation-provider
      rules="required"
      v-slot="{ errors }"
      :name="validationNameOrDefault"
      v-if="!claim.usesSalaryFraction"
    >
      <v-select
        multiple
        item-value="id"
        item-text="name"
        chips
        small-chips
        deletable-chips
        :disabled="isDisabled"
        :items="availablePayingOrgs(claim)"
        :label="label"
        v-model="claim.payingOrgs"
        :error-messages="errors"
        name="payingOrgs"
        outlined
        dense
        :placeholder="placeholderTextOrDefault"
        @change="$emit('paying-orgs-change')"
      >
        <template v-slot:append>
          <v-icon tag="span">mdi-menu-down</v-icon>
        </template>
      </v-select>
    </validation-provider>
    <v-tooltip top v-else color="black">
      <template v-slot:activator="{ on }">
        <v-chip v-on="on">
          {{ $t("payingOrgsPickerStrings.splitBySalaryFraction") }}
          <v-icon tag="span" class="ml-2">info</v-icon>
        </v-chip>
      </template>
      <ul>
        <li
          v-for="(parts, orgId) in salaryFractions"
          :key="orgId"
          class="mb-1"
        >{{ $tc("payingOrgsPickerStrings.salaryFractionExplanation", parts, { org: getOrgNameByOrgId(orgId), parts, total: totalFractionsCount }) }}</li>
      </ul>
    </v-tooltip>
  </div>
</template>

<script>
import { filterAvailablePayingOrgs } from "@/utils/claim";
import { ValidationProvider } from "vee-validate";
import { getOrgDisplayName } from "@/utils/org";
import { mapGetters } from "vuex";

export default {
  name: "PayingOrgsPicker",
  props: {
    claim: {
      type: Object,
      required: true
    },
    configurationTypeProperty: {
      type: String,
      required: true
    },
    filterEnabledTypesFunc: {
      type: Function,
      required: true
    },
    claimTypeProperty: {
      type: String,
      required: true
    },
    validationName: {
      type: String
    },
    placeholderText: {
      type: String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean
    }
  },
  components: {
    ValidationProvider
  },
  watch: {
    "claim.usesSalaryFraction"(val) {
      if (val) {
        this.$set(this.claim, "payingOrgs", this.salaryFractionOrgIds);
      } else {
        this.$set(this.claim, "payingOrgs", []);
      }
      this.$emit('paying-orgs-change', this.claim.payingOrgs);
    },
    salaryFractionOrgIds(val) {
      if (this.claim.usesSalaryFraction) {
        if (val) {
          this.$set(this.claim, "payingOrgs", val);
        } else {
          this.$set(this.claim, "payingOrgs", []);
        }
        this.$emit('paying-orgs-change', this.claim.payingOrgs);
      }
    }
  },
  computed: {
    ...mapGetters({
      salaryFractions: "application/salaryFractionsStore/fractions",
      currentApplicationOrgs: "application/currentApplicationStore/orgs",
      totalFractionsCount:
        "application/salaryFractionsStore/totalFractionsCount",
      salaryFractionOrgIds: "application/salaryFractionsStore/uniqueOrgIds"
    }),
    isDisabled() {
      if (this.disabled) {
        return true;
      }

      if (this.claim.usesSalaryFraction) {
        return true;
      }

      return !this.claim[this.claimTypeProperty];
    },
    placeholderTextOrDefault() {
      if (this.placeholderText) {
        return this.placeholderText;
      }

      return this.$t("claimPickersStrings.placeholders.payingOrgs");
    },
    validationNameOrDefault() {
      if (this.validationName) {
        return this.validationName;
      }

      return this.$t("claimPickersStrings.tableHeaders.payingOrgs");
    }
  },
  methods: {
    getOrgNameByOrgId(orgId) {
      orgId = parseInt(orgId, 10);

      const org = this.currentApplicationOrgs.find(x => x.id === orgId);

      if (org != null) {
        return getOrgDisplayName(org);
      }

      return "";
    },
    availablePayingOrgs(claim) {
      return filterAvailablePayingOrgs(
        claim,
        this.configurationTypeProperty,
        this.filterEnabledTypesFunc,
        this.claimTypeProperty
      ).map(x => ({ id: x.id, name: getOrgDisplayName(x) }));
    }
  }
};
</script>