<template>
  <v-card>
    <v-card-title class="py-0"
      ><h1 class="text-h2 mt-10 mb-7">
        {{ $t("remittancesStrings.exportPanel.title") }}
      </h1></v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-select
            :label="$t('remittancesStrings.exportPanel.labels.receiver')"
            multiple
            item-value="id"
            item-text="text"
            chips
            small-chips
            deletable-chips
            :items="receivingOrgFilterOptions"
            v-model="filteredOrgIds"
            dense
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            rounded
            small
            class="primary-inverted-button"
            @click="clearFilters"
            :block="$vuetify.breakpoint.xsOnly"
            >{{
              $t("remittancesStrings.exportPanel.labels.clearFiltersButton")
            }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <text-field-date-picker
            v-model="paymentDate"
            :label="$t('remittancesStrings.exportPanel.labels.paymentDate')"
            :allowed-dates="x => $moment(x).isSameOrAfter($moment(), 'days')"
          ></text-field-date-picker>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-title class="py-0"
      ><h2 class="text-h2 mt-10 mb-7">
        {{
          $t("remittancesStrings.exportPanel.resultsSubheader", [filteredCount])
        }}
      </h2></v-card-title
    >
    <v-card-text>
      <v-data-table
        v-model="selectedObjectsForExport"
        item-key="orgAndApplicationId"
        show-select
        :headers="headers"
        :items="filteredApplications"
        :no-data-text="this.$t('remittancesStrings.exportPanel.noDataText')"
        :no-results-text="
          this.$t('remittancesStrings.exportPanel.noResultsText')
        "
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-btn
              :loading="isExporting"
              :disabled="exportButtonDisabled || isExporting"
              rounded
              small
              @click="exportRemittances"
              class="primary-inverted-button"
              >{{
                $t("remittancesStrings.exportPanel.labels.exportButton")
              }}</v-btn
            >
          </v-toolbar>
        </template>
        <template
          v-slot:item.data-table-select="{ isSelected, select, isMobile }"
        >
          <v-checkbox
            color="primary"
            :value="isSelected"
            :label="
              isMobile
                ? $t('remittancesStrings.exportPanel.labels.exportCheckbox')
                : ''
            "
            @change="select($event)"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { api, urls } from "@/utils/api";
import { getOrgDisplayName } from "@/utils/org";
import PaymentMethods from "@/enums/PaymentMethods";
import TextFieldDatePicker from "@/views/common/components/TextFieldDatePicker";
import _ from "lodash";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "RemittanceExportPanel",
  components: {
    TextFieldDatePicker
  },
  data() {
    return {
      paymentDate: this.$moment().format(
        this.$t("commonStrings.dateValueFormat")
      ),
      filteredOrgIds: [],
      applications: [],
      selectedObjectsForExport: [],
      currentOrgWithChildren: [],
      orgNames: {},
      headers: [
        {
          text: this.$t(
            "remittancesStrings.exportPanel.headers.applicationDescription"
          ),
          value: "name"
        },
        {
          text: this.$t("remittancesStrings.exportPanel.headers.sender"),
          value: "senderName"
        },
        {
          text: this.$t("remittancesStrings.exportPanel.headers.receiver"),
          value: "recipientName"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      stateInitiated: "contextStore/initiated",
      currentOrgId: "contextStore/orgId",
      currentOrgs: "currentOrgsStore/orgs",
      urlState: "utilsRestStore/urlState",
      orgsWithPermissions: "currentOrgsStore/orgs"
    }),
    currentOrg() {
      return this.orgsWithPermissions.find(x => x.org.id === this.currentOrgId);
    },
    isExporting() {
      if (this.selectedObjectsForExport?.length) {
        return this.urlState(
          urls.v1.orgs.exportRemittanceFile(this.currentOrgId)
        );
      }

      return false;
    },
    exportButtonDisabled() {
      return !this.selectedObjectsForExport.length;
    },
    filteredCount() {
      if (this.filteredApplications) {
        return this.filteredApplications.length;
      } else {
        return 0;
      }
    },
    loading() {
      return this.urlState(
        urls.v1.orgs.applicationsWithChildren(this.currentOrgId)
      );
    },
    currentOrgName() {
      let org;

      if (this.currentOrgId && this.currentOrgs) {
        org = this.currentOrgs.find(x => x.org.id === this.currentOrgId);
      } else {
        org = null;
      }

      if (org) {
        return getOrgDisplayName(org.org);
      } else {
        return "";
      }
    },
    receivingOrgFilterOptions() {
      return _.uniqBy(
        this.applications.map(x => ({
          text: x.recipientName,
          id: x.orgId
        })),
        x => x.id
      );
    },
    filteredApplications() {
      return this.applications.filter(app =>
        this.filteredOrgIds.find(id => app.orgId === id)
      );
    },
    selectedTransactions() {
      return this.selectedObjectsForExport.map(app => ({
        applicationId: app.id,
        orgId: app.orgId
      }));
    }
  },
  methods: {
    async exportRemittances() {
      try {
        const response = await api.v1.orgs.exportRemittanceFile(
          this.currentOrgId,
          {
            paymentDate: this.paymentDate,
            transactions: this.selectedTransactions
          },
          { responseType: "blob" }
        );

        if (response.status === 200) {
          const disposition = response.headers["content-disposition"];
          const name = disposition.split("filename*=UTF-8''")[1];
          const decoded = decodeURIComponent(name);
          const url = window.URL.createObjectURL(response.data);
          //insert temporary link in DOM and trigger click to download file
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", decoded);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        }

        if (response.status === 204) {
          this.$snacks.add(this.$t("remittancesStrings.exportPanel.notFoundWarning"),
            "warning"
          );
        }
      } catch (error) {
        await handleError(error);
      }

      //reload after export
      await this.fetch();
      this.clearFilters();
      this.clearSelection();
    },
    async fetch() {
      try {
        const response = await api.v1.orgs.applicationsWithChildren(
          this.currentOrgId
        );

        if (response.status === 200) {
          let applications;

          if (response.data) {
            applications = response.data;
          } else {
            applications = [];
          }

          this.applications = this.mapApplications(applications);
        }
      } catch (error) {
        await handleError(error);
      }
    },
    async fetchChildOrgs() {
      try {
        const response = await api.v1.orgs.readonlyChildren(this.currentOrgId);

        if (response.status === 200) {
          let orgs;

          if (response.data) {
            orgs = response.data;
          } else {
            orgs = [];
          }

          orgs.forEach(org => {
            this.orgNames[org.id] = getOrgDisplayName(org);
          });

          this.orgNames[this.currentOrgId] = this.currentOrgName;

          this.currentOrgWithChildren = [
            this.currentOrgId,
            ...orgs.map(x => x.id)
          ];
        }
      } catch (error) {
        await handleError(error);
      }
    },
    getSenderName(person) {
      if (person) {
        return `${person.lastName}, ${person.firstName}`;
      } else {
        return "";
      }
    },
    clearFilters() {
      this.filteredOrgIds = [...this.receivingOrgFilterOptions.map(x => x.id)];
    },
    clearSelection() {
      this.selectedObjectsForExport = [];
    },
    mapApplications(applications) {
      const perApplicationOrg = [];
      applications.forEach(data => {
        this.currentOrgWithChildren.forEach(org => {
          const applicationOrg = data.application.orgs[org];
          if (
            applicationOrg?.approval &&
            !applicationOrg?.paymentSent &&
            applicationOrg?.paymentMethod === PaymentMethods.Remittance
          ) {
            perApplicationOrg.push({
              orgId: org,
              data: data
            });
          }
        });
      });
      return perApplicationOrg.map(applicationOrgData => ({
        orgId: applicationOrgData.orgId,
        id: applicationOrgData.data.application.id,
        name: applicationOrgData.data.application.applicationName,
        senderName: this.getSenderName(
          applicationOrgData.data.senderInformation
        ),
        recipientName: this.orgNames[applicationOrgData.orgId],
        orgAndApplicationId: `${applicationOrgData.orgId}${applicationOrgData.data.application.id}`
      }));
    }
  },
  watch: {
    stateInitiated: {
      handler: async function (val) {
        if (val) {
          await this.fetchChildOrgs();
          await this.fetch();
          this.clearFilters();
        }
      },
      immediate: true
    }
  }
};
</script>