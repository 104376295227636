<template>
<span>
    <div v-if="label">{{label}}</div>
    <span>{{(value || 0) | toMoney}}</span>
</span>
</template>

<script>
import { toMoney } from "@/utils/numeric";

export default {
  name: "AmountLabel",
  props: {
    value: {
      validator: prop => typeof prop === "number" || typeof prop === "string" || prop === null || prop === undefined,
      required: true
    },
    label: {
      type: String
    }
  },
  filters: {
    toMoney
  }
};
</script>