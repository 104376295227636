const getFeatureFlagConfig = function () {
  if (process.env.VUE_APP_FEATURE_FLAGS && !process.env.VUE_APP_FEATURE_FLAGS.startsWith("#")) {
    return JSON.parse(process.env.VUE_APP_FEATURE_FLAGS);
  }

  return {};
};

export const remittanceOrgIds = function remittanceOrgIds() {
  const featureFlagConfig = getFeatureFlagConfig();

  if (featureFlagConfig?.remittanceOrgIds) {
    return featureFlagConfig.remittanceOrgIds;
  }

  return [];
};