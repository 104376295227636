import PaymentMethods from "@/enums/PaymentMethods";

const previewApplicationPageStrings = {
  name: "@:commonStrings.applicationDetails",
  pageHeader: "@:previewApplicationPageStrings.name",
  personInformationHeader: "@:commonStrings.sender",
  emptyValue: "-",
  buypassInfoText: "Ved utbetaling via Buypass håndteres ikke forskuddstrekk per mottaker eller arbeidsgiveravgift. Er dette aktuelt, bør kravet håndteres manuelt.",
  journalNotAvailable: "Bilag blir tilgjengelig når alle mottakere har godkjent kravet.",
  labels: {
    toDraftButton: "Angre innsending",
    editButton: "@:commonStrings.edit",
    toJournal: "Til bilag for {0}",
    closeButton: "@:commonStrings.close",
    setAsPreferredMethod: "Lagre valgt utbetalingsmåte"
  },
  general: {
    title: "Informasjon om kravet",
    applicationNameLabel: "Kravet gjelder",
    createdDateLabel: "Dato",
    accountNumberLabel: "Kontonummer",
    idLabel: "ID"
  },
  noPassengers: "Ingen @.lower:commonStrings.passengers",
  approvedByOrg: "Godkjent av org ({0})",
  rejectedByOrg: "Avvist av org ({0})",
  nextAction: "Neste handling",
  noMoreActions: "Behandling fullført",
  linkToRequiredPermissions: "Hvem kan gjøre dette?",
  handlingCompleted: "Kravet er ferdig behandlet av {0}",
  handling: "Behandling og bilag",
  allOrgsHaveApproved: "Alle mottakere har godkjent kravet.",
  allOrgsHaveNotApproved: "Alle mottakere må godkjenne kravet før det kan utbetales. Foreløpig har {0} av {1} mottakere godkjent.",
  resetPaymentButton: "Endre utbetalingsmåte",
  approvalPanel: {
    nextActionApproval: "@:previewApplicationPageStrings.nextAction: Godkjenning",
    youCanApprove: "Du har rettigheter til å godkjenne for {0} med din aktive rolle.",
    youCannotApprove: "Du har ikke rettigheter til å godkjenne for {0} med din aktive rolle.",
    approveButton: "Godkjenn",
    rejectButton: "Avvis og returner",
    permissionsModalTitle: "Funksjonstyper som kan godkjenne krav"
  },
  confirmRejectApplicationDialog: {
    title: "Avvise kravet?",
    visibilityInfoText: `Etter at kravet er returnert vil det ikke lenger være synlig, fram til avsender eventuelt sender det inn på nytt.`,
    commentInfoText: "Om du ønsker å legge til en kommentar må dette gjøres før avvisning.",
    confirmationInfoText: "Er du sikker på at du vil avvise kravet og returnere det til avsender?",
    labels: {
      cancelButton: "@:commonStrings.cancel",
      confirmButton: "OK, avvis kravet"
    }
  },
  paymentMethodSelectionPanel: {
    nextActionPaymentMethodSelection: "@:previewApplicationPageStrings.nextAction: Valg av utbetalingsmåte",
    youCanSelectPaymentMethod: "Du har rettigheter til å velge utbetalingsmåte for {0} med din aktive rolle.",
    youCannotSelectPaymentMethod: "Du har ikke rettigheter til å velge utbetalingsmåte for {0} med din aktive rolle.",
    permissionsModalTitle: "Funksjonstyper som kan velge utbetalingsmåte",
    permissionsModalEndText: "Når mottaker er gruppe for særidrett/allidrett kan også personer som har aktuell funksjon i overliggende klubb velge utbetalingsmåte.",
    paymentMethodButtonText: {
      [PaymentMethods.Manual]: "Manuell utbetaling",
      [PaymentMethods.Buypass]: "Utbetaling fra Buypass",
      [PaymentMethods.Remittance]: "Utbetaling med remitteringsfil"
    },
    paymentMethodInfoText: {
      [PaymentMethods.Manual]: "Velg Manuell utbetaling dersom kravet skal viderebehandles i lønns- eller regnskapssystem",
      [PaymentMethods.Buypass]: "Velg Utbetaling fra Buypass for å utbetale fra Buypass-konto",
      //Placeholder. Needs a text that actually says something.
      [PaymentMethods.Remittance]: "Velg Utbetaling med remitteringsfil for å utbetale med remitteringsfil"
    }
  },
  paymentSignaturePanel: {
    nextActionPaymentSignature: "@:previewApplicationPageStrings.nextAction: Signering av utbetaling",
    youHaveAlreadySignedPayment: "Du har allerede signert denne utbetalingen for {0}.",
    youCanSignPayment: "Du har rettigheter til å signere utbetaling for {0} med din aktive rolle.",
    youCannotSignPayment: "Du har ikke rettigheter til å signere utbetaling for {0} med din aktive rolle.",
    remainingSignaturesRequired: "{0} av {1} signaturer gjenstår.",
    signButton: "Signer for utbetaling",
    permissionsModalTitle: "Funksjonstyper som kan signere for utbetaling",
    permissionsModalEndText: "Når mottaker er gruppe for særidrett/allidrett må den som skal signere ha aktuell funksjon i overliggende klubb."
  },
  payoutPanel: {
    nextActionPayment: "@:previewApplicationPageStrings.nextAction: Utbetaling",
    youCanPayout: "Du har rettigheter til å merke som utbetalt for {0} med din aktive rolle.",
    youCannotPayout: "Du har ikke rettigheter til å merke som utbetalt for {0} med din aktive rolle.",
    youCanPayoutBuypass: "Du har rettigheter til å utbetale fra Buypass for {0} med din aktive rolle.",
    youCannotPayoutBuypass: "Du har ikke rettigheter til å utbetale fra Buypass for {0} med din aktive rolle.",
    manualPayoutButton: "Merk som utbetalt",
    buypassPayoutButton: "Utbetal fra Buypass",
    remittancePayoutButton: "Gå til remittering",
    manualPermissionsModalTitle: "Funksjonstyper som kan merke som utbetalt",
    manualPermissionsModalEndText: "Når mottaker er gruppe for særidrett/allidrett kan også personer som har aktuell funksjon i overliggende klubb merke som utbetalt.",
    buypassPermissionsModalTitle: "Funksjonstyper som kan utbetale fra Buypass",
    buypassPermissionsModalEndText: "Når mottaker er gruppe for særidrett/allidrett må den som skal utbetale fra Buypass ha aktuell funksjon i overliggende klubb.",
    buypassPermissionsModalStartText: `Personer som skal utbetale fra Buypass må være registrert som Administrator i Buypass Payment Manager,
     i tillegg til å ha en av funksjonene under:`,
    chosenPaymentMethod: "Valgt betalingsmetode:"
  }
};

export default previewApplicationPageStrings;