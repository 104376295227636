<template>
  <v-menu
    v-model="timeOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{}">
      <validation-provider rules="time" :name="label" v-slot="{ errors }">
        <v-text-field
          :error-messages="errors"
          v-mask="'##:##'"
          v-model="textTime"
          :label="label"
          prepend-inner-icon="access_time"
          dense
          outlined
          @click:prepend-inner="timeOpen = true"
          @blur="updateFromText"
          :disabled="!value"
        ></v-text-field>
      </validation-provider>
    </template>
    <v-time-picker
      v-if="timeOpen"
      format="24hr"
      v-model="internalValue"
      full-width
      @input="inputFunc"
      @click:minute="updateMinute"
      :disabled="!value"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
    ></v-time-picker>
  </v-menu>
</template>
<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  name: "TextFieldTimePicker",
  data: function () {
    return {
      internalHour: null,
      internalMinute: null,
      internalValue: null,
      timeOpen: false,
      textTime: ""
    };
  },
  methods: {
    updateFromText() {
      if (!this.textTime) {
        this.$emit("input", "");
        return;
      }

      this.setHourAndMinute(this.textTime);

      const val = this.$moment(
        this.momentValue,
        this.$t("commonStrings.dateValueFormat")
      );

      if (!val.isValid()) {
        return;
      }

      this.$emit("input", this.momentValue);
    },
    updateMinute: function () {
      this.timeOpen = false;
    },
    inputFunc: function (e) {
      if (e) {
        this.setHourAndMinute(e);
      }
      const val = this.$moment(
        this.momentValue,
        this.$t("commonStrings.dateValueFormat")
      );

      if (!val.isValid()) {
        return;
      }
      const textVal = val.format(this.$t("commonStrings.timeDisplayFormat"));
      this.textTime = textVal;
      this.$emit("input", this.momentValue);
    },
    setHourAndMinute(value) {
      const values = value.split(":");
      this.internalHour = parseInt(values[0], 10);
      this.internalMinute = parseInt(values[1], 10);
    }
  },
  props: {
    value: String,
    label: String,
    allowedHours: Function,
    allowedMinutes: Function
  },
  computed: {
    momentValue: function () {
      const m = this.$moment(
        this.value,
        this.$t("commonStrings.dateValueFormat")
      );
      m.hour(this.internalHour);
      m.minute(this.internalMinute);

      return m.format(this.$t("commonStrings.dateValueFormat"));
    }
  },
  mounted: function () {
    if (this.value) {
      this.internalValue = this.$moment(
        this.value,
        this.$t("commonStrings.dateValueFormat")
      ).format(this.$t("commonStrings.timeDisplayFormat"));
      this.setHourAndMinute(this.internalValue);
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.internalValue = this.$moment(
          value,
          this.$t("commonStrings.dateValueFormat")
        ).format(this.$t("commonStrings.timeDisplayFormat"));
        this.setHourAndMinute(this.internalValue);
        this.$emit("input", this.momentValue);
      } else {
        this.internalValue = null;
        this.internalHour = null;
        this.internalMinute = null;
      }
    }
  }
};
</script>