<template>
  <v-timeline-item :color="itemColor(event.logEventType)" fill-dot elevation="1">
    <template v-slot:icon>
      <v-icon tag="span" :class="iconClass(event.logEventType)">{{icon(event.logEventType)}}</v-icon>
    </template>
    <p class="text-h5">{{$t(`enums.logEventTypeStrings.${event.logEventType}`, names(event))}}</p>
    <p>{{event.date | moment(`${$t("communicationPanelStrings.eventDateTimeDisplayFormat")}`)}}
    <span v-if="eventAuthor(event)"><br/>{{$t("communicationPanelStrings.labels.eventAuthor", [eventAuthor(event)])}}</span>
    </p>
  </v-timeline-item>
</template>


<script>
import LogEventTypes from "@/enums/LogEventTypes";
import { mapGetters } from "vuex";
import { getOrgName, getPersonName } from "@/utils/currentApplication";

export default {
  name: "LogEvent",
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs"
    })
  },
  methods: {
    iconClass(eventType) {
      switch (eventType) {
        case LogEventTypes.ApplicationApprovedForPayment:
        case LogEventTypes.ApplicationPaidByOrg:
          return "white--text";
        default:
          return "inactive--text";
      }
    },
    icon(eventType) {
      switch (eventType) {
        case LogEventTypes.ApplicationCreated:
          return "save";
        case LogEventTypes.ApplicationSendForApproval:
          return "more_horiz";
        case LogEventTypes.ApplicationRejectedByOrg:
          return "block";
        case LogEventTypes.ApplicationApprovedByOrg:
          return "done";
        case LogEventTypes.ApplicationApprovedForPayment:
          return "done";
        case LogEventTypes.ApplicationPaidByOrg:
          return "mdi-piggy-bank";
        case LogEventTypes.ApplicationPaymentReset:
          return "undo";
        default:
          return "";
      }
    },
    itemColor(eventType) {
      switch (eventType) {
        case LogEventTypes.ApplicationApprovedForPayment:
        case LogEventTypes.ApplicationPaidByOrg:
          return "primary";
        default:
          return "white";
      }
    },
    eventAuthor(event) {
      if (event.personId) {
        return getPersonName(event.personId);
      }

      return "";
    },
    names(event) {
      if (event.orgId) {
        return [getOrgName(event.orgId)];
      }

      return [];
    }
  }
};
</script>