const ApplicationStatusTypes = {
    Saved: 2,
    Pending: 3,
    Returned: 4,
    Approved: 5,
    Paid: 6
};

Object.freeze(ApplicationStatusTypes);

export default ApplicationStatusTypes;