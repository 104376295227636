import VueInstance from "@/main";
import store from "@/store";
import { getErrorString, getHttpErrorString, getBuypassErrorString } from "@nifnpm/vue-error-utils";

export const determineRedirectOnError = async function (error, text) {
    if (error?.isAxiosError && error?.config.method === "get" && error?.request.status > 0) {
        await store.commit("errorStore/set", text);
        VueInstance.$router.push("/error");
        return true;
    }

    return false;
};

export const handleError = async function (error) {
    const text = getErrorString(error, VueInstance.$i18n, [getBuypassErrorString, getHttpErrorString]);

    if (await determineRedirectOnError(error, text)) {
        return;
    }

    VueInstance.$snacks.add(text, "error");
};