<template>
  <div>
    <template v-if="!createdByCurrentUser">
      <h3>{{ displayHandledOrgName }}</h3>
      <template v-if="nextActionIsApprove">
        <approval-panel
          :handled-org-id="handledOrgId"
          :handled-org-name="displayHandledOrgName"
          :user-can-approve="userCanApprove"
          :is-typing-response="isTypingResponse"
          @application-approved="$emit('application-approved')"
          @application-rejected="$emit('application-rejected')"
        >
        </approval-panel>
      </template>
      <template v-if="nextActionIsPaymentMethodSelection">
        <payment-method-selection-panel
          :handled-org-id="handledOrgId"
          :handled-org-name="displayHandledOrgName"
          :user-can-select="userCanSelectPaymentMethod"
          :available-payment-methods="availablePaymentMethods"
          :approved-orgs-count="approvedOrgsCount"
          :total-orgs-count="totalOrgsCount"
          :is-approved-by-all="isApprovedByAllRecipients"
          :is-typing-response="isTypingResponse"
          @payment-method-selected="$emit('payment-method-selected', $event)"
        >
        </payment-method-selection-panel>
      </template>
      <template v-if="nextActionIsPaymentSignature">
        <payment-signature-panel
          :handled-org-id="handledOrgId"
          :handled-org-name="displayHandledOrgName"
          :user-can-sign="userCanSignPayment"
          :user-has-already-signed="userHasAlreadySigned"
          :signature-count="signatureCount"
          :required-signature-count="requiredSignatureCount"
          :approved-orgs-count="approvedOrgsCount"
          :total-orgs-count="totalOrgsCount"
          :is-approved-by-all="isApprovedByAllRecipients"
          :user-can-reset="userCanReset"
          :is-typing-response="isTypingResponse"
          @payment-signed="$emit('payment-signed')"
          @payment-reset="$emit('payment-reset', $event)"
        >
        </payment-signature-panel>
      </template>
      <template v-if="nextActionIsPayout">
        <payout-panel
          :handled-org-id="handledOrgId"
          :approved-orgs-count="approvedOrgsCount"
          :total-orgs-count="totalOrgsCount"
          :handled-org-name="displayHandledOrgName"
          :user-can-pay="userCanPay"
          :is-approved-by-all="isApprovedByAllRecipients"
          :user-can-reset="userCanReset"
          :payment-method="applicationOrg.paymentMethod"
          :is-typing-response="isTypingResponse"
          @application-payout-requested="
            $emit('application-payout-requested', $event)
          "
          @payment-reset="$emit('payment-reset', $event)"
        >
        </payout-panel>
      </template>
      <template v-if="paymentMethodIsBuypass">
        <v-alert type="info" class="mt-4">
          <p class="ma-0">
            {{ $t("previewApplicationPageStrings.buypassInfoText") }}
          </p>
        </v-alert>
      </template>
      <template v-if="isHandlingCompleted">
        <handling-completed-panel :handled-org-name="displayHandledOrgName">
        </handling-completed-panel>
      </template>
    </template>
    <template v-if="canShowJournal">
      <journal-link-panel
        :application-id="applicationId"
        :handled-org-id="handledOrgId"
        :handled-org-name="displayHandledOrgName"
      >
      </journal-link-panel>
    </template>
    <template v-if="paymentMethodIsRemittance">
      <remittance-details-modal
        :applicationId="applicationId"
        :orgId="handledOrgId"
        :linkText="$t('remittancesStrings.detailsModal.link')"
        linkCss="primary-inverted-button"
      ></remittance-details-modal>
    </template>
    <template v-else>
      <em>{{ $t("previewApplicationPageStrings.journalNotAvailable") }}</em>
    </template>
  </div>
</template>

<script>
import ApprovalPanel from "./ApprovalPanel";
import PaymentMethodSelectionPanel from "./PaymentMethodSelectionPanel";
import PaymentSignaturePanel from "./PaymentSignaturePanel";
import PayoutPanel from "./PayoutPanel";
import HandlingCompletedPanel from "./HandlingCompletedPanel";
import JournalLinkPanel from "./JournalLinkPanel";
import { mapGetters, mapActions } from "vuex";
import { getOrgDisplayName } from "@/utils/org";
import PaymentMethods from "@/enums/PaymentMethods";
import { getActualPayingOrgId } from "@/utils/currentApplication";
import RemittanceDetailsModal from "../../components/RemittanceDetailsModal";
import buypassMerchants from "@/mixins/buypassMerchants";

export default {
  name: "OrgApplicationHandlingPanel",
  components: {
    PaymentMethodSelectionPanel,
    PaymentSignaturePanel,
    ApprovalPanel,
    PayoutPanel,
    HandlingCompletedPanel,
    JournalLinkPanel,
    RemittanceDetailsModal
  },
  mixins: [buypassMerchants],
  data() {
    return {
      currentOrgConfiguration: {}
    };
  },
  props: {
    applicationOrg: {
      type: Object,
      default: () => ({})
    },
    applicationId: String,
    handledOrgId: Number,
    approvedOrgsCount: Number,
    totalOrgsCount: Number,
    isTypingResponse: Boolean,
    createdByCurrentUser: Boolean
  },
  computed: {
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs",
      userOrgId: "contextStore/orgId",
      orgsWithPermissions: "currentOrgsStore/orgs",
      user: "oidcStore/oidcUser"
    }),
    paymentMethodIsBuypass() {
      return this.applicationOrg?.paymentMethod === PaymentMethods.Buypass;
    },
    paymentMethodIsRemittance() {
      return this.applicationOrg?.paymentMethod === PaymentMethods.Remittance;
    },
    isApprovedByAllRecipients() {
      return this.approvedOrgsCount === this.totalOrgsCount;
    },
    handledOrgWithPermissions() {
      return this.orgsWithPermissions.find(x => x.org.id === this.handledOrgId);
    },
    actualPayingOrgId() {
      if (this.handledOrgId) {
        return getActualPayingOrgId(this.handledOrgId);
      }

      return null;
    },
    actualPayingOrgWithPermissions() {
      if (this.actualPayingOrgId) {
        return this.orgsWithPermissions.find(
          x => x.org.id === this.actualPayingOrgId
        );
      }

      return null;
    },
    handledPayingOrg() {
      return this.payingOrgs.find(y => y.id === this.handledOrgId);
    },
    nextActionIsApprove() {
      return this.applicationOrg && !this.applicationOrg.approval;
    },
    nextActionIsPaymentMethodSelection() {
      return (
        this.applicationOrg?.approval && !this.applicationOrg?.paymentMethod
      );
    },
    nextActionIsPaymentSignature() {
      return (
        this.applicationOrg?.approval &&
        this.applicationOrg?.paymentMethod &&
        this.hasRemainingRequiredSignatures
      );
    },
    nextActionIsPayout() {
      return (
        this.applicationOrg?.approval &&
        this.applicationOrg?.paymentMethod &&
        !this.hasRemainingRequiredSignatures &&
        !this.applicationOrg?.paymentSent
      );
    },
    isHandlingCompleted() {
      return this.applicationOrg?.paymentSent;
    },
    displayHandledOrgName() {
      return getOrgDisplayName(this.handledPayingOrg);
    },
    userCanApprove() {
      return this.handledOrgWithPermissions?.approvalEnabled;
    },
    userCanSelectPaymentMethod() {
      return this.handledOrgWithPermissions?.paymentMethodSelectionEnabled ||
          this.actualPayingOrgWithPermissions?.paymentMethodSelectionEnabled;
    },
    userCanSignPayment() {
      return this.actualPayingOrgWithPermissions?.paymentSignatureEnabled;
    },
    userCanPay() {
      return this.isNotBuypassAndUserCanPay || this.isBuypassAndUserCanPay;
    },
    isNotBuypassAndUserCanPay() {
      return (
        this.applicationOrg.paymentMethod !== PaymentMethods.Buypass &&
        (this.handledOrgWithPermissions?.payoutEnabled ||
          this.actualPayingOrgWithPermissions?.payoutEnabled)
      );
    },
    isBuypassAndUserCanPay() {
      return (
        this.applicationOrg.paymentMethod === PaymentMethods.Buypass &&
        this.actualPayingOrgWithPermissions?.payoutEnabled &&
        this.isBuypassAdminForCurrentOrg
      );
    },
    userCanReset() {
      return (
        this.applicationOrg.paymentMethod === PaymentMethods.Buypass &&
        this.availablePaymentMethods.length > 1 &&
        (this.nextActionIsPaymentSignature || this.nextActionIsPayout)
      );
    },
    canShowJournal() {
      return this.nextActionIsPayout || this.isHandlingCompleted;
    },
    availablePaymentMethods() {
      const methods = [PaymentMethods.Manual];

      if (this.currentOrgConfiguration?.buypassSettings?.isActive) {
        methods.push(PaymentMethods.Buypass);
      }

      if (this.currentOrgConfiguration?.remittanceSettings?.isActive) {
        methods.push(PaymentMethods.Remittance);
      }

      return methods;
    },
    userHasAlreadySigned() {
      return this.applicationOrg?.paymentSignatures?.some(x => x.personId === Number(this.user.personid));
    },
    signatureCount() {
      return this.applicationOrg?.paymentSignatures?.length ?? 0;
    },
    requiredSignatureCount() {
      if (this.applicationOrg?.paymentMethod === PaymentMethods.Buypass) {
        return 2;
      }

      return 0;
    },
    hasRemainingRequiredSignatures() {
      return this.signatureCount < this.requiredSignatureCount;
    }
  },
  methods: {
    ...mapActions({
      getOrgConfiguration: "settings/orgConfigurationStore/getOrgConfiguration"
    }),
    async fetchOrgConfiguration() {
      const actualPayingOrgId = getActualPayingOrgId(this.handledOrgId);

      this.currentOrgConfiguration = await this.getOrgConfiguration(
        actualPayingOrgId
      );
    }
  },
  watch: {
    handledOrgId: {
      async handler(orgId) {
        if (orgId) {
          await this.fetchOrgConfiguration();
        }
      },
      immediate: true
    },
    actualPayingOrgId: {
      handler(orgId) {
        this.buypassOrgId = orgId;
      },
      immediate: true
    }
  }
};
</script>