<template>
  <div>
    <validation-provider
      rules="required"
      :name="$t('orgSearchStrings.payingOrgsAutocompleteValidationName')"
      v-slot="{errors}"
    >
      <v-autocomplete
        :value="value"
        :items="items"
        :loading="loading"
        :search-input.sync="search"
        :filter="filterOrgSearchResults"
        :error-messages="errors"
        :hide-no-data="loading || !isSearchStringValid(search)"
        hide-selected
        item-text="name"
        item-value="id"
        :label="$t('orgSearchStrings.payingOrgsAutocomplete')"
        :hint="$t('orgSearchStrings.payingOrgsAutocompletePlaceholder')"
        :no-data-text="$t('orgSearchStrings.noDataText')"
        multiple
        return-object
        outlined
        dense
        @change="onChange"
      >
        <template v-slot:selection></template>
        <template v-slot:item="{item}">
          <v-list-item-content>
            <v-list-item-title>{{ orgDisplayName(item) }}</v-list-item-title>
            <v-list-item-subtitle>{{ orgTypeDisplayName(item) }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </validation-provider>
    <div>
      <v-chip
        v-for="(item, index) in value"
        :key="index"
        close
        @click:close="removeOrg(item)"
      >{{orgNameWithOrgTypeCode(item)}}</v-chip>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";
import { ValidationProvider } from "vee-validate";
import { mapGetters, mapMutations } from "vuex";
import { copy } from "@/utils/object";
import { handleError } from "@/utils/errorHandler";
import {
  getOrgDisplayName,
  getOrgTypeDisplayName,
  getOrgNameWithOrgTypeCode
} from "@/utils/org";

export default {
  name: "OrgSearch",
  props: {
    claims: {
      default: () => ({}),
      type: Object
    }
  },
  components: {
    ValidationProvider
  },
  data() {
    return {
      value: [],
      loading: false,
      results: [],
      search: null,
      orgInRemoval: ""
    };
  },
  computed: {
    items() {
      return [...this.results, ...this.payingOrgs];
    },
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs",
      federationId: "application/currentApplicationStore/federationId"
    }),
    filteredFederationId() {
      if (this.federationId) {
        return this.federationId;
      }

      return null;
    }
  },
  watch: {
    async search(query) {
      // Items have already been requested
      if (this.loading) {
         return;
      }

      this.results = [];

      if (!this.isSearchStringValid(query)) {
        return;
      }

      this.loading = true;

      await this.getOrgSearchResults(query);

      this.loading = false;
    },
    payingOrgs(payingOrgs) {
      this.value = copy(payingOrgs);
    },
    value() {
      this.search = "";
      this.results = [];
    }
  },
  methods: {
    ...mapMutations({
      setPayingOrgs: "application/payingOrgsStore/setPayingOrgs",
      removeRateConfigurationGroup:
        "application/rateConfigurationsStore/removeRateConfigurationGroup"
    }),
    filterOrgSearchResults(_, queryText, itemText) {
      return (
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getOrgSearchResults(query) {
      try {
        const response = await api.v1.orgs.get({
          params: {
            name: query,
            activatedOnly: true,
            federationId: this.filteredFederationId
          }
        });

        if (response.status === 200) {
          this.results = response.data;
        }
      } catch (error) {
        await handleError(error);
      }
    },

    onChange(payingOrgs) {
      this.setPayingOrgs(payingOrgs);
    },
    removeOrg(org) {
      const allClaims = Object.values(this.claims)
        .flat()
        .filter(x => x && !x.isDeleted);

      const payingOrgs = allClaims.flatMap(x => x.payingOrgs);

      if (payingOrgs.indexOf(org.id) >= 0) {
        this.orgInRemoval = org.name;
        this.$snacks.add(this.$t("orgSearchStrings.orgInUse", { 0: this.orgInRemoval })
        );
      } else {
        const index = this.value.map(x => x.id).indexOf(org.id);

        if (index >= 0) {
          this.value.splice(index, 1);
        }

        this.removeRateConfigurationGroup(org.id);
      }

      this.setPayingOrgs(this.value);
    },
    isSearchStringValid(query) {
      return query && query.trim().length >= 3;
    },
    orgDisplayName(org) {
      return getOrgDisplayName(org);
    },
    orgTypeDisplayName(org) {
      return getOrgTypeDisplayName(org);
    },
    orgNameWithOrgTypeCode(org) {
      return getOrgNameWithOrgTypeCode(org);
    }
  }
};
</script>