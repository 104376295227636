<template>
  <div>
    <div class="py-2 font-weight-bold">
      {{ $t("previewApplicationPageStrings.paymentMethodSelectionPanel.nextActionPaymentMethodSelection") }}
    </div>
    <div class="py-2">
      <span v-if="userCanSelect">
        {{ $t("previewApplicationPageStrings.paymentMethodSelectionPanel.youCanSelectPaymentMethod", [handledOrgName]) }}
      </span>
      <span v-else>
        {{ $t("previewApplicationPageStrings.paymentMethodSelectionPanel.youCannotSelectPaymentMethod", [handledOrgName])}}
        <permissions-modal
          :link-text="$t('previewApplicationPageStrings.linkToRequiredPermissions')"
          :title="$t('previewApplicationPageStrings.paymentMethodSelectionPanel.permissionsModalTitle')"
          :end-text="$t('previewApplicationPageStrings.paymentMethodSelectionPanel.permissionsModalEndText')"
          :function-type-names="permittedFunctionTypeNames">
        </permissions-modal>
      </span>
    </div>
    <div class="py-2">
      <span v-if="isApprovedByAll">
        {{ $t("previewApplicationPageStrings.allOrgsHaveApproved")}}
      </span>
      <span v-else>
        {{ $t("previewApplicationPageStrings.allOrgsHaveNotApproved", [approvedOrgsCount, totalOrgsCount])}}
      </span>
    </div>
    <template v-if="isApprovedByAll && userCanSelect">
      <div class="py-2" v-for="method in availablePaymentMethods" :key="method">
        {{ $t(`previewApplicationPageStrings.paymentMethodSelectionPanel.paymentMethodInfoText.${method}`)}}
      </div>
      <div class="py-2">
          <v-btn
            v-for="method in availablePaymentMethods"
            :key="method"
            rounded
            class="primary-inverted-button mr-0 mr-sm-2 mt-2 mt-sm-0"
            @click="emitMethod(method)"
            :disabled="isSettingPaymentMethod || isTypingResponse"
            :loading="isSettingPaymentMethod"
            :block="$vuetify.breakpoint.xsOnly"
          >{{ $t(`previewApplicationPageStrings.paymentMethodSelectionPanel.paymentMethodButtonText.${method}`)}}</v-btn>
      </div>
      <div>
        <v-checkbox
          color="primary"
          :label="$t('previewApplicationPageStrings.labels.setAsPreferredMethod')"
          v-model="setAsPreferredMethod"
        ></v-checkbox>
      </div>
    </template>
  </div>
</template>

<script>
import { urls } from "@/utils/api";
import { mapGetters } from "vuex";
import PermissionsModal from "./PermissionsModal";
import { paymentMethodSelectionPermittedFunctionTypes } from "@/utils/permissions";

export default {
  name: "PaymentMethodSelectionPanel",
  components: { PermissionsModal },
  props: {
    handledOrgId: Number,
    handledOrgName: String,
    userCanSelect: Boolean,
    availablePaymentMethods: Array,
    approvedOrgsCount: Number,
    totalOrgsCount: Number,
    isApprovedByAll: Boolean,
    isTypingResponse: Boolean
  },
  data(){
    return {
      setAsPreferredMethod: false
    };
  },
  methods: {
    emitMethod(methodId){
      this.$emit('payment-method-selected', {methodId, setAsPreferredMethod: this.setAsPreferredMethod});
    }
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    isSettingPaymentMethod() {
      if (this.$route.params.id) {
        return this.urlState(urls.v1.applicationOrgs.setPaymentMethod(this.$route.params.id, this.handledOrgId));
      }
      return false;
    },
    permittedFunctionTypeNames() {
      return paymentMethodSelectionPermittedFunctionTypes().map(x => x.name);
    }
  }
};
</script>