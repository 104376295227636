<template>
  <v-app class="theme--nif">
    <header-block>
      <template v-slot:title>
        <router-link :to="'/start'">
          <v-img
            class="d-none d-sm-block"
            src="@/assets/IO.svg"
            id="io-logo-sm"
            :alt="$t('appLayoutStrings.toolbarTitle')"
            width="300"
            height="70"
          ></v-img>
          <v-img
            class="d-block d-sm-none"
            src="@/assets/io-logo.svg"
            id="io-logo"
            :alt="$t('appLayoutStrings.toolbarTitle')"
            width="58"
            height="70"
          ></v-img>
        </router-link>
      </template>
      <template v-slot:inside-right>
        <context-switch
          :orgs="orgsWithPermissions"
          :name="name"
        ></context-switch>
      </template>
      <template v-slot:menu>
        <app-menu
          :current-org="currentOrgWithPermissions"
        ></app-menu>
      </template>
    </header-block>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-main :class="[{'image-title-background': useImageTitleStyle}]" id="mainContent">
      <welcome-banner v-if="useImageTitleStyle"></welcome-banner>
      <v-container :class="['py-10', 'main-container', {'rounded-content': !$vuetify.breakpoint.mobile}]">
        <info-message-alerts :currentOrg="currentOrgWithPermissions"></info-message-alerts>
        <slot />
        <help-bar></help-bar>
      </v-container>
      <app-footer></app-footer>
    </v-main>
  </v-app>
</template>

<style lang="scss">
.main-container {
  min-height: calc(100vh - 312px);
}

.v-alert--prominent {
  .v-icon{
    margin-left: 8px;
  }
}

.v-expansion-panel-header,
.v-btn,
.v-input input,
a {
  &:focus {
    outline: 1px dotted #dddddd;
  }
}

.skip-to-content-link {
  position: absolute;
  left: -2000px;
  font-size: 14px;
  padding: 2.5rem 1rem;

  &:focus {
    left: 0px;
    color: #000;
    background-color: #fff;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.v-dialog {
  max-height: 90vh;
  overflow-y: hidden;

  .v-card.v-sheet {
    .v-card__text {
      max-height: calc(90vh - 164px); //subtract height of title + actions, probably brittle if more content is added.
      overflow-y: auto;
    }

    .v-card__title {
      background-color: var(--v-primary-variant-base);
      color: var(--v-inverted-base);
    }
  }
}

.theme--nif {
  .v-messages.theme--light {
    &.v-messages__wrapper {
      &.v-messages__message {
        opacity: 0.7;
      }
    }
  }

  .help-border {
    border: 2px solid var(--v-warning-base);
  }

  .large-comment-bubble {
    width: 60%;
  }
}
.v-data-table-header.v-data-table-header-mobile {
  display: none;
}

.v-data-table__mobile-row:nth-of-type(1) {
  margin-top: 1rem;
}

.v-data-table__mobile-table-row {
  .v-data-table__mobile-row {
    padding: 0 !important;
    height: auto !important;
    display: block;
    float: left;
    width: 100%;

    .v-data-table__mobile-row__cell {
      display: block;
      text-align: left !important;
    }
  }
}

.text-h1,
.text-h2,
.text-h3 {
  word-break: break-word;
}
</style>

<script>
import { mapGetters } from "vuex";
import InfoMessageAlerts from "./components/InfoMessageAlerts";
import ContextSwitch from "./components/ContextSwitch";
import HelpBar from "./components/help/HelpBar";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import AppMenu from "./components/AppMenu";
import WelcomeBanner from './components/WelcomeBanner';
import AppFooter from './components/AppFooter';
import HeaderBlock from "@/views/common/components/HeaderBlock";


export default {
  name: "AppLayout",
  components: {
    HelpBar,
    ContextSwitch,
    InfoMessageAlerts,
    AppMenu,
    WelcomeBanner,
    HeaderBlock,
    AppFooter
  },
  data() {
    return {
      helpBarOptions: {
        visible: false,
        contentType: HelpBarContentTypes.None,
        expandedSections: []
      },
      loading: true
    };
  },
  props: {
    styleOverride: String
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "oidcStore/oidcIsAuthenticated",
      user: "oidcStore/oidcUser",
      currentOrgId: "contextStore/orgId",
      orgsWithPermissions: "currentOrgsStore/orgs",
      contextInitiated: "contextStore/initiated"
    }),
    name() {
      return `${this.user?.given_name} ${this.user?.family_name}`;
    },
    currentOrgWithPermissions() {
      const org = this.orgsWithPermissions.find(
        x => x.org.id === this.currentOrgId
      );

      if (org) {
        return org;
      } else {
        return null;
      }
    },
    useImageTitleStyle() {
      return this.styleOverride === 'image-title';
    }
  },
  watch: {
    contextInitiated: {
      handler(val) {
        if (val) {
          this.loading = false;
        }
      },
      immediate: true
    }
  }
};
</script>