<template>
  <v-tooltip top color="black" v-if="claim.amount > 0">
    <template v-slot:activator="{ on }">
      <v-icon color="primary" v-on="on">info</v-icon>
    </template>

    <div v-for="(claimRateSetup, orgId, index) in claimRateLookup" :key="index" class="mb-2">
      <strong>{{ getOrgNameByOrgId(orgId) }}</strong>
      <ul>
        <li>{{$t('tripRateTooltipStrings.ratePerKilometer', [$t(`enums.vehicleTypes.${claim.claimTypeId}`), toMoney(claimRateSetup.vehicleTypeRate )])}}</li>
        <li>{{$t('tripRateTooltipStrings.ratePerPassenger', [toMoney(claimRateSetup.ratePerPassenger)])}}</li>
        <li>{{$t('tripRateTooltipStrings.minimumDrivingDistance', [claimRateSetup.minimumDrivingDistance]) }}</li>
      </ul>
    </div>

    <ul v-if="hasAdditionalInformation" class="mt-5">
      <li
        v-if="hasMultiplePayingOrgs"
      >{{ $t('tripRateTooltipStrings.splitKilometers', [splitKilometers])}}</li>
      <li
        v-if="hasPassengers"
      >{{ $tc('tripRateTooltipStrings.passengerCount', claim.passengers.length, { count: claim.passengers.length})}}</li>
    </ul>
  </v-tooltip>
  <v-tooltip top color="black" v-else>
    <template v-slot:activator="{ on }">
      <v-icon tag="span" color="warning" v-on="on">warning</v-icon>
    </template>
    {{$t('tripRateTooltipStrings.minimumDrivingDistanceWarning')}}
    <div v-for="(claimRateSetup, orgId, index) in claimRateLookup" :key="index" class="mb-2">
      <strong>{{ getOrgNameByOrgId(orgId) }}</strong>
      <ul>
        <li>{{$t('tripRateTooltipStrings.minimumDrivingDistance', [claimRateSetup.minimumDrivingDistance]) }}</li>
      </ul>
    </div>

    <ul v-if="hasAdditionalInformation" class="mt-5">
      <li
        v-if="hasMultiplePayingOrgs"
      >{{ $t('tripRateTooltipStrings.splitKilometers', [splitKilometers])}}</li>
    </ul>
  </v-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
import { toMoney, toNumeric } from "@/utils/numeric";
import { getOrgDisplayName } from "@/utils/org";

export default {
  name: "TripRateInformationTooltip",
  props: {
    claim: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapGetters({
      getRateConfiguration:
        "application/rateConfigurationsStore/rateConfiguration"
    }),
    toMoney(value) {
      return toMoney(value);
    },
    getOrgNameByOrgId(orgId) {
      orgId = parseInt(orgId, 10);

      const org = this.currentApplicationOrgs.find(x => x.id === orgId);

      if (org != null) {
        return getOrgDisplayName(org);
      }

      return "";
    }
  },
  computed: {
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs",
      currentApplicationOrgs: "application/currentApplicationStore/orgs"
    }),
    hasPassengers() {
      return this.claim.passengers && this.claim.passengers.length > 0;
    },
    hasMultiplePayingOrgs() {
      return !this.claim.usesSalaryFraction && this.claim.payingOrgs && this.claim.payingOrgs.length > 1;
    },
    hasAdditionalInformation() {
      return this.hasPassengers || this.hasMultiplePayingOrgs;
    },
    claimRateLookup() {
      const result = {};

      if (!this.claim?.payingOrgs) {
        return result;
      }

      for (const orgId of this.claim.payingOrgs) {
        // as we are passing params to vuex getter
        // the first function returns delegate
        // so we can have parameters in second
        const currentConfiguration = this.getRateConfiguration()(
          orgId,
          this.claim.date
        );

        if (currentConfiguration) {
          const vehicleRates = currentConfiguration['vehicleRates'];

          result[currentConfiguration.orgId] = {
            vehicleTypeRate: vehicleRates["vehicleRateTypes"].find(
              x => x["rateType"] === this.claim.claimTypeId
            )["rate"],
            ratePerPassenger: vehicleRates["passengerRate"],
            minimumDrivingDistance: vehicleRates["minDrivingDistance"]
          };
        }
      }

      return result;
    },
    splitKilometers() {
      if (
        this.claim.kilometers &&
        this.claim.payingOrgs &&
        this.claim.payingOrgs.length
      ) {
        return toNumeric(this.claim.kilometers / this.claim.payingOrgs.length);
      }

      return null;
    }
  },
  filters: {
    toMoney
  }
};
</script>