const validationErrors = {
    methods: {
        getErrors(observer) {
            const messages = [];
            const refs = Object.keys(observer.refs);
            if (refs?.length) {
                refs.forEach(v => {
                    const ref = observer.refs[v];
                    if (ref) {
                        const errors = ref.errors;
                        if (errors?.length) {
                            errors.forEach(e => {
                                messages.push(e);
                            });
                        }
                    }
                });
            }

            return messages;
        },
        showValidationErrorSnackbar(observer) {
            const observers = this.getOrCreateObserverArray(observer);

            const validationErrorsOnPage = observers.map(x => this.getErrors(x)).flat();

            if (validationErrorsOnPage?.length) {
                if (validationErrorsOnPage.length === 1) {
                    this.$snacks.add(this.$t(validationErrorsOnPage[0]), "warning");
                } else {
                    this.$snacks.add(this.$t("errorMessages.validationErrorsOnPage", [
                            validationErrorsOnPage.length
                        ]),
                        "warning"
                    );
                }
            }
        },
        getOrCreateObserverArray(observer) {
            if (Array.isArray(observer)) {
                return observer;
            }

            return [observer];
        }
    }
};

export default validationErrors;