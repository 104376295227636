const SalaryTypes = {
    Coach: 1,
    Instructor: 2,
    TalentDeveloper: 3,
    CourseInstructor: 4,
    Functionary: 5,
    Referee: 6,
    Custom: 7,
    Default: 8
};

Object.freeze(SalaryTypes);

export default SalaryTypes;