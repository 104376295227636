<template>
  <div class="container">
    <v-file-input
      outlined
      dense
      v-model="file"
      accept=".bmp, .jpg, .jpeg, .png, .gif, .pdf"
      :disabled="isUploading"
      :label="$t('attachmentUploadStrings.fileLabel')"
    ></v-file-input>
    <p>
      <em>{{$t('attachmentUploadStrings.permittedFileTypesInfoText')}}</em>
    </p>
    <v-btn
      rounded
      small
      class="primary-button"
      v-on:click="submitFile()"
      :disabled="!isFileSelected || isUploading"
      :loading="isUploading"
    >{{$t('attachmentUploadStrings.uploadButton')}}</v-btn>
  </div>
</template>

<script>
import { api, urls } from "@/utils/api";
import { mapGetters } from "vuex";
import { validationConstants } from "@/utils/validationConstants";

export default {
  name: "AttachmentUpload",
  data() {
    return {
      file: null
    };
  },
  computed: {
    ...mapGetters({
      user: "oidcStore/oidcUser",
      urlState: "utilsRestStore/urlState"
    }),
    isFileSelected() {
      return this.file != null;
    },
    isUploading() {
      if (this.user?.personid) {
        return this.urlState(
          urls.v1.persons.postAttachment(this.user.personid)
        );
      }
      return false;
    },
    maxFileSizeInMB() {
      return validationConstants?.attachmentUpload?.maxFileSizeInMB ?? 0;
    },
    maxFileSizeInBytes() {
      return this.maxFileSizeInMB * 1024 * 1024;
    }
  },
  methods: {
    async submitFile() {

      if (this.file.size === 0) {
        this.$emit(
          "upload-failed",
          this.$t("attachmentUploadStrings.fileEmptyError")
        );
        return;
      }

      if (this.file.size > this.maxFileSizeInBytes) {
        this.$emit(
          "upload-failed",
          this.$t("attachmentUploadStrings.fileTooLargeError", [this.maxFileSizeInMB])
        );
        return;
      }

      const formData = new FormData();

      formData.append("file", this.file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };

      try {
        const response = await api.v1.persons.postAttachment(
          formData,
          this.user.personid,
          config
        );

        if (response.status === 201) {
          this.$emit("file-added", {
            url: response.headers.location,
            filename: this.file.name,
            attachmentId: response.headers.location.substring(
              response.headers.location.lastIndexOf("/") + 1
            )
          });
          this.file = null;
        }
      } catch (error) {
        this.$emit("upload-failed");
      }
    }
  }
};
</script>