import { copy } from "@/utils/object";

const rateSummariesStore = {
    namespaced: true,
    state: {
        rateSummaries: []
    },
    mutations: {
        setRateSummaries(state, rateSummaries) {
            state.rateSummaries = copy(rateSummaries);
        }
    },
    getters: {
        rateSummaries: state => state.rateSummaries
    }
};

export default rateSummariesStore;