import { api } from "@/utils/api";
import Vue from '@/main';
import { handleError } from "@/utils/errorHandler";

const getFormattedMoment = function (date) {
    if (date) {
        return Vue.$moment(
            date,
            Vue.$t("commonStrings.dateValueFormat")
        ).format(Vue.$t("commonStrings.dateDisplayFormat"));
    }

    return "";
};

const mapRemittances = function (data) {
    return data.map(r => ({
        dataSenderOrgId: r.dataSenderOrgId,
        transmissionId: r.transmissionId,
        transactionCount: r.transactionCount,
        receiptReceivedCount: r.receiptReceivedCount,
        assignmentCount: r.assignmentCount,
        lastExportDate: getFormattedMoment(r.lastExportDate),
        lastReceiptDate: getFormattedMoment(r.lastReceiptDate)
    }));
};

const remittanceHistoryStore = {
    namespaced: true,
    state: {
        history: []
    },
    mutations: {
        set(state, remittances) {
            Vue.$set(state, "history", remittances);
        }
    },
    actions: {
        async fetch(context, orgId) {
            try {
                const response = await api.v1.orgs.getTransmissions(orgId);

                if (response.status === 200 && response.data) {
                    context.commit('set', mapRemittances(response.data));
                }
            } catch (error) {
                await handleError(error);
            }
        }
    },
    getters: {
        remittances: state => state.history
    }
};

export default remittanceHistoryStore;