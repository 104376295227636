<template>
  <v-card>
    <v-card-title><h2 class="text-h2">{{ $t("previewApplicationPageStrings.handling") }}</h2></v-card-title>
    <v-card-text>
      <div class="py-4" v-for="org in availableOrgs" :key="org.id">
        <org-application-handling-panel
          @application-approved="approve(org.id)"
          @application-rejected="reject(org.id)"
          @application-payout-requested="payout($event, org.id)"
          @payment-method-selected="paymentMethodSelected($event, org.id)"
          @payment-signed="paymentSigned(org.id)"
          @payment-reset="$emit('payment-reset', $event)"
          :application-org="getApplicationOrg(org.id)"
          :handled-org-id="org.id"
          :application-id="application.id"
          :approved-orgs-count="approvedOrgsCount"
          :total-orgs-count="totalOrgsCount"
          :is-typing-response="isTypingResponse"
          :created-by-current-user="createdByCurrentUser"
        ></org-application-handling-panel>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import OrgApplicationHandlingPanel from "./OrgApplicationHandlingPanel";

export default {
  name: "OrgsHandlingPanel",
  components: {
    OrgApplicationHandlingPanel
  },
  props: {
    application: {},
    availableOrgs: Array,
    isTypingResponse: Boolean,
    createdByCurrentUser: Boolean
  },
  computed: {
    approvedOrgsCount() {
      if (this?.application?.orgs) {
        return Object.values(this.application.orgs).filter(x => x.approval).length;
      }

      return null;
    },
    totalOrgsCount() {
      if (this?.application?.orgs) {
        return Object.values(this.application.orgs).length;
      }

      return null;
    }
  },
  methods: {
    getApplicationOrg(id) {
      if (this.application?.orgs?.[id]) {
        return this.application.orgs[id];
      }

      return null;
    },
    approve(id) {
      this.$emit('application-approved', id);
    },
    reject(id) {
      this.$emit('application-rejected', id);
    },
    payout(method, id) {
      this.$emit('application-payout-requested', {method, id});
    },
    paymentMethodSelected(method, orgId) {
      method.orgId = orgId;
      this.$emit('payment-method-selected', method);
    },
    paymentSigned(id) {
      this.$emit('payment-signed', id);
    }
  }
};
</script>