import Vue from "@/main";
import { copy } from "@/utils/object";
import _ from "lodash";

const salaryFractionsStore = {
    namespaced: true,
    state: {
        orgIds: []
    },
    mutations: {
        setOrgIds(state, orgIds) {
            Vue.$set(state, "orgIds", copy(orgIds));
        }
    },
    actions: {},
    getters: {
        totalFractionsCount: state => state.orgIds.length,
        fractions: function (state) {
            const result = {};

            for (const orgId of state.orgIds) {
                if (!result[orgId]) {
                    result[orgId] = 1;
                } else {
                    result[orgId] += 1;
                }
            }

            return result;
        },
        salaryFractionSplitAvailable: function (state) {
            //[...new Set(arr)] returns distinct
            // salary fraction should be available if we have
            // at least 2 paying orgs
            return [...new Set(state.orgIds)].length > 1;
        },
        uniqueOrgIds: function (state){
            return _.uniq( state.orgIds);
        }
    }
};

export default salaryFractionsStore;