<template>
  <component :is="applicationPageComponentName"></component>
</template>

<script>
import EditApplicationPage from '@/views/applications/edit/EditApplicationPage.vue';
import PreviewApplicationPage from '@/views/applications/preview/PreviewApplicationPage.vue';
import { mapGetters } from "vuex";


export default {
  name: "ApplicationDetailsPage",
  components: {
    EditApplicationPage,
    PreviewApplicationPage
  },
  computed: {
    ...mapGetters({
        currentApplication: "application/currentApplicationStore/application"
    }),
    applicationPageComponentName(){
      if(this.currentApplication.isDraft) {
        return 'EditApplicationPage';
      } else {
        return 'PreviewApplicationPage';
      }
    }
  }
};
</script>