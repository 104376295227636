<template>
  <div class="personalSettings">
    <v-skeleton-loader :loading="loading" type="card-heading, list-item@3">
      <v-card>
        <v-card-title class="py-0">
          <h1 class="text-h2 mt-10 mb-7">{{$t('personalSettingsStrings.emailSubscriptionsHeader')}}</h1>
                        <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onHelpClick"
          >help_outline</v-icon>
        </v-card-title>
        <v-card-text>

          <div class="subtitle-1">{{$t('personalSettingsStrings.emailSubscriptionsInfoText')}}</div>
        </v-card-text>
        <v-card-title class="py-0">
          <h2 class="text-h3 mt-8 mb-5">{{$t('personalSettingsStrings.personalEmailSubscriptionsHeader')}}</h2>
        </v-card-title>
        <v-card-text>
          <div v-for="item in personConfiguration.personalEmailSubscriptions" :key="item.emailType">
            <v-switch
              v-model="item.enabled"
              inset
              color="primary"
              :label="$t(`enums.personalEmailTypes.${item.emailType}`)"
            ></v-switch>
          </div>
        </v-card-text>
        <v-card-text v-if="personConfiguration.orgEmailSubscriptionCollections && personConfiguration.orgEmailSubscriptionCollections.length">
          <v-alert type="info">{{ $t("personalSettingsStrings.repeatingMailRuleText") }}</v-alert>
        </v-card-text>
        <div
          v-for="collection in personConfiguration.orgEmailSubscriptionCollections"
          :key="collection.orgId"
        >
          <v-card-title class="py-0">
            <h3 class="text-h3 mt-8 mb-5"
            >{{$t("personalSettingsStrings.orgEmailSubscriptionsHeader", [getOrgDisplayName(collection.orgId)])}}</h3>
          </v-card-title>
          <v-card-text>
            <div v-for="item in collection.orgEmailSubscriptions" :key="item.emailType">
              <v-switch
                v-model="item.enabled"
                inset
                color="primary"
                :label="$t(`enums.orgEmailTypes.${item.emailType}`, [getOrgDisplayName(collection.orgId)])"
              ></v-switch>
            </div>
          </v-card-text>
        </div>
        <v-card-actions>
          <v-btn
            rounded
            class="primary-button"
            @click="save"
            :disabled="!hasChanges || isSaving"
            :loading="isSaving"
          >{{$t('personalSettingsStrings.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-skeleton-loader>
  </div>
</template>

<script>
import { api, urls } from "@/utils/api";
import { copy } from "@/utils/object";
import { mapGetters } from "vuex";
import PersonalEmailTypes from "@/enums/PersonalEmailTypes";
import OrgEmailTypes from "@/enums/OrgEmailTypes";
import { getOrgDisplayName } from "@/utils/org";
import ObjectId from "bson-objectid";
import { compare } from "fast-json-patch";
import claimPanel from "@/mixins/claimPanel";
import { handleError } from "@/utils/errorHandler";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";

export default {
  name: "PersonalSettingsPage",
  data() {
    return {
      loading: true,
      personConfiguration: {
        personId: 0,
        personalEmailSubscriptions: [],
        orgEmailSubscriptionCollections: []
      },
      originalState: {}
    };
  },
  mixins: [claimPanel],
  computed: {
    ...mapGetters({
      user: "oidcStore/oidcUser",
      orgsWithPermissions: "currentOrgsStore/orgs",
      orgsLoaded: "currentOrgsStore/loaded",
      urlState: "utilsRestStore/urlState"
    }),
    defaultPersonalEmailSubscriptions() {
      return Object.values(PersonalEmailTypes).map(x => ({
        emailType: x,
        enabled: true
      }));
    },
    approverOrgEmailTypes() {
      return Object.values(OrgEmailTypes)
        .filter(x => x !== OrgEmailTypes.PendingPayment);
    },
    backofficeOrgEmailTypes() {
      return Object.values(OrgEmailTypes)
        .filter(x => x === OrgEmailTypes.PendingPayment);
    },
    hasChanges() {
      return compare(this.originalState, this.personConfiguration)
        .length;
    },
    isPosting() {
      return this.urlState(urls.v1.personConfigurations.post());
    },
    isPatching() {
      if (this.originalState?.id) {
        return this.urlState(urls.v1.personConfigurations.patch(this.originalState.id));
      }
      return false;
    },
    isSaving() {
      return this.isPosting || this.isPatching;
    }
  },
  methods: {
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.PersonalSettings);
    },
    async fetch() {
      try {
        const response = await api.v1.persons.personConfigurations(
          this.user.personid
        );

        if (response.status === 200 || response.status === 204) {
          if (response.status === 200 && response.data) {
            this.personConfiguration = copy(response.data);
            this.originalState = copy(response.data);
          } else {
            this.$set(this.personConfiguration, "personId", this.user.personid);
          }

          this.updatePersonConfiguration();
        }
      } catch (error) {
        await handleError(error);
        this.loading = false;
      }
    },
    async patch() {
      const patchDoc = compare(
        this.originalState,
        this.personConfiguration
      );

      const response = await api.v1.personConfigurations.patch(
        this.originalState.id,
        patchDoc
      );
      if (response.status === 204) {
        this.originalState = copy(this.personConfiguration);
        this.$snacks.add(this.$t("personalSettingsStrings.successfulSaveAlert"),
          "success"
        );
      }
    },
    async post() {
      const response = await api.v1.personConfigurations.post(
        this.personConfiguration
      );

      if (response.status === 201) {
        if (response.data) {
          this.personConfiguration = copy(response.data);
          this.originalState = copy(response.data);
        }
        this.$snacks.add(this.$t("personalSettingsStrings.successfulSaveAlert"),
          "success"
        );
      }
    },
    async save() {
      try {
        if (ObjectId.isValid(this.originalState.id)) {
          await this.patch();
        } else {
          await this.post();
        }
      } catch (error) {
        await handleError(error);
      }
    },
    getOrgDisplayName(orgId) {
      const org = this.orgsWithPermissions.find(x => x.org.id === orgId);

      if(org?.org) {
        return getOrgDisplayName(org.org);
      }

      return "";
    },
    defaultApproverOrgEmailSubscriptions(isDefaultRecipient) {
      return this.approverOrgEmailTypes.map(x => ({ emailType: x, enabled: isDefaultRecipient }));
    },
    defaultBackofficeOrgEmailSubscriptions(isDefaultRecipient) {
      return this.backofficeOrgEmailTypes.map(x => ({ emailType: x, enabled: isDefaultRecipient }));
    },
    updatePersonConfiguration() {
      if (this.orgsLoaded) {
        this.addMissingPersonalEmailSubscriptions();
        this.addMissingOrgEmailSubscriptionCollections();
        this.removeUnusedOrgEmailSubscriptionCollections();
        this.updateOrgEmailSubscriptions();

        this.loading = false;
      }
    },
    addMissingPersonalEmailSubscriptions() {
      const missing = copy(this.defaultPersonalEmailSubscriptions).filter(
        x =>
          !this.personConfiguration.personalEmailSubscriptions.some(
            y => y.emailType === x.emailType
          )
      );

      this.personConfiguration.personalEmailSubscriptions = this.personConfiguration.personalEmailSubscriptions.concat(
        missing
      );
    },
    addMissingOrgEmailSubscriptionCollections() {
      const missingOrgs = this.orgsWithPermissions.filter(
        x =>
          (x.approvalEnabled || x.backofficeEnabled) &&
          !this.personConfiguration.orgEmailSubscriptionCollections.some(
            y => y.orgId === x.org.id
          )
      );

      const collectionsToAdd = missingOrgs.map(x => ({
        orgId: x.org.id,
        orgEmailSubscriptions: []
      }));

      this.personConfiguration.orgEmailSubscriptionCollections = this.personConfiguration.orgEmailSubscriptionCollections.concat(
        collectionsToAdd
      );
    },
    removeUnusedOrgEmailSubscriptionCollections() {
      this.personConfiguration.orgEmailSubscriptionCollections = this.personConfiguration.orgEmailSubscriptionCollections.filter(
        x =>
          this.orgsWithPermissions.some(
            y =>
              y.org.id === x.orgId && (y.approvalEnabled || y.backofficeEnabled)
          )
      );
    },
    updateOrgEmailSubscriptions() {
      for (const collection of this.personConfiguration
        .orgEmailSubscriptionCollections) {
        const org = this.orgsWithPermissions.find(
          x => x.org.id === collection.orgId
        );

        if (org.approvalEnabled) {
          //add any missing emails for approver role
          const missing = this.getMissingTypes(this.defaultApproverOrgEmailSubscriptions(org.defaultEmailRecipient), collection);

          collection.orgEmailSubscriptions = collection.orgEmailSubscriptions.concat(
            missing
          );
        }

        if (org.backofficeEnabled) {
          //add any missing email types for backoffice role
          const missing = this.getMissingTypes(this.defaultBackofficeOrgEmailSubscriptions(org.defaultEmailRecipient), collection);

          collection.orgEmailSubscriptions = collection.orgEmailSubscriptions.concat(
            missing
          );
        }

        //remove any email types not in use
        collection.orgEmailSubscriptions = collection.orgEmailSubscriptions.filter(
          x =>
            (org.approvalEnabled &&
              this.approverOrgEmailTypes.some(
                y => y === x.emailType
              )) ||
            (org.backofficeEnabled &&
              this.backofficeOrgEmailTypes.some(
                y => y === x.emailType
              ))
        );
      }
    },
    getMissingTypes(defaultSubscriptions, collection) {
      return copy(defaultSubscriptions).filter(
            x =>
              !collection.orgEmailSubscriptions.some(
                y => y.emailType === x.emailType
              )
          );
    }
  },
  watch: {
    orgsLoaded(val) {
      if (val && this.loading) {
        this.updatePersonConfiguration();
      }
    }
  },
  async mounted() {
    this.fetch();
  }
};
</script>