import { api } from "@/utils/api";
import { handleError } from "@/utils/errorHandler";

const functionTypesStore = {
    namespaced: true,
    state: {
        functionTypes: []
    },
    mutations: {
        setFunctionTypes(state, functionTypes) {
            state.functionTypes = functionTypes;
        }
    },
    actions: {
        async fetch(context, payload) {
            if (context.state.functionTypes?.length) {
                return;
            }

            try {
                const response = await api.v1.functionTypes.get(
                    {
                        headers: {
                            Authorization: `Bearer ${payload.token}`
                        }
                    }
                );
                if (response.status === 200) {
                    context.commit('setFunctionTypes', response.data);
                }
            } catch (error) {
                await handleError(error);
            }
        }
    },
    getters: {
        functionTypes: state => state.functionTypes
    }
};

export default functionTypesStore;