import { api } from "@/utils/api";
import { handleError } from "@/utils/errorHandler";

const infoMessagesStore = {
    namespaced: true,
    state: {
        messages: []
    },
    mutations: {
        setMessages(state, messages) {
            state.messages = messages;
        }
    },
    actions: {
        async fetch(context, payload) {
            if (context.state.messages?.length) {
                return;
            }

            try {
                let response;
                if (payload?.token) {
                    response = await api.v1.infoMessages.getToday(
                        {
                            headers: {
                                Authorization: `Bearer ${payload.token}`
                            }
                        }
                    );
                } else {
                    response = await api.v1.infoMessages.getToday();
                }

                if (response.status === 200) {
                    context.commit('setMessages', response.data);
                }
            } catch (error) {
                await handleError(error);
            }
        },
        refetch(context) {
            context.commit('setMessages', []);
            context.dispatch('fetch');
        }
    },
    getters: {
        messages: state => state.messages
    }
};

export default infoMessagesStore;