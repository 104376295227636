<template>
  <validation-observer ref="rateConfigurationForm">
    <breadcrumb-block>
      <breadcrumb-router-link to="/backoffice">
        {{ $t("commonStrings.toOverview") }}
      </breadcrumb-router-link>
    </breadcrumb-block>
    <div class="rateConfiguration">
    <v-skeleton-loader :loading="initializing" type="card-heading" height="94">
      <v-row justify="space-between">
        <v-col cols="12" md="4" order-md="1">
            <text-field-date-picker
              v-model="localRateConfiguration.fromDate"
              :label="$t('rateConfigurationStrings.fromDateLabel')"
              :allowed-dates="allowedDate"
            ></text-field-date-picker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            rounded
            :block="$vuetify.breakpoint.xsOnly"
            class="primary-inverted-button"
            @click="onFillDefaults"
          >{{$t("rateConfigurationStrings.fillDefaultsButton")}}</v-btn>

        </v-col>
      </v-row>
    </v-skeleton-loader>


      <v-row align-content="space-around">
        <v-col cols="12">
          <salary-settings
            :loading="initializing"
            :salaryRates="localRateConfiguration.salaryRates"
          ></salary-settings>
        </v-col>
        <v-col cols="12">
          <receipt-settings
            :loading="initializing"
            :receiptRates="localRateConfiguration.receiptRates"
          ></receipt-settings>
        </v-col>
        <v-col cols="12">
          <vehicle-settings
            :loading="initializing"
            :vehicleRates="localRateConfiguration.vehicleRates"
            :vehicleTaxRates="vehicleTaxRates"
          ></vehicle-settings>
        </v-col>
        <v-col cols="12">
          <diet-settings
            :loading="initializing"
            :dietRates="localRateConfiguration.dietRates"
            :dietTaxRates="dietTaxRates"
          ></diet-settings>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-skeleton-loader :loading="initializing" type="actions">
            <v-btn
              rounded
              :disabled="isSaving"
              :loading="isSaving"
              :block="$vuetify.breakpoint.xsOnly"
              class="primary-button"
              @click="onSave"
            >{{$t("commonStrings.save")}}</v-btn>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import {compare} from "fast-json-patch";
import TextFieldDatePicker from "@/views/common/components/TextFieldDatePicker";
import BreadcrumbBlock from "@/views/common/components/BreadcrumbBlock";
import BreadcrumbRouterLink from "@/views/common/components/BreadcrumbRouterLink";

import VehicleSettings from "./components/VehicleSettings";
import SalarySettings from "./components/SalarySettings";
import ReceiptSettings from "./components/ReceiptSettings";
import DietSettings from "./components/DietSettings";

import DietStayTypes from "@/enums/DietStayTypes";
import DietLengthTypes from "@/enums/DietLengthTypes";
import ReceiptTypes from "@/enums/ReceiptTypes";
import SalaryTypes from "@/enums/SalaryTypes";
import VehicleTypes from "@/enums/VehicleTypes";
import InactiveReceiptTypes from "@/enums/InactiveReceiptTypes";
import AccountDefaults from "@/enums/AccountDefaults";

import validationErrors from "@/mixins/validationErrors";
import { api, urls } from "@/utils/api";
import ObjectId from "bson-objectid";
import { ValidationObserver } from "vee-validate";
import { copy } from "@/utils/object";
import _ from "lodash";
import { handleError } from "@/utils/errorHandler";

export default {
  components: {
    TextFieldDatePicker,
    BreadcrumbBlock,
    BreadcrumbRouterLink,
    VehicleSettings,
    ValidationObserver,
    SalarySettings,
    ReceiptSettings,
    DietSettings
  },
  mixins: [validationErrors],
  data: function () {
    return {
      initializing: false,
      localRateConfiguration: {
        id: String,
        orgId: Number,
        isActive: true,
        fromDate: this.$moment().format(),
        salaryRates: {
          salaryRateTypes: []
        },
        receiptRates: {
          receiptRateTypes: []
        },
        vehicleRates: {
          minDrivingDistance: 0,
          passengerRate: 0,
          trailerRate: 0,
          accountingId: "",
          passengerAccountingId: "",
          trailerAccountingId: "",
          vehicleRateTypes: []
        },
        dietRates: {
          accountingId: "",
          nightAdditionAccountingId: "",
          dietStayRateTypes: [],
          dietLengthRateTypes: []
        }
      },
      rateConfigurationComparison: {},
      existingConfigurationDates: [],
      vehicleTaxRates: {
        passengerMaxTaxRate: 0,
        trailerMaxTaxRate: 0,
        vehicleTypeMaxTaxRates: []
      },
      dietTaxRates: {
        dietLengthTypeMaxTaxRates: [],
        dietStayTypeMaxTaxRates: []
      }
    };
  },
  name: "RateConfigurationPage",
  computed: {
    ...mapGetters({
      currentOrgId: "contextStore/orgId",
      urlState: "utilsRestStore/urlState",
      contextInitiated: "contextStore/initiated"
    }),
    allowedDate: function () {
      const that = this;
      return function (val) {
        return (
          that.existingConfigurationDates.every(
            x => !that.$moment(val).isSame(x, "day")
          ) ||
          that
            .$moment(val)
            .isSame(that.rateConfigurationComparison.fromDate, "day")
        );
      };
    },
    formattedFromDate: function () {
      return this.$moment(this.localRateConfiguration.fromDate).format(
        this.$t("commonStrings.dateDisplayFormat")
      );
    },
    isPosting() {
      return this.urlState(urls.v1.rateConfigurations.post());
    },
    isPatching() {
      if (this.localRateConfiguration?.id) {
        return this.urlState(
          urls.v1.rateConfigurations.patch(this.localRateConfiguration.id)
        );
      }

      return false;
    },
    isSaving() {
      return this.isPosting || this.isPatching;
    }
  },
  methods: {
    async save() {
      if (ObjectId.isValid(this.localRateConfiguration.id)) {
        try {
          const patchDoc = compare(
            this.rateConfigurationComparison,
            this.localRateConfiguration
          );

          const response = await api.v1.rateConfigurations.patch(
            this.localRateConfiguration.id,
            patchDoc
          );
          if (response.status === 204) {
            this.$snacks.add(this.$t("rateConfigurationStrings.saveSuccessAlert"),
              "success",
              true
            );
            this.$router.push("/backoffice");
          }
        } catch (error) {
          await handleError(error);
        }
      } else {
        try {
          this.localRateConfiguration.orgId = this.currentOrgId;
          const response = await api.v1.rateConfigurations.post(
            this.localRateConfiguration
          );
          if (response.status === 201) {
            this.$snacks.add(this.$t("rateConfigurationStrings.saveSuccessAlert"),
              "success",
              true
            );
            this.$router.push("/backoffice");
          }

        } catch (error) {
          await handleError(error);
        }
      }
    },
    prepareSalaryForSave() {
      for (const salaryRate of this.localRateConfiguration.salaryRates
        .salaryRateTypes) {
        if (!salaryRate.enabled) {
          salaryRate.accountingId = "";
        }
      }
    },
    prepareReceiptForSave() {
      for (const receiptRate of this.localRateConfiguration.receiptRates
        .receiptRateTypes) {
        if (!receiptRate.enabled) {
          receiptRate.requiresAttachment = 0;
          receiptRate.accountingId = "";
        }
      }
    },
    prepareVehicleForSave() {
      for (const vehicleRate of this.localRateConfiguration.vehicleRates
        .vehicleRateTypes) {
        if (!vehicleRate.enabled) {
          vehicleRate.rate = 0;
        }
      }

      if(!this.localRateConfiguration.vehicleRates.minDrivingDistance){
        this.localRateConfiguration.vehicleRates.minDrivingDistance = 0;
      }

      if(!this.localRateConfiguration.vehicleRates.passengerRate){
        this.localRateConfiguration.vehicleRates.passengerRate = 0;
      }

      if(!this.localRateConfiguration.vehicleRates.trailerRate){
        this.localRateConfiguration.vehicleRates.trailerRate = 0;
      }
    },
    prepareDietForSave() {
      for (const lengthRate of this.localRateConfiguration.dietRates
        .dietLengthRateTypes) {
        if (!lengthRate.enabled) {
          lengthRate.rate = 0;
        }
      }

      for (const stayRate of this.localRateConfiguration.dietRates
        .dietStayRateTypes) {
        if (!stayRate.enabled) {
          stayRate.rate = 0;
        }
      }
    },
    prepareForSave() {
      this.prepareSalaryForSave();
      this.prepareReceiptForSave();
      this.prepareVehicleForSave();
      this.prepareDietForSave();

      // removing time (hours, minutes, seconds) part of date
      this.localRateConfiguration.fromDate = this.$moment(
        this.localRateConfiguration.fromDate
      )
        .startOf("day")
        .format();
    },
    onFillDefaults() {
      this.fillSalaryDefaults();
      this.fillReceiptDefaults();
      this.fillVehicleDefaults();
      this.fillDietDefaults();
    },
    fillSalaryDefaults() {
      for (const salaryRate of this.localRateConfiguration.salaryRates
        .salaryRateTypes) {
          const match = AccountDefaults.salaries.find(x => x.rateType === salaryRate.rateType);

          if (match) {
            salaryRate.enabled = true;
            salaryRate.accountingId = match.accountingId;
          }
      }
    },
    fillReceiptDefaults() {
      for (const receiptRate of this.localRateConfiguration.receiptRates
        .receiptRateTypes) {
          const match = AccountDefaults.receipts.find(x => x.rateType === receiptRate.rateType);

          if (match) {
            receiptRate.enabled = true;
            receiptRate.accountingId = match.accountingId;
          }
      }
    },
    fillVehicleDefaults() {
      this.localRateConfiguration.vehicleRates.accountingId = AccountDefaults.vehicle.accountingId;
      this.localRateConfiguration.vehicleRates.passengerAccountingId = AccountDefaults.vehicle.passengerAccountingId;
      this.localRateConfiguration.vehicleRates.trailerAccountingId = AccountDefaults.vehicle.trailerAccountingId;
    },
    fillDietDefaults() {
      this.localRateConfiguration.dietRates.accountingId = AccountDefaults.diet.accountingId;
      this.localRateConfiguration.dietRates.nightAdditionAccountingId = AccountDefaults.diet.nightAdditionAccountingId;
    },
    async onSave() {
      const success = await this.$refs.rateConfigurationForm.validate();
      if (success) {
        this.prepareForSave();
        await this.save();
      } else {
        this.showValidationErrorSnackbar(this.$refs.rateConfigurationForm);
      }
    },
    async fetch() {
      try {
        const response = await api.v1.orgs.rateConfigurations(
          this.currentOrgId
        );
        if (response.status === 200) {
          return response.data;
        }
        return [];
      } catch (error) {
        await handleError(error);
        return [];
      }
    },
    getEnabledValidationid(rateType) {
      return `enabled_${rateType}`;
    },
    async getMaxTaxRates() {
      try {
        const response = await api.v1.taxRatePeriods.current();
        if (response.status === 200) {
          this.vehicleTaxRates.vehicleTypeMaxTaxRates =
            response.data.vehicleTypeMaxTaxRates;
          this.vehicleTaxRates.passengerMaxTaxRate =
            response.data.passengerMaxTaxRate;
          this.vehicleTaxRates.trailerMaxTaxRate =
            response.data.trailerMaxTaxRate;
          this.dietTaxRates.dietLengthTypeMaxTaxRates =
            response.data.dietLengthTypeMaxTaxRates;
          this.dietTaxRates.dietStayTypeMaxTaxRates =
            response.data.dietStayTypeMaxTaxRates;
        }
      } catch (error) {
        await handleError(error);
      }
    },
    buildDefaultTypeArrays() {
      this.localRateConfiguration.salaryRates.salaryRateTypes = Object.values(
        SalaryTypes
      ).filter(x => x === SalaryTypes.Default)
      .map(x => ({ rateType: x, enabled: false, accountingId: null }));

      this.localRateConfiguration.receiptRates.receiptRateTypes = Object.values(
        ReceiptTypes
      ).filter(x => Object.values(InactiveReceiptTypes).every(y => y !== x))
      .map(x => ({
        rateType: x,
        enabled: false,
        requiresAttachment: false,
        accountingId: null
      }));

      this.localRateConfiguration.vehicleRates.vehicleRateTypes = Object.values(
        VehicleTypes
      ).map(x => ({
        rateType: x,
        enabled: false,
        rate: 0
      }));

      this.localRateConfiguration.dietRates.dietStayRateTypes = Object.values(
        DietStayTypes
      ).map(x => ({ rateType: x, enabled: false, rate: 0 }));

      this.localRateConfiguration.dietRates.dietLengthRateTypes = Object.values(
        DietLengthTypes
      ).map(x => ({ rateType: x, enabled: false, rate: 0 }));
    },
    init(id, allConfigs) {
      let config;

      if (allConfigs?.length) {
        config = allConfigs.find(x => x.id === id);
      } else {
        config = null;
      }

      if (!config) {
        this.$snacks.add(this.$t("rateConfigurationStrings.existingConfigNotFound"),
          "error"
        );
      }

      this.localRateConfiguration = config;
      this.rateConfigurationComparison = copy(config);
    },
    initNew(allConfigs) {
      let lastConfig;

      if (allConfigs?.length) {
        lastConfig = _.last(allConfigs.sort(x => x.fromDate));
      } else {
        lastConfig = null;
      }

      if (lastConfig) {
        this.localRateConfiguration = copy(lastConfig);
        this.localRateConfiguration.id = null;
      }

      if (lastConfig &&
      this.$moment(lastConfig.fromDate).isSameOrAfter(this.$moment(), "day")) {
        this.localRateConfiguration.fromDate = this.$moment(lastConfig.fromDate)
              .add(1, "days")
              .format(this.$t("commonStrings.dateValueFormat"));
      } else {
        this.localRateConfiguration.fromDate = this.$moment().format(this.$t("commonStrings.dateValueFormat"));
      }
    },
    setExistingConfigurationDates(allConfigs) {
      if (allConfigs?.length) {
        this.existingConfigurationDates = allConfigs.map(x => x.fromDate);
      } else {
        this.existingConfigurationDates = [];
      }
    },
    async onMountedOrContextInitiated(){
      this.buildDefaultTypeArrays();
      this.getMaxTaxRates();

      const allConfigs = await this.fetch();

      this.setExistingConfigurationDates(allConfigs);

      if (this.$route.params.id) {
        this.init(this.$route.params.id, allConfigs);
      } else {
        this.initNew(allConfigs);
      }
    }
  },
  async mounted() {
    if (this.contextInitiated) {
      await this.onMountedOrContextInitiated();
    }
  },
  watch: {
    //Here immediate: true defeates the purpouse of waiting for the contextInitiated value being flipped to true,
    //as we are using it to wait for context information to be available before doing addition calls, which would fail if context is not initiated.
    //Instead mounted is called immediately when context is already initiated and we navigate to this page, in that case contextInitiated is already true, and we could unwatch it.
    //In the case where we hit this page directly mounted is essentially skipped and we wait for contextInitiated to be true before we do addition calls which depends on context.
    contextInitiated: {
      handler: async function (val) {
        if(val){
          await this.onMountedOrContextInitiated();
        }
      }
    }
  }
};
</script>