<template>
  <v-row v-if="areBuypassSettingsVisible">
    <v-col cols="12">
      <h3 class="text-h3 mt-8 mb-5">
        {{ $t("generalSettingsStrings.buypassSettings.title") }}
      </h3>
      <v-switch
        color="primary"
        inset
        :label="
          $t('generalSettingsStrings.buypassSettings.labels.isActive')
        "
        :disabled="!isBuypassAdminForCurrentOrg"
        v-model="orgConfiguration.buypassSettings.isActive"
      ></v-switch>
      <v-row v-if="isBuypassAdminForCurrentOrg">
        <v-col cols="12" sm="6" md="3">
          <validation-provider
            :rules="{
              required_if: orgConfiguration.buypassSettings.isActive,
            }"
            v-slot="{ errors }"
            :name="
              $t(
                'generalSettingsStrings.buypassSettings.labels.settlementAccountId'
              )
            "
          >
            <v-select
              v-model="
                orgConfiguration.buypassSettings.settlementAccountId
              "
              :items="availableBuypassAccounts"
              item-value="settlementAccountId"
              item-text="name"
              :label="
                $t(
                  'generalSettingsStrings.buypassSettings.labels.settlementAccountId'
                )
              "
              :placeholder="
                $t(
                  'generalSettingsStrings.buypassSettings.placeholders.settlementAccountId'
                )
              "
              :error-messages="errors"
              outlined
              dense
            ></v-select>
          </validation-provider>
        </v-col>
      </v-row>
      <em v-else>{{$t('generalSettingsStrings.buypassSettings.editNotAvailableMessage')}}</em>
      <div class="mt-2"><buypass-settings-info-modal></buypass-settings-info-modal></div>
    </v-col>
  </v-row>
</template>

<script>
import BuypassSettingsInfoModal from "./BuypassSettingsInfoModal";
import { mapGetters } from "vuex";
import { api } from "@/utils/api";
import { ValidationProvider } from "vee-validate";
import { handleError } from "@/utils/errorHandler";
import buypassMerchants from "@/mixins/buypassMerchants";

export default {
  name: "BuypassSettings",
  components: {
      BuypassSettingsInfoModal,
      ValidationProvider
  },
  mixins: [buypassMerchants],
  data() {
    return {
      availableBuypassAccounts: []
    };
  },
  props: {
    orgConfiguration: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    relationActivationRights: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    orgId: Number
  },
  computed: {
    ...mapGetters({
      orgsWithPermissions: "currentOrgsStore/orgs"
    }),
    currentOrgWithPermissions() {
      if (this.orgsWithPermissions && this.orgId) {
        return this.orgsWithPermissions.find(x => x.org.id === this.orgId);
      }

      return null;
    },
    areBuypassSettingsVisible() {
      return this.relationActivationRights.areBuypassSettingsPermitted;
    },
    canGetBuypassAccounts() {
      return this.isBuypassAdminForCurrentOrg && this.areBuypassSettingsVisible;
    }
  },
  methods: {
    async fetchBuypassAccounts() {
      try {
        const response = await api.v1.orgs.buypassSettlementAccounts(
          this.orgId
        );
        if (response.status === 200) {
          this.availableBuypassAccounts = response.data;
        }
      } catch (error) {
        await handleError(error);
      }
    }
  },
  watch: {
    canGetBuypassAccounts: {
      async handler(val) {
        if (val) {
          await this.fetchBuypassAccounts();
        }
      },
      immediate: true
    },
    orgId: {
      handler(orgId) {
        this.buypassOrgId = orgId;
      },
      immediate: true
    }
  }
};
</script>