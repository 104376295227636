const personalSettingsStrings = {
  name: "Dine innstillinger",
  pageTitle: "@:personalSettingsStrings.name",
  pageHeader: "@:personalSettingsStrings.name",
  emailSubscriptionsHeader: "E-postvarsling",
  emailSubscriptionsInfoText: "Velg om du vil varsles på e-post for følgende hendelser:",
  personalEmailSubscriptionsHeader: "For dine krav",
  orgEmailSubscriptionsHeader: "For krav til {0}",
  successfulSaveAlert: "Dine innstillinger ble lagret",
  save: "@:commonStrings.save",
  repeatingMailRuleText: `Nytt varsel om krav til godkjenning eller utbetaling sendes ikke dersom samme type varsel er sendt siste 24 timer,
   og det ikke er utført noen godkjenninger/utbetalinger siden forrige varsel.`
};

export default personalSettingsStrings;