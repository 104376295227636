import Vue from "@/main";
import { toMoney } from "@/utils/numeric";
import store from '@/store';
import _ from 'lodash';
import { calculateSalaryFractionCoefficientForOrg, getSalaryFractionsCountForOrg, getTotalSalaryFractionsCount } from "@/utils/claims/salary";

export const calculateForOrg = function (claim, orgId) {
    const rateConfiguration = getRateConfiguration(orgId, claim.date);

    if (!rateConfiguration) {
        return 0;
    }

    if (parseFloat(claim.kilometers) >= rateConfiguration.vehicleRates["minDrivingDistance"]) {
        const distance = calculateOrgTripDistance(claim, orgId);
        const rate = getTotalRateForOrg(claim, orgId);

        return distance * rate;
    }

    //below minimum distance
    return 0;
};

export const calculateVehicleAmountForOrg = function (claim, orgId) {
    const rateConfiguration = getRateConfiguration(orgId, claim.date);

    if (!rateConfiguration) {
        return 0;
    }

    if (parseFloat(claim.kilometers) >= rateConfiguration.vehicleRates["minDrivingDistance"]) {
        const distance = calculateOrgTripDistance(claim, orgId);
        const rate = getVehicleRateForOrg(claim, orgId);

        return distance * rate;
    }

    //below minimum distance
    return 0;
};

export const calculatePassengerAmountForOrg = function (claim, orgId) {
    const rateConfiguration = getRateConfiguration(orgId, claim.date);

    if (!rateConfiguration) {
        return 0;
    }

    if (parseFloat(claim.kilometers) >= rateConfiguration.vehicleRates["minDrivingDistance"]) {
        const distance = calculateOrgTripDistance(claim, orgId);
        const rate = getPassengerRateByPassengerCountForOrg(claim, orgId);

        return distance * rate;
    }

    //below minimum distance
    return 0;
};



export const calculateTotalTripAmountForOrgs = function (claim) {
    return _.sum(claim.payingOrgs.map(orgId => calculateForOrg(claim, orgId)));
};

export const calculateOrgTripDistance = function (claim, orgId) {
    const isTripValid = claim.kilometers && claim.payingOrgs?.length;

    if (!isTripValid) {
        return claim.kilometers;
    }

    if (!claim.usesSalaryFraction) {
        return claim.kilometers / claim.payingOrgs.length;
    }

    return claim.kilometers * calculateSalaryFractionCoefficientForOrg(orgId);
};

export const getTotalRateForOrg = function (claim, orgId) {
    const rateConfiguration = getRateConfiguration(orgId, claim.date);

    if (!rateConfiguration) {
        return 0;
    }

    const baseRate = getVehicleTypeRate(claim.claimTypeId, rateConfiguration);
    const passengerTotalRate = getPassengerRateByPassengerCount(claim, rateConfiguration);

    return baseRate + passengerTotalRate;
};

export const getVehicleRateForOrg = function (claim, orgId) {
    const rateConfiguration = getRateConfiguration(orgId, claim.date);

    if (!rateConfiguration) {
        return 0;
    }

    return getVehicleTypeRate(claim.claimTypeId, rateConfiguration);
};

export const getPassengerRateByPassengerCountForOrg = function (claim, orgId) {
    const rateConfiguration = getRateConfiguration(orgId, claim.date);

    if (!rateConfiguration) {
        return 0;
    }

    return getPassengerRateByPassengerCount(claim, rateConfiguration);
};

const getVehicleTypeRate = function (claimTypeId, rateConfiguration) {
    const rateType = rateConfiguration?.vehicleRates?.vehicleRateTypes?.find(
        x => x.rateType === claimTypeId
    );

    if (rateType) {
        return rateType.rate;
    }

    return 0;
};

const getPassengerRateByPassengerCount = function (claim, rateConfiguration) {
    const passengerRate = getPassengerRate(rateConfiguration);

    if (claim?.passengers?.length){
        return claim.passengers.length * passengerRate;
    }

    return 0;
};

const getPassengerRate = function (rateConfiguration) {
    return rateConfiguration?.vehicleRates?.passengerRate || 0;
};

const getMultiplierExplanationText = function (claim, rateConfiguration) {
    if (claim.passengers?.length) {
        return `(${toMoney(
            getVehicleTypeRate(claim.claimTypeId, rateConfiguration)
        )}${Vue.$t("tripsSummaryPanelStrings.perKm")} + (${Vue.$tc(
                "tripsSummaryPanelStrings.passenger",
                claim.passengers.length
            )} × ${toMoney(getPassengerRate(rateConfiguration))}${Vue.$t(
                "tripsSummaryPanelStrings.perKm"
            )}))`;
    }

    return `${toMoney(
        getVehicleTypeRate(claim.claimTypeId, rateConfiguration)
    )}${Vue.$t("tripsSummaryPanelStrings.perKm")}`;
};

const getKilometersExplanationText = function (claim, orgId) {
    if (claim.usesSalaryFraction) {
        const parts = getSalaryFractionsCountForOrg(orgId);
        const totalParts = getTotalSalaryFractionsCount();

        if (parts !== totalParts) {
            return Vue.$tc("tripsSummaryPanelStrings.salaryFractionExplanation",
                parts,
                {
                    kilometers: claim.kilometers,
                    total: totalParts,
                    parts
                });
        }
    }

    if (claim.payingOrgs.length > 1) {
        return `(${claim.kilometers} ${Vue.$t(
            "tripsSummaryPanelStrings.kilometers"
        )} / ${claim.payingOrgs.length} ${Vue.$t(
            "summaryPanelStrings.payingOrgs"
        )})`;
    }

    return `${claim.kilometers} ${Vue.$t(
        "tripsSummaryPanelStrings.kilometers"
    )}`;
};

const getRateConfiguration = store.getters['application/rateConfigurationsStore/rateConfiguration'];

export const getTripExplanationForOrg = function(orgId, claim){
    const rateConfiguration = getRateConfiguration(orgId, claim.date);

    return `${getMultiplierExplanationText(
        claim,
        rateConfiguration
    )} × ${getKilometersExplanationText(
        claim,
        orgId
    )}`;
};

export const getTripClaimsForOrg = function (orgId, claims, prefix) {
    const orgClaims = claims.trips.filter(
        x => x?.amount && x.payingOrgs?.includes(orgId)
    );

    return orgClaims.map(function (claim, index) {
        return {
            id: [prefix, index].join("_"),
            name: this.$t(`enums.vehicleTypes.${claim.claimTypeId}`),
            details: getTripExplanationForOrg(orgId, claim),
            amount: calculateForOrg(
                claim,
                orgId
            )
        };
    }, Vue);
};