import _ from "lodash";
const customSort = {
    methods: {
        customSort(items, index, isDescending) {
            if (!items.length) {
                return items;
            }
            return _.orderBy(items, this.getSortProperties(items, index), isDescending.map(x => this.getSortOrder(x)));
        },

        //If value is date, return a sortable date string.
        getSortProperties(items, index) {
            return index.map(i => {
                if (this.$moment(items[0][i], this.$t("commonStrings.dateValueFormat"), true).isValid()) {
                    return item => this.$moment(item[i], this.$t("commonStrings.dateValueFormat")).format(this.$t("commonStrings.dateValueFormat"));
                } else {
                    return i;
                }
            });
        },
        getSortOrder(item) {
            if (item) {
                return "desc";
            }

            return "asc";
        }
    }
};

export default customSort;