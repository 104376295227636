import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from './i18n';
import { preset } from '@nifnpm/vue-cli-plugin-vuetify-preset/preset';

Vue.use(Vuetify);

const options = {
  preset,
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true }
   },
   lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
};

export default new Vuetify(options);
