const communicationPanelStrings = {
  title: "Logg og kommentarer",
  to: "@.lower:commonStrings.to",
  recipients: "Mottakere som skal varsles på e-post",
  disabledText: "Nye kommentarer kan kun opprettes etter at kravet er sendt, bruk feltet Merknad for å legge til informasjon om enkeltbeløp.",
  disabledByArchivedText: "Kan ikke opprette nye kommentarer når kravet er arkivert.",
  visibilityInfoText: "Alle kommentarer er synlige både for avsender og alle mottakere av kravet.",
  senderEmailInfoText: "Kravets avsender mottar alltid varsel på e-post når en ny kommentar legges til, med mindre dette er slått av i '@:personalSettingsStrings.name'.",
  recipientEmailInfoText: `Dersom ingen organisasjonsledd er valgt under '@:communicationPanelStrings.recipients'
   sendes e-postvarsel til alle mottakere av kravet.`,
  recipientPersonEmailInfoText: `Varsel til mottakere sendes til alle personer i organisasjonsleddet som har tilgang til å lese kommentaren,
   så lenge varsling ikke er slått av i '@:personalSettingsStrings.name'.`,
  newCommentSubtitle: "Legg til ny kommentar",
  filterSubtitle: "Filter",
  labels: {
    showLogEvents: "@:commonStrings.view logg",
    showComments: "@:commonStrings.view kommentarer",
    filterCommentsForOrg: "Velg for kun å vise kommentarer til utvalgte mottakere",
    sendReplyBtn: "Legg til",
    eventAuthor: "Utført av: {0}"
  },
  placeholders: {
    replyContent: "Ny kommentar",
    recipients: "@:communicationPanelStrings.recipients (valgfritt)"
  },
  emptyCommentSection: "Ingen logg eller kommentarer å vise",
  eventDateTimeDisplayFormat: "D. MMMM YYYY HH:mm"

};

export default communicationPanelStrings;