import { mapGetters } from "vuex";

const readonlyPanel = {
    computed: {
        ...mapGetters({
            payingOrgNames: "application/payingOrgsStore/nameLookup"
        })
    },
    methods: {
        getNames(orgIds) {
            if (!orgIds) {
                return [];
            }
            return orgIds.map(orgId => this.payingOrgNames[orgId]);
        }
    }
};

export default readonlyPanel;