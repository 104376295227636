<template>
  <v-card>
    <v-card-title class="py-0"
      ><h1 class="text-h2 mt-10 mb-7">
        {{ $t("remittancesStrings.importPanel.title") }}
      </h1></v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-file-input
            outlined
            dense
            v-model="file"
            :disabled="isUploading"
            :label="$t('remittancesStrings.importPanel.labels.fileLabel')"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            rounded
            small
            class="primary-button"
            v-on:click="submitFile()"
            :disabled="!isFileSelected || isUploading"
            :loading="isUploading"
          >{{$t('remittancesStrings.importPanel.labels.uploadButton')}}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { api, urls } from "@/utils/api";
  import { mapGetters } from "vuex";
  import { validationConstants } from "@/utils/validationConstants";
  import { handleError } from "@/utils/errorHandler";

  export default {
    name: "RemittanceImportPanel",
    data() {
      return {
        file: null
      };
    },
    computed: {
      ...mapGetters({
        user: "oidcStore/oidcUser",
        urlState: "utilsRestStore/urlState",
        currentOrgId: "contextStore/orgId"
      }),
      isFileSelected() {
        return this.file != null;
      },
      isUploading() {
        if (this.currentOrgId) {
          return this.urlState(
            urls.v1.orgs.importRemittanceReceipt(this.currentOrgId)
          );
        }
        return false;
      },
      maxFileSizeInMB() {
        return validationConstants?.remittanceImportPanel?.maxFileSizeInMB ?? 0;
      },
      maxFileSizeInBytes() {
        return this.maxFileSizeInMB * 1024 * 1024;
      }
    },
    methods: {
      async submitFile() {

        if (this.file.size === 0) {
          this.showErrorMessage(this.$t("remittancesStrings.importPanel.fileEmptyError"));
          return;
        }

        if (this.file.size > this.maxFileSizeInBytes) {
          this.showErrorMessage(this.$t("remittancesStrings.importPanel.fileTooLargeError", [this.maxFileSizeInMB]));
          return;
        }

        const formData = new FormData();

        formData.append("file", this.file);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };

        try {
          const response = await api.v1.orgs.importRemittanceReceipt(
            this.currentOrgId,
            formData,
            config
          );

          if (response.status === 200 && response.data) {
            this.showSuccessMessage(response.data.totalImportedCount, response.data.updatedCount);
            this.file = null;
          }
        } catch (error) {
          await handleError(error);
        }
      },
      showSuccessMessage(totalCount, updatedCount) {
        this.$snacks.add(this.$t("remittancesStrings.importPanel.successMessage", [totalCount, updatedCount]), "success");
      },
      showErrorMessage(message) {
        this.$snacks.add(message, "error");
      }
    }
  };
</script>