const salaryPanelStrings = {
  title: "Honorar",
  tableHeaders: {
    date: "Dato",
    salaryType: "Honorartype",
    payingOrgs: "Mottakere",
    count: "@:commonStrings.count",
    salaryCost: "Enhetspris (kr)",
    amount: "@:commonStrings.amount",
    comment: "@:commonStrings.comment"
  },
  labels: {
    addButton: "@:commonStrings.add @.lower:salaryPanelStrings.title",
    removeButton: "@:commonStrings.remove",
    editButton: "@:commonStrings.edit",
    closeButton: "@:commonStrings.close",
    cancelButton: "@:commonStrings.cancel",
    insertButton: "@:commonStrings.add",
    updateButton: "@:commonStrings.update",
    insertEditDialogTitle: "Nytt @.lower:salaryPanelStrings.title",
    updateEditDialogTitle: "Endre @.lower:salaryPanelStrings.title"
  },
  placeholders: {
    count: "@:commonStrings.count",
    amount: "@:commonStrings.amount"
  },
  additionDisabled: "Flere honorarer kan ikke legges til"
};

export default salaryPanelStrings;