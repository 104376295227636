<template>
  <v-timeline-item fill-dot color="background-active">
    <template v-slot:icon>
      <v-icon tag="span" class="primary--text">mdi-message-text</v-icon>
    </template>
    <v-card :class="{'large-comment-bubble': $vuetify.breakpoint.mdAndUp, 'background-active': true}" :color="`${commentColor}-lighten-4`">
      <v-card-text class="pb-1 pt-2">
        <p class="text-h5">{{senderName}}</p>
        <p>{{comment.date | moment(`${$t("communicationPanelStrings.eventDateTimeDisplayFormat")}`)}}<br/>
          <template v-if="recipients.length">
            <em>{{ recipients }}</em><br />
          </template>
          {{ comment.content }}
        </p>
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script>
import { mapGetters } from "vuex";
import { getOrgName, getPersonName } from "@/utils/currentApplication";

export default {
  name: "LogComment",
  props: {
    comment: {
      type: Object,
      required: true
    },
    ownComment: {
      type: Boolean
    }
  },
  computed: {
    commentColor() {
      if (this.ownComment) {
        return "primary";
      }

      return "grey";
    },
    ...mapGetters({
      payingOrgs: "application/payingOrgsStore/payingOrgs"
    }),
    senderName() {
      const personName = getPersonName(this.comment.sender.personId);

      if (this.comment.sender.orgId) {
        const orgName = getOrgName(this.comment.sender.orgId);

        if (orgName) {
          return `${orgName} (${personName})`;
        }
      }

      return personName;
    },
    recipients() {
      if (this.comment.recipients.length === 0) {
        return "";
      }

      return `${this.$t(
        "communicationPanelStrings.to"
      )} ${this.comment.recipients.map(orgId => getOrgName(orgId)).join(", ")}`;
    }
  },
  methods: {
    isOwnComment(comment) {
      if (this.commentAsReceiver) {
        return comment.sender.orgId === this.currentOrgId;
      }

      return comment.sender.personId === this.currentPersonId;
    }
  }
};
</script>