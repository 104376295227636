<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col>
          <h2 class="text-h2 float-left">{{ $t("dietPanelStrings.title") }} {{ diet ? "(1)" : "" }}</h2>
          <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onHelpClick"
          >help_outline</v-icon>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row v-if="diet">
        <v-col>
          <diet-information :diet="diet" :canUseSalaryFraction="canUseSalaryFraction"></diet-information>
        </v-col>
      </v-row>
      <v-row v-if="statusAvailable">
        <v-col>
          <diet-status :from-date="diet.fromDate" :to-date="diet.toDate" :distance="diet.distance"></diet-status>
        </v-col>
      </v-row>
      <v-row v-if="showMoreInfoRequiredAlert">
        <v-col>
          <v-alert type="info">{{ $t("dietPanelStrings.moreInformationRequired") }}</v-alert>
        </v-col>
      </v-row>
      <v-row v-if="staysAvailable">
        <v-col>
          <diet-stays
            :from-date="diet.fromDate"
            :to-date="diet.toDate"
            :stays="diet.stays"
            :diet-uses-salary-fraction="diet.usesSalaryFraction"
            :paying-orgs="diet.payingOrgs"
            :distance="distanceNumber"
          ></diet-stays>
        </v-col>
      </v-row>
      <v-row v-if="staysAvailable">
        <v-col>
          <strong>
            {{ $t("dietPanelStrings.labels.total") }}:
            <amount-label :value="diet.amount"></amount-label>
          </strong>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            v-if="diet"
            rounded
            class="accent-inverted-button"
            small
            @click="$emit('remove-current-diet')"
          >{{ $t("dietPanelStrings.labels.removeButton") }}</v-btn>
          <v-btn
            v-else
            rounded
            :disabled="!enableAddNew"
            class="primary-inverted-button"
            small
            @click="$emit('add-default-diet')"
          >
            <v-icon tag="span" left>mdi-plus</v-icon>
            {{ $t("dietPanelStrings.labels.addButton") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { dietRestrictionConstants } from "@/utils/claims/diet";
import DietInformation from "./components/DietInformation";
import DietStatus from "./components/DietStatus";
import DietStays from "./components/DietStays";
import _ from "lodash";
import AmountLabel from "@/views/common/components/AmountLabel";
import claimPanel from "@/mixins/claimPanel";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import HelpBarApplicationSectionTypes from "@/enums/HelpBarApplicationSectionTypes";

export default {
  name: "DietPanel",
  components: {
    DietInformation,
    DietStays,
    DietStatus,
    AmountLabel
  },
  mixins: [claimPanel],
  watch: {
    "diet.stays": {
      deep: true,
      handler(newVal) {
        if(this.diet) {
          if (newVal?.length) {
            this.$set(this.diet, "amount", _.sum(newVal.map(x => x.amount)));
          } else {
            this.$set(this.diet, "amount", 0);
          }
        }
      }
    }
  },
  computed: {
    staysAvailable() {
      return this.hasValidDietWithDates &&
        this.diet.distance >= dietRestrictionConstants.minimalSufficientDistance;
    },
    statusAvailable() {
      return this.hasValidDietWithDates &&
        this.diet.distance >= dietRestrictionConstants.minimalSufficientDistance;
    },
    hasValidDietWithDates() {
      return this.diet?.payingOrgs &&
        this.diet.fromDate &&
        this.diet.toDate;
    },
    showMoreInfoRequiredAlert() {
      return this.diet && !this.statusAvailable;

    },
    distanceNumber() {
      if(this.diet) {
        return Number(this.diet.distance);
      }
      return 0;
    }
  },
  methods: {
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.Application, [HelpBarApplicationSectionTypes.Diet]);
    }
  },
  props: {
    diet: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    },
    canUseSalaryFraction: {
      required: true
    },
    enableAddNew:{
      required: true,
      type: Boolean
    }
  }
};
</script>