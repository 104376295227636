<template>
  <v-card>
    <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{ $t("applicationStatusPanelStrings.title")}}</h2></v-card-title>
    <v-card-text d-flex>
      <v-simple-table dense>
        <thead>
          <tr>
            <th>{{ $t("applicationStatusPanelStrings.tableHeaders.payingOrgs") }}</th>
            <th>{{ $t("applicationStatusPanelStrings.tableHeaders.status") }}</th>
            <th>{{ $t("applicationStatusPanelStrings.tableHeaders.paymentMethod") }}</th>
            <th>{{ $t("applicationStatusPanelStrings.tableHeaders.orgClaimNumber") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(status, orgId) in orgs" :key="orgId">
            <td>{{ payingOrgNames[orgId] }}</td>
            <td>{{ $t(`enums.applicationStatusTypes.${status.orgApplicationStatus}`)}}</td>
            <td>{{ getPaymentMethodLabel(status.paymentMethod)}}</td>
            <td>{{ status.orgClaimNumber }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApplicationStatusPanel",
  props: {
    orgs: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      payingOrgNames: "application/payingOrgsStore/nameLookup"
    })
  },
  methods: {
    getPaymentMethodLabel(paymentMethod) {
      const key = `enums.paymentMethods.${paymentMethod}`;

      if (this.$te(key)) {
        return this.$t(key);
      }

      return this.$t('commonStrings.notDefined');
    }
  }
};
</script>