const HelpBarContentTypes = {
    None: 0,
    Application: 1,
    PersonalSettings: 2,
    OrgApplications: 3,
    ApplicationPreview: 4,
    OrgSettings: 5,
    Applications: 6
};


Object.freeze(HelpBarContentTypes);

export default HelpBarContentTypes;