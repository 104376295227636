<template>
  <div v-if="claims && claims.length">
    <v-card-title class="py-0"><h2 class="text-h2 mt-10 mb-7">{{ $t("drivingPanelStrings.title") }}</h2></v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="claims"
        :items-per-page="-1"
        hide-default-footer
      >
        <template
          v-slot:item.date="{ item }"
        >{{item.date | moment($t("commonStrings.dateDisplayFormat"))}}</template>

        <template
          v-slot:item.claimTypeId="{ item }"
        >{{$t(`enums.vehicleTypes.${item.claimTypeId}`)}}</template>

        <template v-slot:item.amount="{ item }">{{ item.amount | toMoney}}</template>

        <template v-slot:item.passengers="{ item }">
      <passenger-tooltip :claim="item"></passenger-tooltip>
        </template>

        <template v-slot:item.payingOrgs="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <paying-orgs-label :paying-orgs="item.payingOrgs"></paying-orgs-label>
              </span>
            </template>
            <ul>
              <li v-for="(name, index) in getNames(item.payingOrgs)" :key="index">{{ name }}</li>
            </ul>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
import readonlyPanel from "@/mixins/readonlyPanel";
import PayingOrgsLabel from "@/views/applications/components/PayingOrgsLabel";
import PassengerTooltip from "@/views/applications/components/TripPassengersWithTooltip";

export default {
  name: "TripsPanel",
  components: {
    PayingOrgsLabel,
    PassengerTooltip
  },
  props: {
    claims: {
      type: Array,
      required: true
    }
  },
  mixins: [readonlyPanel],
  data() {
    return {
      headers: [
        {
          text: this.$t("drivingPanelStrings.tableHeaders.date"),
          align: "left",
          sortable: false,
          value: "date"
        },

        {
          text: this.$t("drivingPanelStrings.tableHeaders.vehicleType"),
          align: "left",
          sortable: false,
          value: "claimTypeId"
        },

        {
          text: this.$t("drivingPanelStrings.tableHeaders.payingOrgs"),
          align: "left",
          sortable: false,
          value: "payingOrgs"
        },

        {
          text: this.$t("drivingPanelStrings.tableHeaders.from"),
          align: "left",
          sortable: false,
          value: "from"
        },

        {
          text: this.$t("drivingPanelStrings.tableHeaders.to"),
          align: "left",
          sortable: false,
          value: "to"
        },

        {
          text: this.$t("drivingPanelStrings.tableHeaders.tripLength"),
          align: "right",
          sortable: false,
          value: "kilometers"
        },

        {
          text: this.$t("drivingPanelStrings.tableHeaders.passengers"),
          align: "right",
          sortable: false,
          value: "passengers"
        },

        {
          text: this.$t("drivingPanelStrings.tableHeaders.amount"),
          align: "right",
          sortable: false,
          value: "amount"
        }
      ]
    };
  }
};
</script>