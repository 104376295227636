import { api } from "@/utils/api";
import Vue from '@/main';
import { handleError } from "@/utils/errorHandler";

const currentTaxRatePeriodStore = {
    namespaced: true,
    state: {
        current: null
    },
    mutations: {
        set(state, period) {
            Vue.$set(state, "current", period);
        }
    },
    actions: {
        async fetch(context) {
            try {
                const response = await api.v1.taxRatePeriods.current();

                if (response.status === 200 && response.data) {
                    context.commit('set', response.data);
                }
            } catch (error) {
                handleError(error);
            }
        }
    },
    getters: {
        currentPeriod: state => state.current
    }
};

export default currentTaxRatePeriodStore;