<template>
  <div>
    <v-btn rounded small class="primary-inverted-button" @click="onAdd">
      <v-icon tag="span" left>mdi-plus</v-icon>
      {{ $t("commonStrings.add") }}
    </v-btn>
    <v-dialog v-model="display" max-width="500" :key="dimension.id" persistent>
      <v-card loading="isSaving">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
          <validation-observer ref="financialDimensionObserver">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    rules="required"
                    v-slot="{ errors }"
                    :name="
                      $t(
                        'financialDimensionStrings.dimensionsTable.headers.category'
                      )
                    "
                  >
                    <v-select
                      dense
                      outlined
                      v-model="dimension.categoryType"
                      :items="dimensionCategories"
                      :label="
                        $t(
                          'financialDimensionStrings.dimensionsTable.headers.category'
                        )
                      "
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    :rules="{
                      max: 5,
                      required: true,
                      is_not: usedCodesInCategory
                    }"
                    :name="
                      $t(
                        'financialDimensionStrings.dimensionsTable.headers.code'
                      )
                    "
                    v-slot="{ errors }"
                  >
                    <integer-text-field
                      v-model="dimension.code"
                      :error-messages="errors"
                      :label="
                        $t(
                          'financialDimensionStrings.dimensionsTable.headers.code'
                        )
                      "
                    ></integer-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    rules="max:50|required"
                    :name="
                      $t(
                        'financialDimensionStrings.dimensionsTable.headers.name'
                      )
                    "
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="dimension.name"
                      :error-messages="errors"
                      :label="
                        $t(
                          'financialDimensionStrings.dimensionsTable.headers.name'
                        )
                      "
                      counter="50"
                      outlined
                      dense
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="dimension.active"
                    :label="
                      $t(
                        'financialDimensionStrings.dimensionsTable.headers.active'
                      )
                    "
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="primary-inverted-button mb-2 mb-sm-0"
            @click="cancel"
            :block="$vuetify.breakpoint.xsOnly"
            small
            :disabled="isSaving"
            :loading="isSaving"
          >
            {{ $t("commonStrings.cancel") }}
          </v-btn>
          <v-btn
            rounded
            class="primary-button ml-0 ml-sm-2"
            @click="save"
            :block="$vuetify.breakpoint.xsOnly"
            small
            :disabled="isSaving"
            :loading="isSaving"
          >
            {{ $t("commonStrings.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { compare } from "fast-json-patch";
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { api, urls } from "@/utils/api";
import { copy } from "@/utils/object";
import IntegerTextField from "@/views/common/components/IntegerTextField";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "FinancialDimensionEditDialog",
  components: {
    ValidationObserver,
    ValidationProvider,
    IntegerTextField
  },
  data() {
    return {
      display: false,
      initial: {}
    };
  },
  props: {
    dimension: {
      required: false
    },
    dimensionCategories: {
      required: true
    },
    otherDimensions: {
      required: true
    }
  },
  methods: {
    onAdd() {
      this.$emit("addNew");
    },
    async save() {
      const isDataValid = await this.$refs.financialDimensionObserver.validate();

      if (!isDataValid) {
        this.showValidationErrorSnackbar(this.$refs.financialDimensionObserver);
        return;
      }

      if (this.dimension.id) {
        await this.patchExisting();
      } else {
        await this.postNew();
      }
    },
    async postNew() {
      try {
        const response = await api.v1.financialDimensions.post(this.dimension);

        if (response.status === 200) {
          this.successResponse();
        }
      } catch (error) {
        await handleError(error);
      }
    },
    async patchExisting() {
      try {
        const patchDoc = compare(this.initial, this.dimension);
        const response = await api.v1.financialDimensions.patch(
          this.dimension.id,
          patchDoc
        );
        if (response.status === 204) {
          this.successResponse();
        }
      } catch (error) {
        await handleError(error);
      }
    },
    successResponse() {
      this.$snacks.add(this.$t("financialDimensionStrings.dimensionsTable.editDialog.saved"),
        "success",
        true
      );
      this.$emit("saved");
    },
    cancel() {
      this.$emit("cancelled");
    }
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    isPosting() {
      return this.urlState(urls.v1.financialDimensions.post());
    },
    isPatching() {
      return this.urlState(
        urls.v1.financialDimensions.patch(this.dimension.id)
      );
    },
    isSaving() {
      return this.isPosting || this.isPatching;
    },
    title() {
      if (this.dimension.id <= 0) {
        return this.$t("financialDimensionStrings.dimensionsTable.editDialog.createTitle");
      }
      return this.$t("financialDimensionStrings.dimensionsTable.editDialog.editTitle");
    },
    usedCodesInCategory() {
      const dimensionsInCateogry = this.otherDimensions.filter(
        x => x.categoryType === this.dimension.categoryType
      );
      const usedCodes = dimensionsInCateogry.map(x => x.code);
      return usedCodes;
    }
  },
  watch: {
    dimension: {
      handler(val) {
        if (val.id >= 0) {
          this.display = !!val;
        } else {
          this.display = false;
        }
      },
      deep: true
    },
    display(val) {
      if (val) {
        this.initial = copy(this.dimension);
      }
    }
  }
};
</script>
