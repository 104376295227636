const tripsSummaryPanelStrings = {
  calculatedBy: "beregnet slik",
  perKm: "/@:tripsSummaryPanelStrings.kilometers",
  total: "@:summaryPanelStrings.total",
  of: "@:summaryPanelStrings.of",
  kilometers: "km",
  passenger: "@:commonStrings.passengerCount",
  salaryFractionExplanation: `({kilometers} @:tripsSummaryPanelStrings.kilometers × {parts}/{total} del av honorarbrøk) | 
  ({kilometers} @:tripsSummaryPanelStrings.kilometers × {parts}/{total} deler av honorarbrøk)`
};

export default tripsSummaryPanelStrings;