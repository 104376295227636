<template>
  <div class="statistics">
    <v-row>
      <v-col>
        <approvals-by-month-panel></approvals-by-month-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApprovalsByMonthPanel from "./components/ApprovalsByMonthPanel";

export default {
  name: "StatisticsPage",
  components: {
    ApprovalsByMonthPanel
  }
};
</script>


