<template>
  <div class="py-2 font-weight-bold">
    {{ $t("previewApplicationPageStrings.handlingCompleted", [handledOrgName]) }}
  </div>
</template>

<script>
export default {
  name: "HandlingCompletedPanel",
  props: {
    handledOrgName: String
  }
};
</script>