<template>
  <div>
    <v-dialog
      v-model="showDialog"
      max-width="500"
      :key="currentIndex"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t("remittancesStrings.historyPanel.modalTitle") }}
        </v-card-title>
        <v-card-text>
          {{ $t("remittancesStrings.historyPanel.modalText") }}
        </v-card-text>
        <v-card-actions class="pa-5 flex-column flex-sm-row">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="primary-inverted-button mb-2 mb-sm-0"
            @click="cancel"
            :block="$vuetify.breakpoint.xsOnly"
            small
          >
            {{ $t("commonStrings.no") }}
          </v-btn>
          <v-btn
            rounded
            class="primary-button ml-0 ml-sm-2"
            @click="reset"
            :block="$vuetify.breakpoint.xsOnly"
            small
          >
            {{ $t("commonStrings.yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title class="py-0"
        ><h1 class="text-h2 mt-10 mb-7">
          {{ $t("remittancesStrings.historyPanel.title") }}
        </h1></v-card-title
      >
      <v-card-text>
        <v-data-table
          item-key="orgAndApplicationId"
          :headers="headers"
          :items="remittances"
          :no-data-text="this.$t('remittancesStrings.historyPanel.noDataText')"
          :no-results-text="
            this.$t('remittancesStrings.historyPanel.noResultsText')
          "
          :loading="loading"
        >
          <template v-slot:item.resetAction="{ item }">
            <v-btn
              :disabled="!canReset(item)"
              small
              rounded
              class="primary-inverted-button my-2"
              @click="onReset(item)"
              >{{ $t("remittancesStrings.historyPanel.resetButton") }}</v-btn
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { api, urls } from "@/utils/api";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "RemittanceExportPanel",
  components: {},
  data() {
    return {
      showDialog: false,
      selectedRemittance: {},
      headers: [
        {
          text: this.$t(
            "remittancesStrings.historyPanel.headers.transmissionId"
          ),
          value: "transmissionId"
        },
        {
          text: this.$t(
            "remittancesStrings.historyPanel.headers.transactionCount"
          ),
          value: "transactionCount"
        },
        {
          text: this.$t(
            "remittancesStrings.historyPanel.headers.lastExportDate"
          ),
          value: "lastExportDate"
        },
        {
          text: this.$t(
            "remittancesStrings.historyPanel.headers.assignmentCount"
          ),
          value: "assignmentCount"
        },
        {
          text: this.$t(
            "remittancesStrings.historyPanel.headers.receiptReceivedCount"
          ),
          value: "receiptReceivedCount"
        },
        {
          text: this.$t(
            "remittancesStrings.historyPanel.headers.lastReceiptDate"
          ),
          value: "lastReceiptDate"
        },
        {
          text: this.$t("remittancesStrings.historyPanel.headers.resetAction"),
          value: "resetAction",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      currentOrgId: "contextStore/orgId",
      currentOrgs: "currentOrgsStore/orgs",
      urlState: "utilsRestStore/urlState",
      orgsWithPermissions: "currentOrgsStore/orgs",
      remittances: "application/remittanceHistoryStore/remittances"
    }),
    loading() {
      return this.urlState(urls.v1.orgs.getTransmissions(this.currentOrgId));
    },
    resetting() {
      return this.urlState(
        urls.v1.orgs.resetTransmission(
          this.selectedRemittance.dataSenderOrgId,
          this.selectedRemittance.transmissionId
        )
      );
    },
    currentIndex() {
      if (this.selectedRemittance) {
        return this.selectedRemittance.transmissionId;
      }
      return 0;
    }
  },
  methods: {
    ...mapActions({
      fetchHistory: "application/remittanceHistoryStore/fetch"
    }),
    canReset(item) {
      return !item.lastReceiptDate;
    },
    onReset(item) {
      this.showDialog = true;
      this.selectedRemittance = item;
    },
    cancel() {
      this.showDialog = false;
      this.selectedRemittance = {};
    },
    async reset() {
      try {
        const response = await api.v1.orgs.resetTransmission(
          this.selectedRemittance.dataSenderOrgId,
          this.selectedRemittance.transmissionId
        );

        if (response.status === 200) {
          this.$snacks.add(this.$t("remittancesStrings.historyPanel.successMessage"),
            "success",
            true
          );
          await this.fetchHistory(this.currentOrgId);
          this.showDialog = false;
          this.selectedRemittance = {};
        }
      } catch (error) {
        await handleError(error);
        this.showDialog = false;
        this.selectedRemittance = {};
      }
    }
  }
};
</script>