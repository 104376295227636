import { api } from "@/utils/api";
import { handleError } from "@/utils/errorHandler";

const buypassOrgsStore = {
  namespaced: true,
  state: {
    orgs: [],
    loaded: false
  },
  mutations: {
    setBuypassOrgs(state, orgs) {
      state.orgs = orgs;
      state.loaded = true;
    }
  },
  actions: {
    async fetch(context, payload) {
      if (payload.personId && payload.buypassId) {
        try {
          const merchantResponse = await api.v1.persons.getMerchantsForBuypassAdministrator(
            payload.personId,
            payload.buypassId
          );
          if (merchantResponse && merchantResponse.status === 200 && merchantResponse.data) {
            context.commit('setBuypassOrgs', merchantResponse.data);
          }
        } catch (error) {
          await handleError(error);
        }
      }
    }
  },
  getters: {
    orgs: state => state.orgs,
    loaded: state => state.loaded
  }
};

export default buypassOrgsStore;