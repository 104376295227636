const remittancesStrings = {
  name: "Remittering",
  pageTitle: "@:remittancesStrings.name",
  notEnabled: "Remittering må først konfigureres, påkrevd informasjon kan registreres i ",
  noAccess: "Du har ikke tilgang til å eksportere remitteringsfil for aktivt organisasjonsledd.",
  importPanel: {
    title: "Importere konteringsdata",
    successMessage: "{0} krav importert, {1} betalinger oppdatert",
    fileEmptyError: "Kan ikke laste opp tom fil",
    fileTooLargeError: "Filen er for stor for opplasting, max {0} MB",
    labels: {
      fileLabel: "Velg fil",
      uploadButton: "Last opp"
    }
  },
  historyPanel: {
    title: "Historikk",
    noDataText: "@:orgApplicationsStrings.applicationsTableNoDataText",
    noResultsText: "@:orgApplicationsStrings.applicationsTableNoResultsText",
    resetButton: "@:commonStrings.clear",
    modalTitle: "Bekreft",
    modalText: "Vil du nullstille denne filen slik at kravene er klare for å eksporteres på nytt?",
    successMessage: "Filen ble nullstilt",
    headers: {
      transmissionId: "Forsendelses-ID",
      transactionCount: "Antall transaksjoner",
      receiptReceivedCount: "Antall konteringsdata",
      assignmentCount: "Antall oppdrag",
      lastExportDate: "Eksportdato",
      lastReceiptDate: "Importdato konteringsdata",
      resetAction: "@:commonStrings.clear"
    }
  },
  exportPanel: {
    title: "Eksportere remitteringsfil",
    resultsSubheader: "Søkeresultater ({0})",
    notFoundWarning: "Filen mangler innhold",
    noDataText: "@:orgApplicationsStrings.applicationsTableNoDataText",
    noResultsText: "@:orgApplicationsStrings.applicationsTableNoResultsText",
    headers: {
      applicationDescription: "Kravet gjelder",
      sender: "Avsender",
      receiver: "Mottaker"
    },
    labels: {
      receiver: "Filtrer på mottaker",
      paymentDate: "Utbetalingsdato",
      clearFiltersButton: "@:commonStrings.clearFilters",
      exportCheckbox: "Velg",
      exportButton: "Hent remitteringsfil"
    }
  },
  detailsModal: {
    link: "Remitteringsdetaljer",
    title: "Remitteringsdetaljer",
    transmissionId: "@:remittancesStrings.historyPanel.headers.transmissionId",
    assignmentId: "Oppdragsnr",
    amount: "Beløp",
    createdDate: "@:remittancesStrings.historyPanel.headers.lastExportDate",
    receiptReceivedDate: "@:remittancesStrings.historyPanel.headers.lastReceiptDate",
    notFound: "Remitteringsfil ser ikke ut til å være eksportert"
  }
};

export default remittancesStrings;