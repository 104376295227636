<template>
  <menu-block>
    <menu-router-link to="/personalsettings">
      {{ $t("menuStrings.personalSettings") }}
    </menu-router-link>
    <menu-header v-if="hasBackofficeAccess || hasOrgApplicationAccess || hasRemittanceAccess" icon="mdi-account-group">
      {{$t("menuStrings.orgGroupHeader")}}
    </menu-header>
    <menu-router-link v-if="hasOrgApplicationAccess" to="/orgapplications">
      {{ $t("menuStrings.orgApplications") }}
    </menu-router-link>
    <menu-router-link v-if="hasRemittanceAccess" to="/remittances">
      {{ $t("menuStrings.remittances") }}
    </menu-router-link>
    <menu-router-link v-if="hasOrgApplicationAccess" to="/senderInformation">
      {{ $t("menuStrings.senderInformation") }}
    </menu-router-link>
    <menu-router-link v-if="hasBackofficeAccess" to="/backoffice">
      {{ $t("menuStrings.backoffice") }}
    </menu-router-link>
    <menu-header v-if="isSuperuser" icon="mdi-cog">
      {{$t("menuStrings.superuserGroupHeader")}}
    </menu-header>
    <menu-router-link v-if="isSuperuser" to="/statistics">
      {{ $t("menuStrings.statistics") }}
    </menu-router-link>
    <menu-router-link v-if="isSuperuser" to="/orgFeesInvoice">
      {{ $t("menuStrings.orgFeesInvoice") }}
    </menu-router-link>
    <menu-router-link v-if="isSuperuser" to="/messages">
      {{ $t("menuStrings.infoMessages") }}
    </menu-router-link>
  </menu-block>
</template>

<script>
import { remittanceOrgIds } from "@/utils/featureFlags";
import OrgTypes from "@/enums/OrgTypes";
import { mapGetters } from "vuex";
import MenuBlock from "@/views/common/components/MenuBlock";
import MenuHeader from "@/views/common/components/MenuHeader";
import MenuRouterLink from "@/views/common/components/MenuRouterLink";

export default {
  name: "AppMenu",
  components: {
    MenuBlock,
    MenuHeader,
    MenuRouterLink
  },
  props: {
    currentOrg: {
      validator: prop => typeof prop === "object" || prop === null,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isSuperuser: "currentOrgsStore/superuser"
    }),
    hasOrgApplicationAccess() {
      return this.currentOrg?.orgApplicationEnabled;
    },
    hasBackofficeAccess() {
      return this.currentOrg?.backofficeEnabled;
    },
    hasPermittedOrgTypeForRemittance() {
      return this.currentOrg?.org?.orgTypeId !== OrgTypes.GR && this.currentOrg?.org?.orgTypeId !== OrgTypes.AI;
    },
    hasRemittanceAccess() {
      return this.currentOrg?.payoutEnabled && this.hasPermittedOrgTypeForRemittance && this.areRemittanceSettingsVisible;
    },
    areRemittanceSettingsVisible() {
      return (
        !remittanceOrgIds() ||
        !remittanceOrgIds().length ||
        remittanceOrgIds().includes(this.currentOrg?.org?.id)
      );
    }
  }
};
</script>