import SalaryTypes from "@/enums/SalaryTypes";
import ReceiptTypes from "@/enums/ReceiptTypes";
import VehicleTypes from "@/enums/VehicleTypes";
import DietStayTypes from "@/enums/DietStayTypes";
import DietLengthTypes from "@/enums/DietLengthTypes";
import DietDurationTypes from "@/enums/DietDurationTypes";
import DietDeductionTypes from "@/enums/DietDeductionTypes";
import ApplicationStatusTypes from "@/enums/ApplicationStatusTypes";
import LogEventTypes from "@/enums/LogEventTypes";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import PersonalEmailTypes from "@/enums/PersonalEmailTypes";
import OrgEmailTypes from "@/enums/OrgEmailTypes";
import PaymentMethods from "@/enums/PaymentMethods";
import Products from "@/enums/Products";
import InfoMessageTypes from "@/enums/InfoMessageTypes";
import InfoMessageRecipientTypes from "@/enums/InfoMessageRecipientTypes";
import FinancialDimensionCategoryTypes from "@/enums/FinancialDimensionCategoryTypes";

const enums = {
  helpBarContentTypes: {
    [HelpBarContentTypes.None]: "",
    [HelpBarContentTypes.Application]: "@:applicationsStrings.name",
    [HelpBarContentTypes.PersonalSettings]: "@:personalSettingsStrings.name",
    [HelpBarContentTypes.OrgApplications]: "@:orgApplicationsStrings.name",
    [HelpBarContentTypes.ApplicationPreview]: "@:previewApplicationPageStrings.name",
    [HelpBarContentTypes.OrgSettings]: "@:backofficeStrings.name",
    [HelpBarContentTypes.Applications]: "@:applicationsStrings.name"
  },
  paymentMethods: {
    [PaymentMethods.Manual]: "Manuell utbetaling",
    [PaymentMethods.Buypass]: "Buypass",
    [PaymentMethods.Remittance]: "Remitteringsfil"
  },
  receiptTypes: {
    [ReceiptTypes.Parking]: "Parkering",
    [ReceiptTypes.Toll]: "Bompassering",
    [ReceiptTypes.Meal]: "Måltid",
    [ReceiptTypes.Phone]: "Telefon",
    [ReceiptTypes.Internet]: "Internett",
    [ReceiptTypes.DryCleaning]: "Rens og vask",
    [ReceiptTypes.Custom]: "Andre utlegg",
    [ReceiptTypes.Stay]: "Overnatting",
    [ReceiptTypes.Flight]: "Flybilletter",
    [ReceiptTypes.OtherTravel]: "Andre reisekostnader",
    [ReceiptTypes.Fuel]: "Drivstoff",
    [ReceiptTypes.GiftsAndPrizes]: "Gaver, premier",
    [ReceiptTypes.HealthAndMedical]: "Helse og medisinsk utstyr",
    [ReceiptTypes.SportsEquipment]: "Idrettsutstyr",
    [ReceiptTypes.Communication]: "Bredbånd/telekommunikasjon",
    [ReceiptTypes.OfficeSupplies]: "Kontorrekvisita",
    [ReceiptTypes.Subscriptions]: "Abonnementer/avis/tidsskrift",
    [ReceiptTypes.ComputerSupplies]: "Datautstyr/programmer",
    [ReceiptTypes.MembershipFees]: "Medlemskontingenter",
    [ReceiptTypes.EntryFees]: "Påmelding serier, turneringer etc",
    [ReceiptTypes.Education]: "Møte, kurs, oppdateringer etc",
    [ReceiptTypes.undefined]: "Ikke valgt"
  },
  salaryTypes: {
    [SalaryTypes.Coach]: "Trenerhonorar",
    [SalaryTypes.Instructor]: "Instruktørhonorar",
    [SalaryTypes.TalentDeveloper]: "Utøverutviklingshonorar",
    [SalaryTypes.CourseInstructor]: "Kursinstruktør",
    [SalaryTypes.Functionary]: "Funksjonær",
    [SalaryTypes.Referee]: "Dommerhonorar",
    [SalaryTypes.Custom]: "Fritekst",
    [SalaryTypes.Default]: "Honorar",
    [SalaryTypes.undefined]: "Ikke valgt"
  },
  vehicleTypes: {
    [VehicleTypes.Car]: "Bil",
    [VehicleTypes.ElectricalCar]: "EL-bil",
    [VehicleTypes.Snowmobile]: "Snøscooter/ATV",
    [VehicleTypes.BoatWithEngine]: "Båt m/motor",
    [VehicleTypes.MotorbikeAbove125]: "Motorsykkel (over 125 ccm)",
    [VehicleTypes.MotorbikeBelow125]: "Motorsykkel (tom 125 ccm)"
  },
  dietLengthTypes: {
    [DietLengthTypes.SixToTwelveHours]: "6-12 timer",
    [DietLengthTypes.Above12Hours]: "Over 12 timer"
  },
  dietDurationTypes: {
    [DietDurationTypes.NotEligible]: "Ingen diett",
    [DietDurationTypes.SixToTwelveHours]: "6-12 timer",
    [DietDurationTypes.Above12Hours]: "Over 12 timer",
    [DietDurationTypes.WithOvernight]: "Med overnatting"
  },
  dietStayTypes: {
    [DietStayTypes.Hotel]: "Hotell",
    [DietStayTypes.DormOrBarracksWithCooking]: "Hybel/Brakke (med kokemuligheter)",
    [DietStayTypes.BoardingHouseWithoutCooking]: "Pensjonat (uten kokemuligheter)",
    [DietStayTypes.NightAddition]: "Nattillegg (privat overnatting)"
  },
  dietDeductionTypes: {
    [DietDeductionTypes.Breakfast]: "Frokost",
    [DietDeductionTypes.Lunch]: "Lunsj",
    [DietDeductionTypes.Dinner]: "Middag"
  },
  applicationStatusTypes: {
    [ApplicationStatusTypes.Saved]: "Kladd lagret",
    [ApplicationStatusTypes.Pending]: "Til godkjenning",
    [ApplicationStatusTypes.Returned]: "Sendt i retur",
    [ApplicationStatusTypes.Approved]: "Godkjent",
    [ApplicationStatusTypes.Paid]: "Betalt"
  },
  logEventTypeStrings: {
    [LogEventTypes.ApplicationCreated]: "Krav opprettet",
    [LogEventTypes.ApplicationSendForApproval]: "Krav sendt til godkjenning",
    [LogEventTypes.ApplicationRejectedByOrg]: "Sendt i retur av {0}",
    [LogEventTypes.ApplicationApprovedByOrg]: "Godkjent av {0}",
    [LogEventTypes.ApplicationApprovedForPayment]: "Godkjent av alle mottakere",
    [LogEventTypes.ApplicationPaidByOrg]: "Utbetalt av {0}",
    [LogEventTypes.ApplicationPaymentReset]: "Utbetaling nullstilt av {0}"
  },
  personalEmailTypes: {
    [PersonalEmailTypes.ApplicationApproved]: "Et av dine krav ble godkjent av en mottaker",
    [PersonalEmailTypes.ApplicationRejected]: "Et av dine krav ble avvist og returnert av en mottaker",
    [PersonalEmailTypes.ApplicationPaid]: "Et av dine krav ble utbetalt av en mottaker",
    [PersonalEmailTypes.NewComment]: "En ny kommentar ble lagt til på et av dine krav"
  },
  orgEmailTypes: {
    [OrgEmailTypes.PendingApproval]: "{0} har fått et nytt krav til godkjenning",
    [OrgEmailTypes.PendingPayment]: "Et krav til {0} har blitt godkjent av alle mottakere slik at det er klart til utbetaling",
    [OrgEmailTypes.OtherOrgRejected]: "En annen organisasjon har avvist et krav der {0} også er mottaker",
    [OrgEmailTypes.NewComment]: "En ny kommentar ble lagt til på et krav til {0}"
  },
  productNames: {
    [Products.PayableAccount]: "Leverandørgjeld",
    [Products.BuypassAccount]: "Buypasskonto",
    [Products.BankAccount]: "Bankinnskudd"
  },
  infoMessageTypes: {
    [InfoMessageTypes.Unknown]: "Ukjent",
    [InfoMessageTypes.Info]: "Info",
    [InfoMessageTypes.Warning]: "Advarsel",
    [InfoMessageTypes.Error]: "Error",
    [InfoMessageTypes.Success]: "Suksess"
  },
  infoMessageRecipientTypes: {
    [InfoMessageRecipientTypes.All]: "Alle",
    [InfoMessageRecipientTypes.Approver]: "Mottakere"
  },
  financialDimensionCategoryTypes: {
    [FinancialDimensionCategoryTypes.Project]: "Prosjekt",
    [FinancialDimensionCategoryTypes.Department]: "Avdeling",
    [FinancialDimensionCategoryTypes.Custom1]: "Valgfri kategori 1",
    [FinancialDimensionCategoryTypes.Custom2]: "Valgfri kategori 2"
  }
};

export default enums;