<template>
  <v-row>
    <v-col cols="12">
      <h2 class="text-h2 mt-10 mb-7">
        {{ $t("financialDimensionStrings.categoryTitle") }}
      </h2>
      <h3 class="text-h3 mt-8 mb-5">
        {{ $t("financialDimensionStrings.fixedCategoryTitle") }}
      </h3>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field
            dense
            outlined
            readonly
            disabled
            v-model="project"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <v-text-field
            dense
            outlined
            readonly
            disabled
            v-model="department"
          ></v-text-field>
        </v-col>
      </v-row>
      <h3 class="text-h3 mt-8 mb-5">
        {{ $t("financialDimensionStrings.customCategoryTitle") }}
      </h3>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            outlined
            v-model="customCategory1"
            :label="customLabel1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            dense
            outlined
            v-model="customCategory2"
            :label="customLabel2"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import FinancialDimensionCategoryTypes from "@/enums/FinancialDimensionCategoryTypes";

export default {
  name: "FinancialDimensionCategories",
  components: {},
  data() {
    return {
      project: this.$t(
        `enums.financialDimensionCategoryTypes.${FinancialDimensionCategoryTypes.Project}`
      ),
      department: this.$t(
        `enums.financialDimensionCategoryTypes.${FinancialDimensionCategoryTypes.Department}`
      ),
      customLabel1: this.$t(
        `enums.financialDimensionCategoryTypes.${FinancialDimensionCategoryTypes.Custom1}`
      ),
      customLabel2: this.$t(
        `enums.financialDimensionCategoryTypes.${FinancialDimensionCategoryTypes.Custom2}`
      ),
      customCategory1: "",
      customCategory2: ""
    };
  },
  props: {
    orgConfiguration: {
      required: true,
      validator: prop => typeof prop === "object" || !prop
    }
  },
  methods: {
    initCategoryValue(categoryType) {
      const i = this.orgConfiguration.financialDimensionCategories?.findIndex(
        x => x.categoryType === categoryType
      );

      if (i > -1) {
        return this.orgConfiguration.financialDimensionCategories[i]
          .categoryName;
      }

      return "";
    },
    updateOrAddCategory(val, categoryType) {
      const i = this.orgConfiguration.financialDimensionCategories?.findIndex(
        x => x.categoryType === categoryType
      );
      if (i > -1) {
        this.orgConfiguration.financialDimensionCategories[
          i
        ].categoryName = val;
      } else {
        this.orgConfiguration.financialDimensionCategories.push({
          categoryType: categoryType,
          categoryName: val
        });
      }
    }
  },
  mounted() {
    this.customCategory1 = this.initCategoryValue(
      FinancialDimensionCategoryTypes.Custom1
    );
    this.customCategory2 = this.initCategoryValue(
      FinancialDimensionCategoryTypes.Custom2
    );
  },
  watch: {
    customCategory1(val) {
      this.updateOrAddCategory(val, FinancialDimensionCategoryTypes.Custom1);
    },
    customCategory2(val) {
      this.updateOrAddCategory(val, FinancialDimensionCategoryTypes.Custom2);
    }
  }
};
</script>