const orgApplicationsStrings = {
  name: "Behandle krav",
  pageTitle: "@:orgApplicationsStrings.name",
  pageHeader: "@:orgApplicationsStrings.name for {0}",
  resultsSubheader: "Søkeresultater ({0})",
  applicationsTableNoDataText: "Ingen krav ble funnet",
  applicationsTableNoResultsText: "Ingen krav oppfyller søkekriteriene",
  createdDateTableHeader: "Dato",
  applicationNameTableHeader: "Kravet gjelder",
  recipientsTableHeader: "@:commonStrings.recipients",
  statusTableHeader: "Status",
  senderNameTableHeader: "@:commonStrings.sender",
  amountTableHeader: "@:commonStrings.amount",
  applicationIdTableHeader: "ID",
  paymentSentTableHeader: "Sendt til betaling",
  paymentMethodTableHeader: "Betalingsmåte",
  labels: {
    paymentButtonSendForPayment: "Merk som utbetalt"
  },
  orgFilterLabel: "Vis krav for",
  paymentSentSuccessMessage: "Kravet er sendt til betaling",
  fromDateFilterLabel: "Fra @.lower:orgApplicationsStrings.createdDateTableHeader",
  toDateFilterLabel: "Til @.lower:orgApplicationsStrings.createdDateTableHeader",
  amountFilterLabel: "@:orgApplicationsStrings.amountTableHeader",
  senderNameFilterLabel: "@:orgApplicationsStrings.senderNameTableHeader",
  applicationNameFilterLabel: "@:orgApplicationsStrings.applicationNameTableHeader",
  applicationIdFilterLabel: "@:orgApplicationsStrings.applicationIdTableHeader",
  paymentSentFilterLabel: "@:orgApplicationsStrings.paymentSentTableHeader",
  paymentSentFilterOptionYes: "@:commonStrings.yes",
  paymentSentFilterOptionNo: "@:commonStrings.no",
  recipientsFilterLabel: "@:(orgApplicationsStrings.orgFilterLabel):",
  applicationStatusFilterLabel: "Filtrer på @.lower:orgApplicationsStrings.statusTableHeader:",
  recipientsFilterOptionChildOrgs: "@:commonStrings.childOrgs",
  clearFiltersBtn: "@:commonStrings.clearFilters",
  paymentSentUpdatedAlert: "'@:orgApplicationsStrings.paymentSentCheckboxLabel' ble oppdatert",
  childOrgs: "{0} underliggende orgledd",
  exportButton: "Eksporter valgte til CSV",
  exportCheckbox: "Velg til eksport",
  exportInfo: "Kun godkjente krav med besluttet betalingsmåte kan eksporteres",
  exportInfoModalStrings: {
    title: "CSV-filen blir lastet ned",
    infoP1: "Vær oppmerksom på hvilke datatyper som blir brukt om du åpner CSV-filen i programmer som Excel eller lignende.",
    infoP2: `Excel kan automatisk transformere informasjonen når du åpner CSV-filer, som for eksempel kan føre til at nummer vises uten ledende 0,
     eller at datoer og beløp ikke gjenkjennes i forventet format. Dette avhenger av innstillingene på din PC, og er noe som ikke kan styres i en CSV-fil.`,
    infoP3: `Om du mistenker at dataene er feil, åpne CSV-filen i et program som ikke automatisk transformerer informasjonen,
     for eksempel i Notepad om du bruker Windows, er informasjonen riktig i Notepad kan du antageligvis løse problemet ved å velge riktig datatype når du importerer.`
  },
  childOrgSearch: {
    autocompleteLabel: "@:commonStrings.selectOrg",
    autocompletePlaceholder: "@:commonStrings.orgAutocompleteHint",
    noDataText: "@:commonStrings.noOrgsFound",
    childOrgButtonText: "@:commonStrings.childOrgs",
    selectedOrgText: "Viser krav for ",
    buttonGroupLabel: "@:(orgApplicationsStrings.orgFilterLabel):"
  }
};

export default orgApplicationsStrings;