<template>
  <v-row v-if="canEditProductAccounts">
    <v-col cols="12">
      <h2 class="text-h2 mt-10 mb-7">
        {{ $t("productListSettingsStrings.title") }}
      </h2>

      <em> {{ $t("productListSettingsStrings.helpText") }}</em>
      <v-row>
        <v-col cols="12" lg="8" xl="6">
          <v-simple-table>
            <thead>
              <tr>
                <th>
                  {{
                    $t("productListSettingsStrings.tableHeaders.product")
                  }}
                </th>
                <th>
                  {{
                    $t("productListSettingsStrings.tableHeaders.account")
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(productAccount,
                index) in productAccounts"
                :key="index"
              >
                <td>
                  {{ $t(`enums.productNames.${productAccount.productId}`) }}
                </td>
                <td>
                  <validation-provider
                    rules="finance_account"
                    :name="
                      $t('rateConfigurationStrings.salaryAccountingHeader')
                    "
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="productAccount.accountingId"
                      :error-messages="errors"
                      outlined
                      dense
                    ></v-text-field>
                  </validation-provider>

                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import OrgTypes from "@/enums/OrgTypes";

export default {
  name: "ProductAccountSettings",
  components: {
      ValidationProvider
  },
  props: {
    productAccounts: {
      required: true,
      type: Array
    },
    orgTypeId: Number
  },
  computed: {
    canEditProductAccounts() {
      return this.orgTypeId !== OrgTypes.GR && this.orgTypeId !== OrgTypes.AI;
    }
  }
};
</script>