const editApplicationStrings = {
  name: "@:commonStrings.applicationDetails",
  createPageTitle: "@:editApplicationStrings.name (nytt krav)",
  detailsPageTitle: "@:editApplicationStrings.name",
  pageHeader: "@:editApplicationStrings.name",
  applicationNameTextField: "Hva gjelder dette kravet?",
  applicationNameTextFieldPlaceholder: "Beskrivelse",
  applicationNameTextValidationName: "Beskrivelse",
  accountNumberTextField: "Kontonummer (må være en konto som tilhører deg selv)",
  accountNumberTextFieldValidationName: "Kontonummer",
  saveBtn: "Lagre som kladd",
  sendBtn: "Send",
  saveAlerts: {
    saved: "Kravet ble lagret",
    approve: "Kravet ble godkjent",
    reject: "Kravet ble avvist",
    pay: "Kravet ble merket som utbetalt",
    payBuypass: "Kravet ble utbetalt",
    reclaimed: "Innsending angret, kravet er igjen lagret som kladd",
    signPayment: "Kravet ble signert",
    setPaymentMethod: "Utbetalingsmåte ble lagret",
    resetPaymentMethod: "Utbetalingsmåte og signaturer ble tilbakestilt"
  },
  idLabel: "ID",
  applicationInformationTitle: "Informasjon om kravet",
  noClaimsError: "Minst ett delkrav må legges inn",
  noClaimAmountError: "Totalbeløp for kravet må være større enn 0",
  dietWithoutAmount: "Totalbeløp for diett må være større enn 0"
};

export default editApplicationStrings;