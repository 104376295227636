<template>
  <div class="remittance">
    <template v-if="isRemittanceEnabled">
      <v-tabs v-model="activeTab" centered show-arrows>
        <v-tab href="#exportTab" @change="$refs.exportPanel.fetch()">
          {{ $t("remittancesStrings.exportPanel.title") }}
        </v-tab>
        <v-tab href="#importTab">
          {{ $t("remittancesStrings.importPanel.title") }}
        </v-tab>
        <v-tab href="#historyTab" @change="fetchHistory(currentOrgId)">
          {{ $t("remittancesStrings.historyPanel.title") }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item value="exportTab">
          <remittance-export-panel ref="exportPanel"></remittance-export-panel>
        </v-tab-item>
        <v-tab-item value="importTab">
          <remittance-import-panel></remittance-import-panel>
        </v-tab-item>
        <v-tab-item value="historyTab"
          ><remittance-history-panel
            ref="historyPanel"
          ></remittance-history-panel
        ></v-tab-item>
      </v-tabs-items>
    </template>
    <v-card v-else>
      <v-card-text>
        <template v-if="!hasRemittanceAccess">
          {{ $t("remittancesStrings.noAccess") }}
        </template>
        <template v-else-if="backofficeEnabled">
          {{ $t("remittancesStrings.notEnabled") }}
          <inline-router-link to="/backoffice"> {{ $t("backofficeStrings.pageTitle") }}</inline-router-link>
        </template>
        <template v-else>
          {{ $t("remittancesStrings.notEnabled") }}
          {{ $t("backofficeStrings.pageTitle") }}
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RemittanceExportPanel from "./RemittanceExportPanel.vue";
import RemittanceImportPanel from "./RemittanceImportPanel.vue";
import RemittanceHistoryPanel from "./RemittanceHistoryPanel.vue";
import { remittanceOrgIds } from "@/utils/featureFlags";
import { mapGetters, mapActions } from "vuex";
import OrgTypes from "@/enums/OrgTypes";
import InlineRouterLink from "@/views/common/components/InlineRouterLink";

export default {
  name: "RemittancePage",
  components: {
    RemittanceExportPanel,
    RemittanceImportPanel,
    RemittanceHistoryPanel,
    InlineRouterLink
  },
  data() {
    return {
      activeTab: "exportTab",
      orgConfiguration: {}
    };
  },
  computed: {
    ...mapGetters({
      stateInitiated: "contextStore/initiated",
      currentOrgId: "contextStore/orgId",
      orgsWithPermissions: "currentOrgsStore/orgs"
    }),
    currentOrg() {
      return this.orgsWithPermissions.find(x => x.org.id === this.currentOrgId);
    },
    backofficeEnabled() {
      return this.currentOrg?.backofficeEnabled;
    },
    isRemittanceEnabled() {
      return (
        this.orgConfiguration?.remittanceSettings?.isActive &&
        this.hasRemittanceAccess
      );
    },
    hasRemittanceAccess() {
      return (
        this.currentOrg?.payoutEnabled &&
        this.hasPermittedOrgTypeForRemittance &&
        this.areRemittanceSettingsVisible
      );
    },
    hasPermittedOrgTypeForRemittance() {
      return (
        this.currentOrg?.org?.orgTypeId !== OrgTypes.GR &&
        this.currentOrg?.org?.orgTypeId !== OrgTypes.AI
      );
    },
    areRemittanceSettingsVisible() {
      return (
        !remittanceOrgIds() ||
        !remittanceOrgIds().length ||
        remittanceOrgIds().includes(this.currentOrg?.org?.id)
      );
    }
  },
  methods: {
    ...mapActions({
      getOrgConfiguration: "settings/orgConfigurationStore/getOrgConfiguration",
      fetchHistory: "application/remittanceHistoryStore/fetch"
    })
  },
  watch: {
    stateInitiated: {
      handler: async function (val) {
        if (val) {
          this.orgConfiguration = await this.getOrgConfiguration(
            this.currentOrgId
          );
        }
      },
      immediate: true
    }
  }
};
</script>