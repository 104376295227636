<template>
  <div>
    <div class="py-2 font-weight-bold">
      {{ $t("previewApplicationPageStrings.paymentSignaturePanel.nextActionPaymentSignature") }}
    </div>
    <div class="py-2">
      <span v-if="userHasAlreadySigned">
        {{ $t("previewApplicationPageStrings.paymentSignaturePanel.youHaveAlreadySignedPayment", [handledOrgName]) }}
      </span>
      <span v-else-if="userCanSign">
        {{ $t("previewApplicationPageStrings.paymentSignaturePanel.youCanSignPayment", [handledOrgName]) }}
      </span>
      <span v-else>
        {{ $t("previewApplicationPageStrings.paymentSignaturePanel.youCannotSignPayment", [handledOrgName])}}
        <permissions-modal
          :link-text="$t('previewApplicationPageStrings.linkToRequiredPermissions')"
          :title="$t('previewApplicationPageStrings.paymentSignaturePanel.permissionsModalTitle')"
          :end-text="$t('previewApplicationPageStrings.paymentSignaturePanel.permissionsModalEndText')"
          :function-type-names="permittedFunctionTypeNames">
        </permissions-modal>
      </span>
    </div>
    <div class="py-2">
        {{ $t("previewApplicationPageStrings.paymentSignaturePanel.remainingSignaturesRequired", [signatureCount, requiredSignatureCount]) }}
    </div>
    <div class="py-2">
      <span v-if="isApprovedByAll">
        {{ $t("previewApplicationPageStrings.allOrgsHaveApproved")}}
      </span>
      <span v-else>
        {{ $t("previewApplicationPageStrings.allOrgsHaveNotApproved", [approvedOrgsCount, totalOrgsCount])}}
      </span>
    </div>
      <template v-if="isApprovedByAll && (userCanReset || (userCanSign && !userHasAlreadySigned))">
        <div class="py-2">
          <v-btn v-if="userCanReset"
            rounded
            class="primary-inverted-button mr-0 mr-sm-2 mt-2 mt-sm-0"
            @click="$emit('payment-reset', handledOrgId)"
            :disabled="isSigning || isResetting || isTypingResponse"
            :loading="isResetting"
            :block="$vuetify.breakpoint.xsOnly"
          >{{ $t("previewApplicationPageStrings.resetPaymentButton") }}</v-btn>
          <v-btn v-if="userCanSign && !userHasAlreadySigned"
            rounded
            class="primary-button mr-0 mr-sm-2 mt-2 mt-sm-0"
            @click="$emit('payment-signed')"
            :disabled="isSigning || isResetting || isTypingResponse"
            :loading="isSigning"
            :block="$vuetify.breakpoint.xsOnly"
          >{{ $t("previewApplicationPageStrings.paymentSignaturePanel.signButton") }}</v-btn>
        </div>
      </template>
  </div>
</template>

<script>
import { urls } from "@/utils/api";
import { mapGetters } from "vuex";
import PermissionsModal from "./PermissionsModal";
import { paymentSignaturePermittedFunctionTypes } from "@/utils/permissions";

export default {
  name: "PaymentSignaturePanel",
  components: { PermissionsModal },
  props: {
    handledOrgId: Number,
    handledOrgName: String,
    userCanSign: Boolean,
    userHasAlreadySigned: Boolean,
    signatureCount: Number,
    requiredSignatureCount: Number,
    approvedOrgsCount: Number,
    totalOrgsCount: Number,
    isApprovedByAll: Boolean,
    userCanReset: Boolean,
    isTypingResponse: Boolean
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    isSigning() {
      if (this.$route.params.id) {
        return this.urlState(urls.v1.applicationOrgs.signPayment(this.$route.params.id, this.handledOrgId));
      }
      return false;
    },
    isResetting() {
      if (this.$route.params.id) {
        return this.urlState(urls.v1.applicationOrgs.resetPaymentMethod(this.$route.params.id, this.handledOrgId));
      }
      return false;
    },
    permittedFunctionTypeNames() {
      return paymentSignaturePermittedFunctionTypes().map(x => x.name);
    }
  }
};
</script>