<template>
  <div>
    <v-btn rounded small class="primary-inverted-button" @click="onAdd">
      <v-icon tag="span" left>mdi-plus</v-icon>
      {{ $t("commonStrings.add") }}
    </v-btn>
    <v-dialog
      v-model="display"
      max-width="500"
      :key="message.infoMessageId"
      persistent
    >
      <v-card loading="isSaving">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
          <validation-observer ref="infoMessageObserver">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    rules="max:200"
                    :name="$t('infoMessagesStrings.headers.title')"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="message.title"
                      :error-messages="errors"
                      :label="$t('infoMessagesStrings.headers.title')"
                      counter="200"
                      outlined
                      dense
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    rules="max:4000|required"
                    :name="$t('infoMessagesStrings.headers.message')"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      v-model="message.messageText"
                      :error-messages="errors"
                      :label="$t('infoMessagesStrings.headers.message')"
                      counter="4000"
                      outlined
                      dense
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <text-field-date-picker
                    v-model="message.displayFromDate"
                    :label="$t('infoMessagesStrings.headers.fromDate')"
                  ></text-field-date-picker>
                </v-col>
                <v-col cols="12" sm="6">
                  <text-field-date-picker
                    v-model="message.displayToDate"
                    :label="$t('infoMessagesStrings.headers.toDate')"
                  ></text-field-date-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    dense
                    outlined
                    v-model="message.infoMessageType"
                    :items="getInfoMessageTypeOptions()"
                    :label="$t('infoMessagesStrings.headers.type')"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    dense
                    outlined
                    v-model="message.recipientType"
                    :items="getInfoMessageRecipientTypeOptions()"
                    :label="$t('infoMessagesStrings.headers.recipient')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    rules="max:300"
                    :name="$t('infoMessagesStrings.headers.url')"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="message.readMoreUrl"
                      :error-messages="errors"
                      :label="$t('infoMessagesStrings.headers.url')"
                      counter="300"
                      outlined
                      dense
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    dense
                    outlined
                    v-model="message.displayOnPage"
                    :items="getRouteOptions()"
                    :label="$t('infoMessagesStrings.headers.displayOnPage')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="primary-inverted-button mb-2 mb-sm-0"
            @click="cancel"
            :block="$vuetify.breakpoint.xsOnly"
            small
            :disabled="isSaving"
            :loading="isSaving"
          >
            {{ $t("commonStrings.cancel") }}
          </v-btn>
          <v-btn
            rounded
            class="primary-button ml-0 ml-sm-2"
            @click="save"
            :block="$vuetify.breakpoint.xsOnly"
            small
            :disabled="isSaving"
            :loading="isSaving"
          >
            {{ $t("commonStrings.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { compare } from "fast-json-patch";
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextFieldDatePicker from "@/views/common/components/TextFieldDatePicker";
import InfoMessageTypes from "@/enums/InfoMessageTypes";
import InfoMessageRecipientTypes from "@/enums/InfoMessageRecipientTypes";
import { api, urls } from "@/utils/api";
import router from "@/plugins/router";
import { copy } from "@/utils/object";
import { handleError } from "@/utils/errorHandler";

export default {
  name: "InfoMessageEditDialog",
  components: { ValidationObserver, ValidationProvider, TextFieldDatePicker },
  data() {
    return {
      display: false,
      initial: {}
    };
  },
  props: {
    message: {
      required: false
    }
  },
  methods: {
    onAdd() {
      this.$emit("addNew");
    },
    async save() {
      const isDataValid = await this.$refs.infoMessageObserver.validate();

      if (!isDataValid) {
        this.showValidationErrorSnackbar(this.$refs.infoMessageObserver);
        return;
      }

      if (this.message.infoMessageId) {
        await this.patchExisting();
      } else {
        await this.postNew();
      }
    },
    async postNew() {
      try {
        const response = await api.v1.infoMessages.post(this.message);

        if (response.status === 200) {
          this.successResponse();
        }
      } catch (error) {
        await handleError(error);
      }
    },
    async patchExisting() {
      try {
        const patchDoc = compare(this.initial, this.message);
        const response = await api.v1.infoMessages.patch(
          this.message.infoMessageId,
          patchDoc
        );
        if (response.status === 204) {
          this.successResponse();
        }
      } catch (error) {
        await handleError(error);
      }
    },
    successResponse() {
      this.$snacks.add(this.$t("infoMessagesStrings.saved"), "success", true);
      this.$emit("saved");
    },
    cancel() {
      this.$emit("cancelled");
    },
    getInfoMessageTypeOptions() {
      return Object.values(InfoMessageTypes).map(x => ({
        value: x,
        text: this.$t(`enums.infoMessageTypes.${x}`)
      }));
    },
    getInfoMessageRecipientTypeOptions() {
      return Object.values(InfoMessageRecipientTypes).map(x => ({
        value: x,
        text: this.$t(`enums.infoMessageRecipientTypes.${x}`)
      }));
    },
    getRouteOptions() {
      const routes = router.options.routes.map(x => ({
        value: x.name,
        text: x.name
      }));
      routes.unshift({ value: "", text: this.$t("commonStrings.notDefined") });
      return routes;
    }
  },
  computed: {
    ...mapGetters({
      urlState: "utilsRestStore/urlState"
    }),
    isPosting() {
      return this.urlState(urls.v1.infoMessages.post());
    },
    isPatching() {
      return this.urlState(
        urls.v1.infoMessages.patch(this.message.infoMessageId)
      );
    },
    isSaving() {
      return this.isPosting || this.isPatching;
    },
    title() {
      if (this.message.infoMessageId <= 0) {
        return this.$t("infoMessagesStrings.editModal.createTitle");
      }
      return this.$t("infoMessagesStrings.editModal.editTitle");
    }
  },
  watch: {
    message: {
      handler(val) {
        if (val.infoMessageId >= 0) {
          this.display = !!val;
        } else {
          this.display = false;
        }
      },
      deep: true
    },
    display(val) {
      if (val) {
        this.initial = copy(this.message);
      }
    }
  }
};
</script>
