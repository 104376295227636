<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col>
          <h2 class="text-h2 float-left">{{ $t("receiptsPanelStrings.title") }} {{ claimsCountLabel }}</h2>
          <v-icon
            tag="span"
            class="ml-3"
            @click.stop="onHelpClick"
          >help_outline</v-icon>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <receipt-edit-dialog
        :claims="claims"
        :current-item="editedItem"
        :current-index="editedIndex"
        :can-use-salary-fraction="canUseSalaryFraction"
        @new-item-added="addNewItem"
        @item-changed="updateItem"
        @edit-cancelled="editCancelled"
        :enableAddNew="enableAddNew"
      ></receipt-edit-dialog>
      <v-row v-if="claimsCount">
        <v-col>
          <receipt-table
            :claims="claims"
            :canUseSalaryFraction="canUseSalaryFraction"
            @edit-clicked="editItem"
            @remove-clicked="removeItem"
          ></receipt-table>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ReceiptTable from "./ReceiptsTable";
import ReceiptEditDialog from "./ReceiptEditDialog";
import claimPanel from "@/mixins/claimPanel";
import HelpBarContentTypes from "@/enums/HelpBarContentTypes";
import HelpBarApplicationSectionTypes from "@/enums/HelpBarApplicationSectionTypes";

export default {
  name: "ReceiptsPanel",
  components: {
    ReceiptTable,
    ReceiptEditDialog
  },
  mixins: [claimPanel],
  data() {
    return {
      showPanel: false,
      defaultItem: { attachments: [] },
      editedItem: { attachments: [] },
      editedIndex: -1
    };
  },
  computed: {
    filteredClaims() {
      return (this.claims || []).filter(x => !x.isDeleted);
    },
    claimsCount() {
      return this.filteredClaims.length;
    },
    claimsCountLabel() {
      if (this.claimsCount) {
        return `(${this.claimsCount})`;
      }

      return "";
    }
  },
  props: {
    claims: {
      default: () => [],
      type: Array
    },
    canUseSalaryFraction: {
      required: true
    },
    enableAddNew:{
      required: true,
      type: Boolean
    }
  },
  methods: {
    onHelpClick() {
      this.showHelpBar(HelpBarContentTypes.Application, [HelpBarApplicationSectionTypes.Receipts]);
    },
    initForNewItem() {
      this.editedItem = { ...this.defaultItem };

      //any index < 0 is new item, should change on every init to trigger component updates
      if (this.editedIndex < 0) {
        this.editedIndex = this.editedIndex - 1;
      } else {
        this.editedIndex = -1;
      }
    },
    addNewItem(item) {
      this.claims.push(item);
      this.initForNewItem();
    },
    editItem(item) {
      this.editedItem = { ...item.claim };
      this.editedIndex = item.index;
    },
    updateItem(item) {
      Object.assign(this.claims[item.index], item.claim);
      this.initForNewItem();
    },
    editCancelled() {
      this.initForNewItem();
    },
    removeItem(item) {
      this.claims.splice(item.index, 1);
    }
  }
};
</script>