<template>
  <v-dialog v-model="dialog" max-width="400px">
    <template v-slot:activator="{ on }">
      <v-btn
        text
        v-on="on"
        class="body-1 pa-0 tertiary-remove-underline-button text-none font-weight-light"
      >{{$t('generalSettingsStrings.buypassSettingsInfoModal.linkText')}}</v-btn>
    </template>
    <v-card>
      <v-card-title><h2 class="text-h3">{{$t('generalSettingsStrings.buypassSettingsInfoModal.title')}}</h2></v-card-title>
      <v-card-text class="pa-4">
        <p class="py-2">{{$t('generalSettingsStrings.buypassSettingsInfoModal.infoText1')}} {{$t('generalSettingsStrings.buypassSettingsInfoModal.infoText2')}}</p>
        <p class="py-2">{{$t('generalSettingsStrings.buypassSettingsInfoModal.infoText3')}}</p>
        <p class="py-2">
          <a href="https://www.idrettsforbundet.no/digital/idrettsoppgjor/"
            target="_blank"
            rel="noopener noreferrer"
          >{{ $t("generalSettingsStrings.buypassSettingsInfoModal.helpLinkText") }}</a>
          <v-icon tag="span" small>mdi-launch</v-icon>
        </p>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn
          rounded
          class="primary-button"
          @click="close"
        >{{$t('generalSettingsStrings.buypassSettingsInfoModal.closeButton')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BuypassSettingsInfoModal",
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    close() {
      this.dialog = false;
    }
  }
};
</script>