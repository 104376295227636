<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
  >
    <template v-slot:activator="{ on }">
      <v-btn
      :disabled="!enableAddNew"
      rounded
      small
      class="primary-inverted-button"
      v-on="on"
    >
      <v-icon tag="span" left>mdi-plus</v-icon>
      {{ $t("receiptsPanelStrings.labels.addButton") }}
    </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <validation-observer ref="receiptsObserver">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <claim-date-picker
                  :claim="currentItem"
                  :allowed-dates="x => $moment(x).isSameOrBefore($moment(), 'days')"
                  :label="$t('receiptsPanelStrings.tableHeaders.date')"
                  :key="currentIndex"
                ></claim-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <claim-type-picker
                  :claim="currentItem"
                  configuration-type-property="rateType"
                  :filter-enabled-types-func="filterEnabledReceiptTypes"
                  resources-property="enums.receiptTypes"
                  model-binding-property="claimTypeId"
                  :label="$t('receiptsPanelStrings.tableHeaders.expenseType')"
                  :validation-name="$t('receiptsPanelStrings.tableHeaders.expenseType')"
                ></claim-type-picker>
              </v-col>
              <v-col
                cols="12"
                md="6"
                v-if="canUseSalaryFraction"
              >
                <v-checkbox
                  color="primary"
                  :label="$t('commonStrings.salaryFractionLabel')"
                  v-model="currentItem.usesSalaryFraction"
                  :disabled="!salaryFractionSplitAvailable"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
              >
                <paying-orgs-picker
                  :claim="currentItem"
                  configuration-type-property="rateType"
                  :filter-enabled-types-func="filterEnabledReceiptTypes"
                  claim-type-property="claimTypeId"
                  :label="$t('receiptsPanelStrings.tableHeaders.payingOrgs')"
                  :validation-name="$t('receiptsPanelStrings.tableHeaders.payingOrgs')"
                ></paying-orgs-picker>
              </v-col>
              <v-col
                cols="12"
              >
                <validation-provider
                  rules="required|decimal:2|min_value:0.01"
                  v-slot="{ errors }"
                  :name="$t('receiptsPanelStrings.tableHeaders.amount')"
                >
                  <decimal-text-field
                    v-model="currentItem.amount"
                    name="claimAmount"
                    :label="$t('receiptsPanelStrings.tableHeaders.amount')"
                    :error-messages="errors"
                    :pad-decimals="2"
                    :key="currentIndex"
                  >
                  </decimal-text-field>
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
              >
                <validation-provider
                  :rules="{required: isCommentRequired(currentItem), max: validationConstants.receiptEditDialog.maxCommentLength}"
                  :name="$t('receiptsPanelStrings.tableHeaders.comment')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                  outlined
                  dense
                    v-model="currentItem.comment"
                    :error-messages="errors"
                    counter="60"
                    :label="$t('receiptsPanelStrings.tableHeaders.comment')"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
          <h2 class="subtitle-1">{{ $t("receiptsPanelStrings.attachmentsModal.uploadHeader") }}</h2>
            <v-row>
              <v-col>
                <attachment-upload @file-added="addFile" @upload-failed="uploadFailed"></attachment-upload>
              </v-col>
            </v-row>
            <h2 class="subtitle-1">{{ $t("receiptsPanelStrings.attachmentsModal.selectedAttachmentsHeader") }}</h2>
            <v-row>
              <v-col>
                <attachment-list
                  :attachments="currentItem.attachments"
                  :show-remove="true"
                  @download="downloadFile"
                ></attachment-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  :rules="{min_value: isAttachmentRequired(currentItem) ? 1 : 0}"
                  v-slot="{ errors }"
                  :custom-messages="{min_value: $t('validations.attachmentRequiredForType')}"
                >
                  <v-input
                    v-model="currentItem.attachments.length"
                    readonly
                    :error-messages="errors"
                  >{{ $t('receiptsPanelStrings.labels.attachmentCountSelected', [currentItem.attachments.length]) }}</v-input>
                </validation-provider>
              </v-col>
            </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="pa-5 flex-column flex-sm-row">
        <v-spacer></v-spacer>
        <v-btn
          rounded
          small
          :block="$vuetify.breakpoint.xsOnly"
          class="primary-inverted-button mb-2 mb-sm-0"
          @click="cancel"
        >
          {{$t("receiptsPanelStrings.labels.cancelButton")}}
        </v-btn>
        <v-btn
          rounded
          small
          :block="$vuetify.breakpoint.xsOnly"
          class="primary-button ml-0 ml-sm-2"
          @click="save"
        >
          {{saveLabel}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import validationErrors from "@/mixins/validationErrors";
import ClaimDatePicker from "../components/ClaimDatePicker";
import ClaimTypePicker from "../components/ClaimTypePicker";
import PayingOrgsPicker from "../components/PayingOrgsPicker";
import DecimalTextField from "@/views/common/components/DecimalTextField";
import AttachmentUpload from "@/views/common/components/AttachmentUpload";
import AttachmentList from "@/views/common/components/AttachmentList";
import { api } from "@/utils/api";
import { validationConstants } from "@/utils/validationConstants";
import { handleError } from "@/utils/errorHandler";

import ReceiptTypes from "@/enums/ReceiptTypes";

export default {
  name: "ReceiptEditDialog",
  components: {
    ValidationObserver,
    ValidationProvider,
    ClaimDatePicker,
    ClaimTypePicker,
    PayingOrgsPicker,
    DecimalTextField,
    AttachmentUpload,
    AttachmentList
  },
  mixins: [validationErrors],
  data() {
    return {
      dialog: false,
      validationConstants: validationConstants
    };
  },
  props: {
    claims: {
      default: () => [],
      type: Array
    },
    currentItem: {
      required: true
    },
    currentIndex: {
      required: true
    },
    canUseSalaryFraction: {
      required: true
    },
    enableAddNew:{
      required: true,
      type: Boolean
    }
  },
  watch: {
    currentIndex(val) {
      if(val >= 0) {
        this.dialog = true;
      }
    },
    dialog(val) {
      if (val && this.currentIndex < 0 && this.$refs.receiptsObserver) {
        this.$refs.receiptsObserver.reset();
      }
    }
  },
  computed: {
    ...mapGetters({
      salaryFractionSplitAvailable: "application/salaryFractionsStore/salaryFractionSplitAvailable",
      getRateConfiguration: "application/rateConfigurationsStore/rateConfiguration",
      user: "oidcStore/oidcUser"
    }),
    saveLabel() {
      if (this.currentIndex < 0) {
        return this.$t("receiptsPanelStrings.labels.insertButton");
      } else {
        return this.$t("receiptsPanelStrings.labels.updateButton");
      }
    },
    formTitle() {
      if (this.currentIndex < 0) {
        return this.$t("receiptsPanelStrings.labels.insertEditDialogTitle");
      } else {
        return this.$t("receiptsPanelStrings.labels.updateEditDialogTitle");
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    cancel() {
      this.$emit('edit-cancelled');
      this.close();
    },
    async save() {
      const isDataValid = await this.$refs.receiptsObserver.validate();

      if (!isDataValid) {
        this.showValidationErrorSnackbar(this.$refs.receiptsObserver);
        return;
      }

      if (this.currentIndex >= 0) {
        this.$emit('item-changed', {claim: this.currentItem, index: this.currentIndex});
      } else {
        this.$emit('new-item-added', this.currentItem);
      }

      this.close();
    },
    filterEnabledReceiptTypes(conf) {
      return conf["receiptRates"]["receiptRateTypes"].filter(x => x.enabled);
    },
    isCommentRequired(claim){
      return claim && claim.claimTypeId === ReceiptTypes.Custom;
    },
    isAttachmentRequired(claim) {
      if (!claim?.date || !claim?.claimTypeId || !claim?.payingOrgs) {
        return false;
      }
      const getRateConfigurationFunc = this.getRateConfiguration;

      const receiptRates = claim.payingOrgs.map(orgId =>
        getRateConfigurationFunc(orgId, claim.date, "receiptRates")
      );

      return receiptRates.some(x =>
        x["receiptRateTypes"].some(
          y => y.rateType === claim.claimTypeId && y.requiresAttachment
        )
      );
    },
    addFile(fileDetails) {
      this.$snacks.add(this.$t("receiptsPanelStrings.attachmentsModal.uploadSuccessMessage"),
        "success"
      );
      this.currentItem.attachments.push(fileDetails);
    },
    async downloadFile(attachment) {
      try {
        const config = {
          responseType: "arraybuffer"
        };

        const response = await api.v1.persons.attachments(
          this.user.personid,
          attachment.attachmentId,
          config
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"]
          });

          //insert temporary link in DOM and trigger click to download file
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = attachment.filename;
          link.click();
        }
      } catch (error) {
        await handleError(error);
      }
    },
    getErrorMessageOrDefault(error) {
      if (error?.length) {
        return error;
      }

      return this.$t("receiptsPanelStrings.attachmentsModal.uploadErrorMessage");
    },
    uploadFailed(error) {
      this.$snacks.add(this.getErrorMessageOrDefault(error), "error");
    }
  }
};
</script>