const applicationStatusPanelStrings = {
  title: "Status mottakere",
  tableHeaders: {
    payingOrgs: "@:commonStrings.recipients",
    status: "Status",
    payment: "Utbetalt",
    paymentMethod: "Betalingsmåte",
    orgClaimNumber: "Løpenummer"
  }
};

export default applicationStatusPanelStrings;