const OrgTypes = {
  SF: 2,
  SK: 4,
  KL: 5,
  GR: 6,
  AI: 36,
  RE: 48
};

Object.freeze(OrgTypes);

export default OrgTypes;