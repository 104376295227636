const applicationsStrings = {
  name: "Dine krav",
  pageTitle: "@:applicationsStrings.name",
  pageHeader: "@:applicationsStrings.name",
  createdDateTableHeader: "Dato",
  applicationNameTableHeader: "Kravet gjelder",
  statusTableHeader: "Status",
  amountTableHeader: "@:commonStrings.amount",
  applicationIdTableHeader: "ID",
  paymentReceivedTableHeader: "Mottatt betaling",
  paymentReceivedCheckboxLabel: "@:applicationsStrings.paymentReceivedTableHeader",
  applicationsTableNoDataText: "Ingen krav ble funnet",
  applicationsTableNoResultsText: "Ingen krav oppfyller søkekriteriene",
  createNewBtn: "Opprett nytt krav",
  resultsSubheader: "Søkeresultater ({0})",
  fromDateFilterLabel: "Fra @.lower:applicationsStrings.createdDateTableHeader",
  toDateFilterLabel: "Til @.lower:applicationsStrings.createdDateTableHeader",
  amountFilterLabel: "@:applicationsStrings.amountTableHeader",
  applicationNameFilterLabel: "@:applicationsStrings.applicationNameTableHeader",
  applicationIdFilterLabel: "@:applicationsStrings.applicationIdTableHeader",
  paymentReceivedFilterLabel: "Filtrer på @.lower:applicationsStrings.paymentReceivedTableHeader:",
  paymentReceivedFilterOptionYes: "@:applicationsStrings.paymentReceivedTableHeader",
  paymentReceivedFilterOptionNo: "Ikke @.lower:applicationsStrings.paymentReceivedTableHeader",
  applicationStatusFilterLabel: "Filtrer på @.lower:applicationsStrings.statusTableHeader:",
  includeArchivedFilterLabel: "Inkluder arkiverte (avsluttet for mer enn 60 dager siden)",
  clearFiltersBtn: "@:commonStrings.clearFilters",
  paymentReceivedUpdatedAlert: "'@:applicationsStrings.paymentReceivedCheckboxLabel' ble oppdatert",
  labels: {
    removeButton: "@:commonStrings.remove"
  },
  applicationRemovalDialog: {
    title: "Slette kravet?",
    text: "Er du sikker på at du vil slette kravet {name} (med ID {id})? Kravet kan ikke gjenopprettes etter at det er slettet. {ta_info}",
    tournamentAdminInfo: "Krav hentet fra TurneringsAdmin vil bli tilbakestilt slik at de kan eksporteres på nytt.",
    labels: {
      proceedButton: "Slett kravet",
      cancelButton: "@:commonStrings.cancel"
    }
  },
  applicationDeleted: "Application {0} successfully removed"
};

export default applicationsStrings;