import Vue from 'vue';
import VueMoment from 'vue-moment';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

moment.locale('nb');

Vue.use(VueMoment, {
    moment
});

export const m = moment;