<template>
  <v-row class="ma-0 pa-0">
    <v-col class="my-0 pa-0">
      <v-alert
        :type="alert.type"
        v-for="alert in alerts"
        :key="alert.infoMessageId"
        dismissible
        border="left"
        colored-border
        transition="slide-y-transition"
        v-model="alert.display"
        elevation="1"
        prominent
      >
        <v-container class="py-0">
          <v-row>
            <v-col class="pa-0">
              <h1 class="text-h2 mb-7" v-if="alert.title">
                {{ alert.title }}
              </h1>
            </v-col>
          </v-row>

          <v-row
            ><v-col class="pt-0 pb-0 pl-0 pr-8"
              ><p class="mb-0" style="white-space: pre-wrap">
                {{ alert.text }}
              </p>
            </v-col>
          </v-row>

          <v-row class="mt-2" v-if="alert.link">
            <v-col class="pl-2">
              <span class="remove-underline-animation">
                <a :href="getSanitizedUrl(alert.link)"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="linkClass"
                >{{ $t("commonStrings.readMore")}}&rarr;</a>
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import InfoMessageTypes from "@/enums/InfoMessageTypes";
import InfoMessageRecipientTypes from "@/enums/InfoMessageRecipientTypes";
import { RemovedInfoMessagesLocalStorageKey } from '@/utils/localStorageConstants.js';

export default {
  props: {
    currentOrg: {
      validator: prop => typeof prop === "object" || prop === null,
      required: true
    }
  },
  data() {
    return {
      pageName: "",
      alerts: []
    };
  },
  computed: {
    ...mapGetters({
      stateInitiated: "contextStore/initiated",
      messages: "infoMessagesStore/messages"
    }),
    route() {
      return this.$route;
    }
  },
  methods: {
    getSanitizedUrl(url) {
      const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

      return sanitizeUrl(url);
    },
    getRemovedMessages() {
      let removedMessages = JSON.parse(
        localStorage.getItem(RemovedInfoMessagesLocalStorageKey)
      );
      if (!removedMessages?.length) {
        removedMessages = [];
      }

      return removedMessages;
    },
    getMessagesForPage(pageName) {
      const that = this;
      const removed = this.getRemovedMessages();
      return this.messages.filter(function (m) {
        if (
          m.recipientType === InfoMessageRecipientTypes.Approver &&
          (!that.currentOrg?.orgApplicationEnabled)
        ) {
          return false;
        }

        if (removed.includes(m.infoMessageId)) {
          return false;
        }

        return (m?.displayOnPage === pageName);
      });
    },
    setAlerts() {
      if (this.stateInitiated && this.messages && this.messages.length) {
        const messagesForPage = this.getMessagesForPage(this.pageName);
        this.alerts = this.mapMessagesToAlerts(messagesForPage);
        return;
      }

      this.alerts = [];
    },
    mapMessagesToAlerts(messages) {
      return messages.map(x => ({
        display: true,
        infoMessageId: x.infoMessageId,
        title: x.title,
        text: x.messageText,
        type: this.getAlertType(x.infoMessageType),
        link: x.readMoreUrl
      }));
    },
    getAlertType(messageType) {
      if (messageType) {
        return Object.keys(InfoMessageTypes)
          .find(k => InfoMessageTypes[k] === messageType)
          ?.toLowerCase();
      }
      return "";
    }
  },
  watch: {
    stateInitiated: {
      handler: async function (val) {
        if (val) {
          this.setAlerts();
        }
      },
      immediate: true
    },
    messages() {
      this.setAlerts();
    },
    route(to) {
      this.pageName = to.name;
      this.setAlerts();
    },
    alerts: {
      handler(values) {
        if (values?.length) {
          const removable = values.filter(x => !x.display);

          removable.forEach(x => {
            const removedMessages = this.getRemovedMessages();

            if (!removedMessages.includes(x.infoMessageId)) {
              removedMessages.push(x.infoMessageId);
            }

            localStorage.setItem(
              RemovedInfoMessagesLocalStorageKey,
              JSON.stringify(removedMessages)
            );
          });
        }
      },
      deep: true
    }
  }
};
</script>