import store from '@/store';
import { getOrgDisplayName } from "@/utils/org";

export const getPersonName = function (personId) {
    const currentPeople = store.getters['application/currentApplicationStore/persons'];

    const person = currentPeople.find(x => x.personId === personId);

    if (person) {
        return `${person.firstName} ${person.lastName}`;
    }

    return '';
};

export const getOrgName = function (orgId) {
    const currentOrgs = store.getters['application/currentApplicationStore/orgs'];

    const org = currentOrgs.find(x => x.id === orgId);

    if (org) {
        return getOrgDisplayName(org);
    }

    return '';
};

export const getActualPayingOrgId = function (recipientOrgId) {
    const currentPayingOrgIds = store.getters['application/currentApplicationStore/payingOrgIds'];

    const tuple = currentPayingOrgIds.find(x => x.orgId === recipientOrgId);

    if (tuple) {
        return tuple.payingOrgId;
    }

    return null;
};