<template>
  <div>
    <v-alert type="warning" colored-border border="bottom" elevation="1">
      <v-row v-if="error">
        <v-col>
          <code class="display-block">{{error}}</code>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            rounded
            to="applications"
            class="primary-button"
          >{{$t("errorPageStrings.labels.goBackButton")}}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ErrorPage",
  computed: {
    ...mapGetters({
      error: "errorStore/error"
    })
  },
  methods: {
    ...mapMutations({
      reset: "errorStore/reset"
    })
  },
  destroyed() {
    this.reset();
  }
};
</script>
