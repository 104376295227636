<template>
  <footer-block>
    <p class="body-2 red-underline-animation">
      <a href="https://www.idrettsforbundet.no/digital/idrettsoppgjor/"
        class="inverted--text opaque-underline"
        target="_blank"
        rel="noopener noreferrer"
      >{{ $t("publicPageStrings.readMore") }}&rarr;</a>
    </p>
    <p class="body-2 red-underline-animation">
      <a href="https://www.idrettsforbundet.no/digital/idrettsoppgjor/artikkel/brukervilkar/"
        class="inverted--text opaque-underline"
        target="_blank"
        rel="noopener noreferrer"
      >{{ $t("publicPageStrings.termsLink") }}&rarr;</a>
    </p>
  </footer-block>
</template>

<script>
import FooterBlock from "@/views/common/components/FooterBlock";

export default {
  name: "AppFooter",
  components: {
    FooterBlock
  }
};
</script>