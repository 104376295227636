const ReceiptTypes = {
    Parking: 1,
    Toll: 2,
    Meal: 3,
    Phone: 4,
    Internet: 5,
    DryCleaning: 6,
    Custom: 7,
    Stay: 8,
    Flight: 9,
    OtherTravel: 10,
    Fuel: 11,
    GiftsAndPrizes: 12,
    HealthAndMedical: 13,
    SportsEquipment: 14,
    Communication: 15,
    OfficeSupplies: 16,
    Subscriptions: 17,
    ComputerSupplies: 18,
    MembershipFees: 19,
    EntryFees: 20,
    Education: 21
};

Object.freeze(ReceiptTypes);

export default ReceiptTypes;