import Vue from '@/main';

export const createDateFromMonthAndYear = function createDateFromMonthAndYear(month, year) {
  return Vue.$moment({year: year, month: month - 1});
};

export const sortByYearThenMonthAscending = function sortByYearThenMonthAscending(a, b) {
  if (a.year === b.year) {
    return a.month - b.month;
  }

  return a.year - b.year;
};

export const sortByYearThenMonthDescending = function sortByYearThenMonthDescending(a, b) {
  if (a.year === b.year) {
    return b.month - a.month;
  }

  return b.year - a.year;
};
